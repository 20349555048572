import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const ReviewSummaryRow = ({ description, value }) => {
  const classes = useStyles();
  return (
    <Box className={classes.reviewDetailRow}>
      <Box display="flex" flex={1}>
        <Typography component="span">{description}</Typography>
      </Box>
      <Box display="flex" flex={2}>
        <Typography component="span">{value}</Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  reviewDetailRow: {
    display: 'flex',
    flex: 1,
    textAlign: 'left',
  },
}));

export default ReviewSummaryRow;
