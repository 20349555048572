import React from 'react';
import CmtImage from '@coremat/CmtImage';

const TextInputHelperText = ({ text, error }) => (
  <>
    {error && text && <CmtImage src="/images/icons/error.png" />}
    {text && <em>{text}</em>}
  </>
);

export default TextInputHelperText;
