import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PWModal from '../../../../PWModal';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import CmtImage from '../../../../../@coremat/CmtImage';
import { makeStyles } from '@material-ui/core/styles';
import MCardsButton from '../../../MCardsForm/MCardsButton';
import MCardsModalTitleIcon from '../../../MCardsModalTitleIcon';
import CampaignsService from '../../../../../services/distributor_api/campaigns.service';
import IntlMessages from '../../../../../@jumbo/utils/IntlMessages';
import PageLoader from '../../../../../@jumbo/components/PageComponents/PageLoader';

const i18nPath = 'pages.manage.recipients.carousel.steps.recipients.modal';

const SelectedFilePreview = ({ file }) => {
  return (
    <Box style={{ display: 'contents' }}>
      <CmtImage src="/images/icons/csv-file-icon.png" />
      <Typography align="center" variant="subtitle1" color="textSecondary" style={{ marginTop: '1rem' }}>
        {file.name}
      </Typography>
    </Box>
  );
};
const DropFileInstructions = () => {
  return (
    <Box>
      <Typography align="center" variant="subtitle1" color="primary" style={{ fontWeight: 'bold' }}>
        <IntlMessages id={`${i18nPath}.instructions.title`} />
      </Typography>
      <Typography align="center" variant="subtitle1" color="textSecondary" style={{ marginTop: '1rem' }}>
        <IntlMessages id={`${i18nPath}.instructions.subtitle`} />
      </Typography>
    </Box>
  );
};

const CSVErrors = ({ csvErrors }) => {
  const classes = useStyles();
  const groupedCSVErrors = [csvErrors].flat(1).reduce((acc, { line, detail }) => {
    line = line || 'File';
    acc[line] ? acc[line].push(detail) : (acc[line] = [detail]);
    return acc;
  }, {});

  return (
    <Box style={{ display: 'contents' }}>
      <Typography align="left">
        <IntlMessages id={`${i18nPath}.errors.title`} />
      </Typography>
      <TableContainer className={classes.csvErrorsTableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <IntlMessages id={`${i18nPath}.errors.table.column1`} />
              </TableCell>
              <TableCell align="center">
                <IntlMessages id={`${i18nPath}.errors.table.column2`} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(groupedCSVErrors).map(([line, errorsDetails]) => (
              <TableRow key={`csv-error-line-${line}`}>
                <TableCell align="left">{line}</TableCell>
                <TableCell align="center">
                  {errorsDetails.map((detail) => (
                    <Typography align="left" variant="subtitle1" key={`error-detail-${Math.random()}`}>
                      &bull; {detail}
                    </Typography>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const UploadDescription = () => {
  const classes = useStyles();
  return (
    <Box style={{ textAlign: 'center' }}>
      <Typography variant="subtitle1" color="textSecondary" className={classes.instructionMessage}>
        <IntlMessages id={`${i18nPath}.upload_description.paragraph1`} />
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" className={classes.instructionMessage}>
        <IntlMessages id={`${i18nPath}.upload_description.paragraph2.part1`} />{' '}
        <a href="/recipients-template.csv">
          <IntlMessages id={`${i18nPath}.upload_description.paragraph2.part2`} />
        </a>{' '}
        <IntlMessages id={`${i18nPath}.upload_description.paragraph2.part3`} />
      </Typography>
    </Box>
  );
};

const RecipientsFileModal = ({ isOpen, onCancel, onSuccess, campaignUuid }) => {
  const [file, setFile] = useState(null);
  const [csvErrors, setCsvErrors] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const onFilesSelected = (files) => {
    setFile(files[0]);
    setCsvErrors(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'text/csv',
    maxFiles: 1,
    onDrop: onFilesSelected,
  });

  const resetFileAndCancel = () => {
    if (!isUploading) {
      setFile(null);
      onCancel();
    }
  };

  const isCsvErrorsResponse = ({ data, status }) => {
    return status === 400 && data?.errors?.[0]?.source?.parameter === 'csv_file';
  };

  const uploadRecipientsFile = () => {
    const formData = new FormData();
    formData.append('recipients_file', file);
    setIsUploading(true);
    CampaignsService.uploadRecipientsFile(campaignUuid, formData)
      .then(({ data }) => onSuccess(data))
      .catch(({ response }) => {
        if (isCsvErrorsResponse(response)) setCsvErrors(JSON.parse(response.data.errors[0].detail));
      })
      .finally(() => setIsUploading(false));
  };

  const classes = useStyles();

  return (
    <PWModal size="xs" modalOpen={isOpen} onClose={onCancel}>
      <Box className={classes.recipientModalContainer}>
        {isUploading && <PageLoader />}
        <MCardsModalTitleIcon src="/images/icons/three-people.png" />

        <Box my={4}>
          <Typography align="center" variant="h1">
            <IntlMessages id={`${i18nPath}.title`} />
          </Typography>
        </Box>

        {csvErrors ? <CSVErrors csvErrors={csvErrors} /> : <UploadDescription />}

        <Box className={classes.fileDrop}>
          <Box className={classes.flexColumn} {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            {file ? <SelectedFilePreview file={file} /> : <DropFileInstructions />}
          </Box>
        </Box>

        <MCardsButton
          type="button"
          color={file ? 'primary' : 'default'}
          disabled={!file || isUploading}
          onClick={uploadRecipientsFile}>
          <IntlMessages id={`${i18nPath}.buttons.confirm`} />
        </MCardsButton>

        <Typography align="center" onClick={resetFileAndCancel} className={classes.cancelButton}>
          <IntlMessages id={`${i18nPath}.buttons.cancel`} />
        </Typography>
      </Box>
    </PWModal>
  );
};
const useStyles = makeStyles(() => ({
  cancelButton: {
    cursor: 'pointer',
    marginTop: '1rem',
    fontWeight: 'bold',
  },
  recipientModalContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
  },
  title: {
    marginBottom: '1rem',
  },
  instructionMessage: {
    width: '100%',
    marginTop: '1rem',
  },
  fileDrop: {
    background: 'rgba(8, 38, 235, 0.03)',
    border: '2px dashed #0826EB',
    borderRadius: '5.15px',
    width: '100%',
    margin: '2rem 0',
    cursor: 'pointer',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  csvErrorsTableContainer: {
    maxHeight: '180px',
    border: '2px solid red',
  },
}));

export default RecipientsFileModal;
