import service from 'services/distributor_api/recipients.service';
import actions from './recipients.actions';
import { capitalize } from '@jumbo/utils/commonHelper';

const processRecipientRequest = (type, dispatch, campaignUuid, recipientUuid, payload, onRecipientUpdated) => {
  const capitalizedType = capitalize(type);
  const RecipientsService = service(campaignUuid);

  RecipientsService[type](recipientUuid, payload)
    .then((response) => {
      dispatch(actions[`recipient${capitalizedType}Success`](response.data));
      if (onRecipientUpdated) {
        onRecipientUpdated();
      }
    })
    .catch((error) => {
      dispatch(actions[`recipient${capitalizedType}Success`](error));
    });
};

export const updateRecipient = (dispatch, campaignUuid, recipient, onRecipientUpdated) => {
  processRecipientRequest(
    'update',
    dispatch,
    campaignUuid,
    recipient.uuid,
    { campaign_item: recipient },
    onRecipientUpdated,
  );
};

export const cancelRecipient = (dispatch, campaignUuid, recipientUuid, onRecipientUpdated) => {
  processRecipientRequest('delete', dispatch, campaignUuid, recipientUuid, {}, onRecipientUpdated);
};
