import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import Heading from './Heading';
import ListWithPagination from './ListWithPagination';
import RemoteData from 'components/RemoteData';
import Loading from './Loading';

const BouncedSearchableList = ({ getAll, ListComponent, filters, timeOfRender, countHeadingTitle }) => {
  const [response, setResponse] = useState(null);

  const [chosenPage, setChosenPage] = useState(1);

  const [filtersApplied, setFiltersApplied] = useState({});

  /* Filters bar stuff */
  const classes = useStyles();
  const onFilterChange = (changedFilterEntry) => {
    let newFilters = { ...filtersApplied, ...changedFilterEntry };
    setFiltersApplied(newFilters);
  };

  useEffect(() => {
    /* When a filter changes, go to page 1. */
    setChosenPage(1);
  }, [filtersApplied]);

  const getAllParams = () => [filtersApplied, chosenPage];

  const doSearch = () => getAll(filtersApplied, chosenPage);

  return (
    <Box>
      {countHeadingTitle && <Heading title={countHeadingTitle} resultsAndPagination={response} />}
      <Grid container spacing={2} className={classes.filtersBox}>
        {/* This portion did not go to a separate component because it would get unmounted
         * on every rerender, hence losing the entered filter values. */}
        {filters.map((filter, index) =>
          React.cloneElement(filter, {
            onChange: onFilterChange,
            key: `filter-${index}`,
          }),
        )}
      </Grid>
      <RemoteData
        get={() => doSearch()}
        onload={(response) => setResponse(response)}
        useEffectDeps={[...getAllParams(), timeOfRender]}
        loadingComponent={<Loading />}>
        {(response) => (
          <ListWithPagination
            ListComponent={ListComponent}
            list={response?.results}
            pagination={{
              ...response?.pagination,
              setChosenPage,
            }}
          />
        )}
      </RemoteData>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  filtersBox: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(5),
  },
}));

/**
 * A lists that presents results at ListComponent the results obtained by calling getAll with the given filters.
 * @param getAll a function that receives three parameters: dispatch, filtersApplied, chosenPage.
 * @param ListComponent the component to render the list fetched by getAll.
 * @param filters the filters to be added and applied to the search.
 * @param timeOfRender the time of render of the component. Used to force a re-render.
 * @param countHeadingTitle the title of the count heading. If present, the count heading will be rendered.
 *
 * @returns {JSX.Element}
 */
const SearchableList = ({
  getAll,
  ListComponent,
  filters,
  timeOfRender = new Date().getTime(),
  countHeadingTitle = null,
}) => (
  <BouncedSearchableList
    getAll={getAll}
    ListComponent={ListComponent}
    filters={filters}
    timeOfRender={timeOfRender}
    countHeadingTitle={countHeadingTitle}
  />
);

SearchableList.propTypes = {
  getAll: PropTypes.func.isRequired,
  ListComponent: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.element),
  timeOfRender: PropTypes.number,
  countHeadingTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
};

export default SearchableList;
