import actionTypes from './campaigns.actionTypes';
import initialState from './campaigns.initialState';

const campaignsReducer = (state = initialState, { type, payload, preventTransition, csvErrors }) => {
  switch (type) {
    case actionTypes.CAMPAIGN_GET_ONE_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case actionTypes.CAMPAIGN_GET_ONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentCampaign: payload,
      };
    case actionTypes.CAMPAIGN_GET_ONE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.CAMPAIGN_CREATE_START:
      return {
        ...state,
        isCreating: true,
        errorMessage: null,
        csvErrors: null,
        preventTransition: preventTransition,
      };
    case actionTypes.CAMPAIGN_CREATE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        currentCampaign: payload,
      };
    case actionTypes.CAMPAIGN_CREATE_ERROR:
      return {
        ...state,
        isCreating: false,
        errorMessage: payload,
      };
    case actionTypes.CAMPAIGN_UPDATE_START:
      return {
        ...state,
        isUpdating: true,
        errorMessage: null,
        csvErrors: null,
        preventTransition: preventTransition,
      };
    case actionTypes.CAMPAIGN_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        currentCampaign: payload,
      };
    case actionTypes.CAMPAIGN_UPDATE_ERROR:
      return {
        ...state,
        isUpdating: false,
        errorMessage: payload,
        csvErrors: csvErrors,
      };
    case actionTypes.CAMPAIGN_PUBLISH_START:
      return {
        ...state,
        isPublishing: true,
        errorMessage: null,
      };
    case actionTypes.CAMPAIGN_PUBLISH_SUCCESS:
      return {
        ...state,
        isPublishing: false,
        currentCampaign: payload,
      };
    case actionTypes.CAMPAIGN_PUBLISH_ERROR:
      return {
        ...state,
        isPublishing: false,
        errorMessage: payload,
      };
    case actionTypes.CAMPAIGN_RESTART_CREATION:
      return initialState;
    default:
      return state;
  }
};

export default campaignsReducer;
