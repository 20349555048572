import React from 'react';
import { Box } from '@material-ui/core';
import RecipientsList from './RecipientsList';
import MCardsStatsGrid from '../../MCardsStatsGrid';
import IntlMessages from '@jumbo/utils/IntlMessages';

const CampaignReport = ({ campaign, onReload = null }) => {
  const { uuid, name } = campaign;
  return (
    campaign && (
      <Box>
        <MCardsStatsGrid statistics={campaign.statistics} />
        <RecipientsList
          campaign={{ uuid, name }}
          countHeadingTitle={<IntlMessages id="pages.manage.campaigns.recipients.title" />}
          onReload={onReload}
        />
      </Box>
    )
  );
};

export default CampaignReport;
