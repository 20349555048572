const actionTypesFor = (prefix) => {
  return {
    GET_ONE_START: `${prefix}.GET_ONE_START`,
    GET_ONE_SUCCESS: `${prefix}.GET_ONE_SUCCESS`,
    GET_ONE_ERROR: `${prefix}.GET_ONE_ERROR`,
    CREATE_START: `${prefix}.CREATE_START`,
    CREATE_SUCCESS: `${prefix}.CREATE_SUCCESS`,
    CREATE_ERROR: `${prefix}.CREATE_ERROR`,
    ADD_TERMINAL_START: `${prefix}.ADD_TERMINAL_START`,
    ADD_TERMINAL_SUCCESS: `${prefix}.ADD_TERMINAL_SUCCESS`,
    ADD_TERMINAL_ERROR: `${prefix}.ADD_TERMINAL_ERROR`,
    GET_STATS_START: `${prefix}.GET_STATS_START`,
    GET_STATS_SUCCESS: `${prefix}.GET_STATS_SUCCESS`,
    GET_STATS_ERROR: `${prefix}.GET_STATS_ERROR`,
  };
};

export default actionTypesFor('LOCATIONS');
