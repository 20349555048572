import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import MCardsTextField from '../MCardsTextField';
import { KeyboardArrowDownRounded } from '@material-ui/icons';

const MCardsSelectInput = ({ name, options, placeholder, className, width, ...rest }) => {
  const classes = useStyles({ width });

  return (
    <MCardsTextField
      {...rest}
      select
      name={name}
      variant="outlined"
      className={`${classes.selectRoot} ${className}`}
      inputProps={{ 'aria-label': name }}
      SelectProps={{
        IconComponent: KeyboardArrowDownRounded,
        displayEmpty: !!placeholder,
        MenuProps: { className: classes.selectMuiPopoverRoot },
      }}>
      {placeholder && (
        <MenuItem value="" disabled classes={{ root: classes.menuRoot }}>
          {placeholder}
        </MenuItem>
      )}
      {options.map((item) => (
        <MenuItem key={item.value} value={item.value} classes={{ root: classes.menuItemRoot }}>
          {item.label}
        </MenuItem>
      ))}
    </MCardsTextField>
  );
};

const useStyles = makeStyles((theme) => ({
  selectRoot: {
    textAlign: 'left',
    width: (props) => `${props.width}%`,
    '& .MuiSelect-root': {
      borderRadius: '5.76365px',
      padding: '.801rem!important',
      '&.MuiSelect-select:focus': {
        backgroundColor: '#FFF',
      },
    },
  },
  selectMuiPopoverRoot: {
    '& .MuiMenu-paper.MuiPopover-paper': {
      background: '#FFFFFF',
      border: '1.15273px solid #EBEBEB',
      borderRadius: '5.76365px',
      boxShadow: 'none',
      filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.07))',
      '& .MuiMenu-list': {
        paddingTop: '4px',
        paddingBottom: '4px',
      },
    },
  },
  menuItemRoot: {
    marginLeft: '4px',
    marginRight: '4px',
    paddingLeft: '10px',
    paddingRight: '10px',
    '&.Mui-selected': {
      backgroundColor: '#ECEEFF',
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: '#ECEEFF',
      },
    },
    '&:hover': {
      backgroundColor: '#ECEEFF',
    },
  },
}));

MCardsSelectInput.defaultProps = {
  width: '100',
};

MCardsSelectInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ).isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  width: PropTypes.string,
};

export default MCardsSelectInput;
