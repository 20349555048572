import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import StatusLabel from '../../StatusLabel';

export const CardTransactionStatus = ({ status }) => {
  const labels = {
    posted: { text: <IntlMessages id="pages.manage.cardholders.card_transaction.state.posted" />, state: 'success' },
    cleared: { text: <IntlMessages id="pages.manage.cardholders.card_transaction.state.cleared" />, state: 'success' },
    authorized: { text: <IntlMessages id="pages.manage.cardholders.card_transaction.state.authorized" />, state: 'warning' },
    reversed: { text: <IntlMessages id="pages.manage.cardholders.card_transaction.state.reversed" />, state: 'error' },
    declined: { text: <IntlMessages id="pages.manage.cardholders.card_transaction.state.declined" />, state: 'error' },
    cancelled: { text: <IntlMessages id="pages.manage.cardholders.card_transaction.state.cancelled" />, state: 'error' },
  };

  return status ? <StatusLabel state={labels[status].state} text={labels[status].text} /> : null;
};
