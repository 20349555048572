import React from 'react';
import { BarChart, HomeRounded, Settings } from '@material-ui/icons';
import IntlMessages from '../../../utils/IntlMessages';
import { Icon, Typography } from '@material-ui/core';

export const sidebarNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'section',
    children: [
      {
        name: <IntlMessages id={'pages.homePage.menu'} />,
        type: 'item',
        icon: <HomeRounded />,
        link: '/',
      },
      {
        name: <IntlMessages id={'pages.dashboardPage.title'} />,
        type: 'item',
        icon: <BarChart />,
        link: '/dashboard',
      },
      {
        name: <IntlMessages id={'pages.campaignsPage.create.menuTitle'} />,
        type: 'item',
        icon: <Icon>campaign</Icon>,
        link: '/campaigns/new',
      },
      {
        name: <IntlMessages id={'pages.manage.menuTitle'} />,
        type: 'collapse',
        icon: <Settings />,
        link: '/reports',
        children: [
          {
            name: <IntlMessages id={'pages.manage.campaigns.menuTitle'} />,
            type: 'item',
            link: '/reports/campaigns',
          },
          {
            name: <IntlMessages id={'pages.manage.cardholders.menuTitle'} />,
            type: 'item',
            link: '/reports/cardholders',
          },
          {
            name: <IntlMessages id={'pages.manage.locations.menuTitle'} />,
            type: 'item',
            link: '/reports/locations',
          },
          {
            name: <IntlMessages id={'pages.manage.terminals.menuTitle'} />,
            type: 'item',
            link: '/reports/terminals',
          },
        ],
      },
    ],
  },
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.dashboardPage'} />,
        type: 'item',
        icon: <BarChart />,
        link: '/dashboard',
      },
    ],
  },
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.dashboardPage'} />,
        type: 'item',
        icon: <BarChart />,
        link: '/dashboard',
      },
    ],
  },
];
