import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../../redux/actions/Auth';
import React from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import AUTH0_MOCKED_USER from './mocked_user';

export const auth0Client = createAuth0Client({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  redirect_uri: process.env.REACT_APP_AUTH0_REDITECT_URI,
});

const auth0 = {
  onRegister: ({ name, email, password }) => {
    return (dispatch) => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(fetchSuccess());
        const user = { name: name, email: email, password: password };
        localStorage.setItem('user', JSON.stringify(user));
        dispatch(setAuthUser(user));
      }, 300);
    };
  },

  onLogin: () => {
    return (dispatch) => {
      dispatch(fetchStart());

      auth0Client.then(async (auth0) => {
        try {
          await auth0.loginWithRedirect();
          dispatch(fetchSuccess());
        } catch (error) {
          dispatch(fetchError(error.message));
        }
      });
    };
  },
  onLogout: (withoutPermission = false) => {
    return (dispatch) => {
      dispatch(fetchStart());

      auth0Client.then(async (auth0) => {
        try {
          let returnToURI = process.env.REACT_APP_AUTH0_REDITECT_URI;
          if (withoutPermission) returnToURI += '?withoutPermission=true';

          await auth0.logout({ returnTo: returnToURI });
          dispatch(fetchSuccess());
        } catch (error) {
          dispatch(fetchError(error.message));
        }
      });
    };
  },

  getAuthUser: (loaded = false) => {
    if (process.env.REACT_APP_MOCK_AUTH0 === 'true') {
      return (dispatch) => {
        dispatch(setAuthUser(AUTH0_MOCKED_USER));
        dispatch(updateLoadUser(true));
      };
    }
    return (dispatch) => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));

      auth0Client
        .then(async (auth0) => {
          const authUser = await auth0.getUser();

          if (authUser) {
            dispatch(setAuthUser(authUser));
          }

          dispatch(fetchSuccess());
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          dispatch(updateLoadUser(true));
        });
    };
  },

  onForgotPassword: () => {
    return (dispatch) => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default auth0;
