import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Box } from '@material-ui/core';
import CampaignList from 'components/mcards/Campaigns/CampaignList';
import { useDispatch, useSelector } from 'react-redux';
import { loadCarddesignAsync } from 'redux/reducers/distributor_api/carddesigns/carddesigns.thunks';
import { loadCardprogramsAsync } from 'redux/reducers/distributor_api/cardprograms/cardprograms.thunks';
import MCardsButton from 'components/mcards/MCardsForm/MCardsButton';

const MyCampaigns = () => {
  const { isLoading, cardprograms } = useSelector((state) => state.cardprograms);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    loadCardprogramsAsync(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (cardprograms?.length) {
      loadCarddesignAsync(cardprograms[0]['card_design_uuid'], dispatch);
    }
  }, [cardprograms, dispatch]);

  return isLoading ? (
    <PageLoader />
  ) : (
    <PageContainer
      heading={<IntlMessages id="pages.manage.campaigns.title" />}
      ctaButtons={[
        <MCardsButton onClick={() => history.push('/campaigns/new')}>
          <IntlMessages id="pages.manage.campaigns.new.buttonText" />
        </MCardsButton>,
      ]}
      subtitle={<IntlMessages id="pages.manage.campaigns.subtitle" />}>
      {cardprograms && (
        <Box marginTop="3rem">
          <CampaignList />
        </Box>
      )}
    </PageContainer>
  );
};

export default MyCampaigns;
