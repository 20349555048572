import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import React, { createRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import QuicksightService from 'services/aws_api_gateway/quicksight.service';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    minWidth: '100%',
    minHeight: '50vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dashboard: {
    width: '100%',
  },
}));

const QuickSightDashboard = ({}) => {
  const classes = useStyles();
  const dashboardRef = createRef();

  const [dashboardUrl, setDashboardUrl] = useState(null);
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(true);

  const { currentAccessEntity } = useSelector((state) => state.accessEntities);

  useEffect(() => {
    QuicksightService.getDashboardUrl()
      .then((response) => setDashboardUrl(response.data.EmbedUrl))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (!dashboardUrl || !currentAccessEntity?.uuid) {
      return;
    }

    const dashboard = embedDashboard({
      url: `${dashboardUrl}#p.MarketerUUID=${currentAccessEntity.uuid}`,
      container: dashboardRef.current,
      scrolling: 'no',
      height: 'AutoFit',
      iframeResizeOnSheetChange: true, // use this option in combination with height: AutoFit, to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
      width: '100%',
      locale: 'en-US',
      footerPaddingEnabled: true,
      // sheetId: 'YOUR_SHEETID', // use this option to specify initial sheet id to load for the embedded dashboard
      sheetTabsDisabled: false, // use this option to enable or disable sheet tab controls in dashboard embedding
      printEnabled: false, // use this option to enable or disable print option for dashboard embedding
      undoRedoDisabled: false, // set this option to true to disable undo and redo buttons for dashboard embedding
      resetDisabled: false, // set this option to true to disable reset button for dashboard embedding
      // defaultEmbeddingVisualType: TABLE // this option only applies to experience embedding and will not be used for dashboard embedding
    });

    dashboard.on('error', setIsLoadingDashboard(false));
    dashboard.on('load', setIsLoadingDashboard(false));
  }, [dashboardUrl, currentAccessEntity]);

  return (
    <>
      {isLoadingDashboard && (
        <Box className={classes.container}>
          <CircularProgress />
        </Box>
      )}
      <div id="dashboard" ref={dashboardRef} className={classes.dashboard} />
    </>
  );
};

export default QuickSightDashboard;
