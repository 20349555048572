import React from 'react';
import RecipientsModal from '../../RecipientsModal';
import { Box, Typography } from '@material-ui/core';
import IntlMessages from '../../../../../@jumbo/utils/IntlMessages';
import ActionButtonsContainer from '../ActionButtons/ActionButtonsContainer';
import BackButton from '../ActionButtons/BackButton';
import MCardsTable from '../../../MCardsTable';
import FullName from '../../CampaignReport/recipients/FullName';
import dollars from '../../../../../helpers/dollars';
import service from '../../../../../services/distributor_api/recipients.service';
import { useIntl } from 'react-intl';
import SearchableList from '../../../../SearchableList';
import SearchText from '../../../../filters/SearchText';
import MCardsButton from '../../../MCardsForm/MCardsButton';
import moment from 'moment';

const ScheduledDate = ({ item, currentDeliveryOptions }) => {
  let date;
  const intl = useIntl();

  if (item.delivery_options.delivery_type === 'instant') {
    date = intl.formatMessage({
      id: 'pages.manage.recipients.carousel.steps.schedule.confirmation.instant_delivery',
    });
  } else if (item.delivery_options.delivery_type === 'scheduled') {
    date = moment(item.delivery_options.delivery_date);
  } else if (!!currentDeliveryOptions) {
    date = currentDeliveryOptions[item.uuid] ?? currentDeliveryOptions.default;
  }

  return moment.isDate(date) || moment.isMoment(date) ? moment(date).format('DD/MM/YY h:mm A') : date;
};

const RecipientRows = ({ currentList, currentDeliveryOptions }) => {
  return (
    <MCardsTable
      headers={['amount', 'name', 'mobile_number', 'email', 'message', 'scheduled'].map((header) => (
        <IntlMessages id={`pages.manage.campaigns.recipients.table.col.${header}`} />
      ))}
      rows={currentList?.map((item) => {
        return [
          dollars(item.amount),
          <FullName recipient={item} />,
          item.phone,
          item.email,
          item.message,
          <ScheduledDate item={item} currentDeliveryOptions={currentDeliveryOptions} />,
        ];
      })}
    />
  );
};

const ReviewRecipientsModal = ({
  campaign,
  isOpen,
  onConfirm,
  onClose,
  showBackButton,
  titleI18N,
  subtitleI18N,
  buttonI18N,
  currentDeliveryOptions,
}) => {
  const RecipientsService = service(campaign.uuid);
  const intl = useIntl();

  return (
    <RecipientsModal isOpen={isOpen} onClose={onClose}>
      <Box mb={8}>
        <Typography variant="h1">
          <IntlMessages id={titleI18N} />
        </Typography>
        {subtitleI18N && (
          <Box mt={2}>
            <Typography variant="subtitle1" color="textSecondary">
              <IntlMessages id={subtitleI18N} />
            </Typography>
          </Box>
        )}
      </Box>

      <SearchableList
        getAll={(filters, page) => RecipientsService.getAll(filters, page, { delivery_status: 'queued' })}
        ListComponent={({ currentList }) => (
          <RecipientRows currentList={currentList} currentDeliveryOptions={currentDeliveryOptions} />
        )}
        filters={[
          <SearchText
            placeholder={intl.formatMessage({ id: 'pages.manage.campaigns.recipients.search.placeholder' })}
            searchBarXs={12}
          />,
        ]}
      />

      <ActionButtonsContainer>
        {showBackButton && <BackButton onClick={onClose} />}
        <MCardsButton type="button" onClick={onConfirm}>
          <IntlMessages id={buttonI18N} />
        </MCardsButton>
      </ActionButtonsContainer>
    </RecipientsModal>
  );
};

export default ReviewRecipientsModal;
