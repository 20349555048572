import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import TextInputHelperText from './TextInputHelperText';

const MCardsTextField = ({ type, label, placeholder, autoFocus, error, helperText, ...rest }) => {
  const classes = useStyles();

  return (
    <TextField
      {...rest}
      type={type}
      variant="outlined"
      classes={{ root: classes.root }}
      label={label}
      placeholder={placeholder || label}
      autoFocus={autoFocus}
      error={error}
      helperText={<TextInputHelperText text={helperText} error={error} />}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputLabel-outlined': {
      color: 'rgba(0, 0, 0, 0.4)',
      fontSize: '.875rem',
      transform: 'translate(14px, 14px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.85)',
      },
      '&.Mui-focused': {
        color: theme.palette.primary.main,
      },
      '&.Mui-error': {
        color: theme.status.error,
      },
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '5.76365px',
      '& fieldset': {
        borderColor: '#EBEBEB',
        transition: 'all ease-in-out 0.25s',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
        transition: 'all ease-in-out 0.25s',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.MuiOutlinedInput-multiline': {
        padding: 0,
      },
      '& .MuiOutlinedInput-input': {
        fontSize: '.875rem',
        padding: '.875rem',
      },
      '&.Mui-error': {
        '&:hover fieldset': {
          borderColor: theme.status.error,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.status.error,
        },
      },
      '&.Mui-disabled': {
        background: 'rgba(0, 0, 0, 0.01)',
        '& fieldset': {
          borderColor: '#EBEBEB',
        },
        '&:hover fieldset': {
          borderColor: '#EBEBEB',
        },
      },
    },
    '& .MuiFormHelperText-root': {
      alignItems: 'center',
      display: 'flex',
      fontStyle: 'italic',
      marginLeft: 0,
      marginTop: '5px',
      '&.Mui-error': {
        color: theme.status.error,
      },
      '& img': {
        marginRight: '5px',
      },
    },

    // setting this animationName to 'auto' to fix an issue with react-google-maps-api's StandaloneSearchBox,
    // where it sets this to 'endBrowserAutofill' on load which affects the label animation.
    '& .pac-target-input:not(:-webkit-autofill)': {
      animationName: 'auto',
    },
  },
}));

MCardsTextField.defaultProps = {
  type: 'text',
  autoFocus: false,
};

MCardsTextField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  fullWidth: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default MCardsTextField;
