import MCardsButton from '../../../MCardsForm/MCardsButton';
import IntlMessages from '../../../../../@jumbo/utils/IntlMessages';
import React from 'react';

const BackButton = ({ onClick, disabled }) => {
  const i18nPath = 'pages.manage.campaigns.carousel.buttons';
  return (
    <MCardsButton type="button" color="default" onClick={onClick} disabled={disabled}>
      <IntlMessages id={`${i18nPath}.back`} />
    </MCardsButton>
  );
};

export default BackButton;
