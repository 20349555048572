import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import MCardsTable from '../MCardsTable';
import { CardUuid, CardStatus, CardTotalSpent, CardTransactions, CardJoinedDate } from '../Cards/CardRow';
import { activateCard, deactivateCard } from 'redux/reducers/distributor_api/cardholders/cardholders.thunks';
import { useDispatch, useSelector } from 'react-redux';
import MCardsButton from 'components/mcards/MCardsForm/MCardsButton';
import StatusLabel from 'components/StatusLabel';
import { Typography } from '@material-ui/core';

const ToggleCardButton = ({ card }) => {
  const dispatch = useDispatch();
  const { isUpdatingCard } = useSelector((state) => state.cardholders);
  const status = card.status;
  const labels = {
    pending: { text: <IntlMessages id="pages.manage.cards.status.pending" />, state: 'pending' },
    active: { text: <IntlMessages id="pages.manage.cards.status.active" />, state: 'success' },
    inactive: { text: <IntlMessages id="pages.manage.cards.status.inactive" />, state: 'warning' },
    link_failed: { text: <IntlMessages id="pages.manage.cards.status.link_failed" />, state: 'error' },
    issuer_error: { text: <IntlMessages id="pages.manage.cards.status.issuer_error" />, state: 'error' },
  };

  let toggleFn, toggleButtonText;
  if (status === 'inactive') {
    toggleFn = activateCard;
    toggleButtonText = 'Activate';
  } else if (status === 'active') {
    toggleFn = deactivateCard;
    toggleButtonText = 'Deactivate';
  }

  return ['active', 'inactive'].includes(status) ? (
    <MCardsButton type="button" onClick={() => toggleFn(dispatch, card)} disabled={isUpdatingCard}>
      <span>{toggleButtonText} card</span>
    </MCardsButton>
  ) : (
    <Typography component="h3" variant="h3">
      Card Status - <StatusLabel state={labels[status].state} text={labels[status].text} showDot={false} />
    </Typography>
  );
};

const CardholderCardsList = ({ currentList }) => {
  return (
    <MCardsTable
      headers={['Card UUID', 'Card joined', 'Card Status', 'Total Spent', 'Total Transactions']}
      rows={currentList?.map((card) => [
        <CardUuid card={card} />,
        <CardJoinedDate card={card} />,
        <CardStatus card={card} />,
        <CardTotalSpent card={card} />,
        <CardTransactions card={card} />,
        <ToggleCardButton card={card} />,
      ])}
      cellPadding={'1rem 1.5rem'}
    />
  );
};

export default CardholderCardsList;
