import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import PWModal from '../../../PWModal';
import MCardsButton from '../../MCardsForm/MCardsButton';
import MCardsModalTitleIcon from '../../MCardsModalTitleIcon';
import { publishCampaignAsync } from 'redux/reducers/distributor_api/campaigns/campaigns.thunks';
import IntlMessages from '@jumbo/utils/IntlMessages';

const PublishCampaignModal = ({ campaign, isOpen, closeModal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isPublishing } = useSelector((state) => state.campaigns);

  const publish = () => {
    publishCampaignAsync(campaign, dispatch);
  };

  return (
    <PWModal size="xs" fullWidth modalOpen={isOpen} onClose={closeModal}>
      <div className={classes.modalContainer}>
        <Box className={classes.iconBox}>
          <MCardsModalTitleIcon src="/images/icons/pencil.png" />
        </Box>

        <Typography align="center" variant="h1" className={classes.title}>
          <IntlMessages id="pages.manage.campaigns.publish.modal.title" />
        </Typography>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography variant="subtitle2" color="textSecondary">
                <IntlMessages id="pages.manage.campaigns.publish.modal.field1" />
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body2" color="textSecondary">
                {campaign.name}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <MCardsButton type="button" onClick={publish} className={classes.actionBtn} disabled={isPublishing}>
          <>
            {isPublishing ? (
              <IntlMessages id="pages.manage.campaigns.publish.modal.button.busy" />
            ) : (
              <IntlMessages id="pages.manage.campaigns.publish.modal.button" />
            )}
          </>
        </MCardsButton>
      </div>
    </PWModal>
  );
};

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    textAlign: 'center',
    border: 'none',
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    margin: '2rem 0',
    fontSize: '28px',
  },
  actionBtn: {
    marginTop: theme.spacing(6),
    paddingLeft: theme.spacing(13),
    paddingRight: theme.spacing(13),
  },
}));

export default PublishCampaignModal;
