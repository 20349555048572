import React from 'react';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/styles/makeStyles';
import IntlMessages from '@jumbo/utils/IntlMessages';
import StatusLabel from '../../StatusLabel';
import dollars from 'helpers/dollars';
import { getFormattedDate } from '@jumbo/utils/dateHelper';

export const CardholderJoinedDate = ({ cardholder }) => getFormattedDate(cardholder.created_at, 'DD MMM YYYY');

export const CardholderName = ({ cardholder }) => {
  const classes = useStyles();

  if (cardholder) {
    return (
      <Link to={`/reports/cardholders/${cardholder.uuid}`} className={classes.cardholderName}>
        {cardholder.name}
      </Link>
    );
  } else {
    return null;
  }
};

export const CardholderStatus = ({ cardholder }) => {
  const status = cardholder?.status;
  const labels = {
    active: { text: <IntlMessages id="pages.manage.cardholders.status.active" />, state: 'success' },
    inactive: { text: <IntlMessages id="pages.manage.cardholders.status.inactive" />, state: 'warning' },
    removed: { text: <IntlMessages id="pages.manage.cardholders.status.removed" />, state: 'error' },
  };

  return <StatusLabel state={labels[status].state} text={labels[status].text} />;
};

export const CardholderPhone = ({ cardholder }) => cardholder?.phone;

export const CardholderEmail = ({ cardholder }) => cardholder?.email;

export const CardholderTransactions = ({ cardholder }) => cardholder.statistics[0].value;

export const CardholderUuid = ({ cardholder }) => cardholder.uuid;

export const CardholderTotalSpent = ({ cardholder }) => dollars(cardholder.statistics[1].value, 0);

const useStyles = makeStyles((theme) => ({
  cardholderName: {
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
