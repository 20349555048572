import React from 'react';
import PropTypes from 'prop-types';
import MCardsTable from '../../MCardsTable';
import dollars from 'helpers/dollars';
import SearchableList from 'components/SearchableList';
import SearchText from 'components/filters/SearchText';
import IntlMessages from '@jumbo/utils/IntlMessages';
import SentAt from './recipients/SentAt';
import Status, { getStatusLabel } from './recipients/Status';
import FullName from './recipients/FullName';
import DropdownFilter from '../../../filters/DropdownFilter';
import service from 'services/distributor_api/recipients.service';
import { useIntl } from 'react-intl';

const RecipientRows = ({ currentList, campaign, onRecipientUpdated = null }) => {
  return (
    <MCardsTable
      headers={['sent_date', 'name', 'mobile_number', 'email', 'amount', 'status'].map((header) => (
        <IntlMessages id={`pages.manage.campaigns.recipients.table.col.${header}`} />
      ))}
      rows={currentList?.map((item) => {
        return [
          <SentAt sentDate={item.sent_at} deliveryDate={item.delivery_options?.delivery_date} />,
          <FullName recipient={item} />,
          item.phone,
          item.email,
          dollars(item.amount),
          <Status campaign={campaign} item={item} onRecipientUpdated={onRecipientUpdated} />,
        ];
      })}
    />
  );
};

const RecipientsList = ({ campaign, deliveryStatus = null, countHeadingTitle = null, onReload }) => {
  const RecipientsService = service(campaign.uuid);
  const intl = useIntl();

  return (
    <>
      <SearchableList
        getAll={(filters, page) => RecipientsService.getAll(filters, page, { delivery_status: deliveryStatus })}
        ListComponent={(props) => <RecipientRows {...props} campaign={campaign} onRecipientUpdated={onReload} />}
        filters={[
          <SearchText
            placeholder={intl.formatMessage({ id: 'pages.manage.campaigns.recipients.search.placeholder' })}
            searchBarXs={10}
          />,
          <DropdownFilter
            type="recipients-status"
            placeholder="Status"
            filterBy="status"
            options={[
              { value: '', label: getStatusLabel('all') },
              { value: 'activated', label: getStatusLabel('activated') },
              { value: 'cancelled', label: getStatusLabel('cancelled') },
              { value: 'failed', label: getStatusLabel('failed') },
              { value: 'not_activated', label: getStatusLabel('not_activated') },
              { value: 'scheduled', label: getStatusLabel('scheduled') },
            ]}
          />,
        ]}
        countHeadingTitle={countHeadingTitle}
      />
    </>
  );
};

RecipientsList.propTypes = {
  campaign: PropTypes.object.isRequired,
  deliveryStatus: PropTypes.string,
  countHeadingTitle: PropTypes.any,
};

export default RecipientsList;
