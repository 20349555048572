import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from '@material-ui/core';
import dollars from 'helpers/dollars';
import StatusLabel from '../../../StatusLabel';

const LocationName = ({ uuid, name }) => (uuid && name ? <Link href={`/reports/locations/${uuid}`}>{name}</Link> : name);

const TerminalsCount = ({ uuid, terminals }) => {
  const history = useHistory();

  const autoVerified = terminals?.filter((terminal) => terminal.verification_status === 'auto_verified') || [];
  const numberOfAutoVerified = autoVerified?.length;
  const totalTerminals = terminals?.length || 0;

  const goToLocation = () => {
    history.push(`/reports/locations/${uuid}`);
  };

  const getStateStyle = () => {
    switch (true) {
      case totalTerminals === 0:
        return 'warning';
      case numberOfAutoVerified > 0:
        return 'danger';
      default:
        return 'success';
    }
  };

  return (
    <StatusLabel
      state={getStateStyle()}
      text={
        <>
          {totalTerminals} {!!numberOfAutoVerified ? `(${numberOfAutoVerified} pending)` : null}
        </>
      }
      onClick={!!numberOfAutoVerified ? goToLocation : null}
      showDot={false}
    />
  );
};

const CampaignsCount = ({ campaigns }) => (
  <StatusLabel state={campaigns.length <= 0 ? 'warning' : 'success'} text={campaigns.length} />
);

const LocationsRow = (location) => {
  return [
    <LocationName uuid={location?.uuid} name={location?.name} />,
    location?.address?.long_address,
    location?.address?.phone,
    dollars(location?.total_spent),
    <TerminalsCount uuid={location?.uuid} terminals={location?.terminals} />,
    <CampaignsCount campaigns={location?.campaigns} />,
  ];
};

export default LocationsRow;
