import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import CardDesignsService from 'services/distributor_api/carddesigns.service';
import actions from './carddesigns.actions';

export const loadCarddesignAsync = (uuid, dispatch) => {
  dispatch(actions.carddesignLoadStart());
  dispatch(fetchStart());

  CardDesignsService.getByUuid(uuid)
    .then((response) => {
      dispatch(actions.carddesignLoadSuccess(response.data));
      dispatch(fetchSuccess());
    })
    .catch((error) => {
      dispatch(actions.carddesignLoadError(error.message));
      dispatch(fetchError(error.message));
    });
};
