export default {
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isPublishing: false,
  preventTransition: false,
  currentCampaign: null,
  currentList: null,
  resultsAndPagination: null,
  errorMessage: null,
  csvErrors: null,
};
