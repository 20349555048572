import React from 'react';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import CampaignReviewSummary from './ReviewStep/CampaignReviewSummary';
import ActionButtonsContainer from './ActionButtons/ActionButtonsContainer';
import PublishButton from './ActionButtons/PublishButton';

const ReviewStep = () => {
  const classes = useStyles();

  const { currentCampaign, isPublishing } = useSelector((state) => state.campaigns);

  return (
    currentCampaign && (
      <Box className={classes.reviewContainer}>
        <CampaignReviewSummary
          name={currentCampaign.name}
          restrictionsCount={currentCampaign.location_restrictions?.length ?? 0}
        />

        <ActionButtonsContainer>
          <PublishButton campaign={currentCampaign} disabled={isPublishing} />
        </ActionButtonsContainer>
      </Box>
    )
  );
};

const useStyles = makeStyles((theme) => ({
  reviewContainer: {
    paddingTop: '3rem',
    margin: '0px 0.5rem',
  },
}));

export default ReviewStep;
