import React, { useEffect, useState, useRef } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import RecipientsFileModal from './RecipientsFileModal';
import MCardsButton from '../../../MCardsForm/MCardsButton';
import RecipientsListForm from './RecipientsListForm';
import ActiveRecipientsModal from './ActiveRecipientsModal';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { extractPhoneFromItem } from 'helpers/phone';
import CampaignsService from '../../../../../services/distributor_api/campaigns.service';
import PageLoader from '../../../../../@jumbo/components/PageComponents/PageLoader';
import ErrorMessage from '../../../../ErrorMessage';
import { consolidateGenericErrorMessages } from '../../../../../helpers/response';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import IntlMessages from '../../../../../@jumbo/utils/IntlMessages';
import { notDeliveredNorCancelled } from '../../../../../helpers/campaign';
import ActionButtonsContainer from '../ActionButtons/ActionButtonsContainer';

const ActiveRecipientsLink = ({ toggle }) => {
  return (
    <Box mt={8}>
      <MCardsButton variant="text" type="button" onClick={toggle}>
        See Active Recipients
      </MCardsButton>
    </Box>
  );
};

const i18nPath = 'pages.manage.recipients.carousel.steps.recipients';

const RecipientsStep = ({ currentCampaign, onCampaignUpdated }) => {
  // Initialization

  const defaultRecipient = {
    first_name: '',
    last_name: '',
    phone_without_country_code: '',
    phone_country_code: '',
    email: '',
    message: '',
    balances_attributes: [{ type: 'mcash_balance', value: '' }],
  };

  const initFormState = () => {
    if (currentCampaign?.items?.length > 0) {
      setInitialFormState({
        items_attributes: currentCampaign.items.filter(notDeliveredNorCancelled).map(toItemWithBalanceAttrs),
      });
    }
  };

  useEffect(initFormState, [currentCampaign]);

  // States

  const [initialFormState, setInitialFormState] = useState({ items_attributes: [defaultRecipient] });
  const [removedRecipients, setRemovedRecipients] = useState([]);
  const [recipientsModalOpen, setRecipientsModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [activeRecipientsModalOpen, setActiveRecipientsModalOpen] = useState(false);
  const [selectedMode, setSelectedMode] = useState('manual');
  const formRef = useRef(null);

  // Callbacks

  const onRecipientRemove = (recipientToRemove) => {
    setRemovedRecipients((removedRecipients) => [...removedRecipients, recipientToRemove]);
  };

  const onSubmit = (form) => {
    const campaign = buildCampaign(form, removedRecipients);
    setIsUpdating(true);
    setErrorMessage('');
    CampaignsService.update(currentCampaign['uuid'], campaign)
      .then(({ data }) => onCampaignUpdated(data))
      .catch((error) => setErrorMessage(consolidateGenericErrorMessages(error)))
      .finally(() => setIsUpdating(false));
  };

  const onRecipientsUploaded = (updatedCampaign) => {
    closeRecipientsModal();
    onCampaignUpdated(updatedCampaign, { preventTransition: true });
  };

  const closeRecipientsModal = () => setRecipientsModalOpen(false);
  const toggleActiveRecipientsModal = () => setActiveRecipientsModalOpen((prevState) => !prevState);

  const toItemWithBalanceAttrs = (item) => {
    return { ...item, balances_attributes: item.balances };
  };

  const filledItem = ({ first_name, last_name, phone, email, balances_attributes }) => {
    return first_name && last_name && phone && email && balances_attributes[0].value;
  };

  const buildCampaign = (form, removedRecipients = []) => {
    return {
      items_attributes: form['items_attributes']
        .concat(removedRecipients)
        .map((item) => ({
          ...item,
          phone: extractPhoneFromItem(item),
          balances_attributes: [
            {
              ...item.balances_attributes[0],
              value: item.balances_attributes[0].value.replace(/[^0-9.]/g, ''),
            },
          ],
          delivery_method: 'SMS',
          voided: false,
        }))
        .filter(filledItem),
    };
  };

  const activeRecipientsPresent = () => currentCampaign?.delivery_statistics?.sent_count > 0;

  const classes = useStyles();
  return (
    <Box position="relative">
      {errorMessage && <ErrorMessage message={errorMessage} />}
      {isUpdating && <PageLoader />}
      <Form
        onSubmit={onSubmit}
        initialValues={initialFormState}
        mutators={{
          ...arrayMutators,
        }}
        render={({
          handleSubmit,
          form: {
            mutators: { push },
          },
        }) => (
          <form onSubmit={handleSubmit} ref={formRef} className={classes.recipientsForm}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="details"
              value={selectedMode}
              style={{ flexDirection: 'row', justifyContent: 'center', marginBottom: '1em' }}>
              <FormControlLabel
                value="manual"
                control={<Radio color="primary" />}
                label=<IntlMessages id={`${i18nPath}.radio_buttons.manual`} />
                onChange={() => setSelectedMode('manual')}
              />
              <FormControlLabel
                value="file_upload"
                control={<Radio color="primary" />}
                label=<IntlMessages id={`${i18nPath}.radio_buttons.file_upload`} />
                onChange={() => {
                  setSelectedMode('file_upload');
                  setRecipientsModalOpen(true);
                }}
              />
            </RadioGroup>

            <RecipientsListForm onRecipientRemove={onRecipientRemove} pushFn={push} />

            <RecipientsFileModal
              isOpen={recipientsModalOpen}
              campaignUuid={currentCampaign?.uuid}
              onCancel={closeRecipientsModal}
              onSuccess={onRecipientsUploaded}
            />

            <ActionButtonsContainer>
              <MCardsButton disabled={isUpdating}>
                <IntlMessages id="pages.manage.campaigns.carousel.buttons.next" />
              </MCardsButton>
            </ActionButtonsContainer>

            {activeRecipientsPresent() && (
              <>
                <ActiveRecipientsModal
                  campaign={currentCampaign}
                  isOpen={activeRecipientsModalOpen}
                  onClose={toggleActiveRecipientsModal}
                />
                <ActiveRecipientsLink toggle={toggleActiveRecipientsModal} />
              </>
            )}
          </form>
        )}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  recipientsForm: {
    minWidth: '100%',
    marginTop: '2rem',
    height: '556px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default RecipientsStep;
