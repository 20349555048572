import React from 'react';
import { Card, CardContent, CardMedia } from '@material-ui/core';
import HeaderTitleAndSubtitle from 'components/mcards/HeaderTitleAndSubtitle';
import { EmailIconAndText, PhoneIconAndText } from 'components/mcards/MCardsIconAndText';
import makeStyles from '@material-ui/styles/makeStyles';
import { formatDateRelative } from 'helpers/dates';

const CardholderHeading = ({ cardholder }) => {
  const classes = headerStyle();
  const profilePicture = cardholder?.picture ?? '/images/icons/user_icon.png';

  return cardholder ? (
    <Card className={classes.card}>
      <CardMedia className={classes.cardImage} image={profilePicture} title={cardholder.name} />
      <CardContent className={classes.cardContent}>
        <HeaderTitleAndSubtitle title={cardholder.name} subtitle={`Joined: ${formatDateRelative(cardholder.created_at)}`} />
      </CardContent>
      <CardContent className={classes.cardContent}>
        <PhoneIconAndText phone={cardholder.phone} />
        <EmailIconAndText email={cardholder.email} />
      </CardContent>
    </Card>
  ) : null;
};

const headerStyle = makeStyles((theme) => ({
  mainTitle: {
    fontSize: '2rem',
  },
  card: {
    boxShadow: 'none',
    display: 'flex',
  },
  cardContent: {
    textAlign: 'left',
    '& > :not(:first-child)': {
      marginTop: '1.5rem',
    },
  },
  actionButtonCardContent: {
    marginLeft: 'auto',
  },
  field: {},
  cardImage: {
    backgroundColor: 'lightgrey',
    borderRadius: '100%',
    height: 100,
    width: 100,
    padding: '1rem',
  },
  cardName: {
    marginBottom: '1rem',
  },
}));

export default CardholderHeading;
