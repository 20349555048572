import React from 'react';
import PropTypes from 'prop-types';
import { Box, CardMedia, makeStyles, Typography } from '@material-ui/core';
import PWModal from '../../../../../PWModal';
import MCardsModalTitleIcon from '../../../../MCardsModalTitleIcon';
import MCardsButton from '../../../../MCardsForm/MCardsButton';
import IntlMessages from '@jumbo/utils/IntlMessages';

const WizardCompletedModal = ({ isEdit, openModal, onComplete, mainImage }) => {
  const classes = useStyles();
  const i18nPath = 'pages.manage.campaigns.carousel.steps.confirmation.modal';

  const contents = {
    create: {
      icon: 'send-aeroplane-icon',
      title: `${i18nPath}.submitted.title`,
      message: `${i18nPath}.submitted.message`,
      ctaButtons: [{ id: 0, text: `${i18nPath}.submitted.button`, variant: 'contained', onClick: onComplete }],
    },
    edit: {
      icon: 'pencil',
      title: `${i18nPath}.updated.title`,
      message: `${i18nPath}.updated.message`,
      ctaButtons: [{ id: 0, text: `${i18nPath}.updated.button`, variant: 'contained', onClick: onComplete }],
    },
  };
  const content = isEdit ? contents['edit'] : contents['create'];

  return (
    <PWModal size="xs" modalOpen={openModal}>
      <div className={classes.modalContainer}>
        <MCardsModalTitleIcon src={`/images/icons/${content.icon}.png`} />

        <Box my={4}>
          <Typography align="center" variant="h1">
            <IntlMessages id={content.title} />
          </Typography>
        </Box>

        <Typography variant="subtitle1" color="textSecondary" align="center">
          <IntlMessages id={content.message} />
        </Typography>

        {mainImage && (
          <Box width="310px" height="200px" my={4}>
            <CardMedia image={mainImage} title="The card design image" classes={{ root: classes.mainImg }} />
          </Box>
        )}

        {content.ctaButtons.map((btn) => (
          <MCardsButton key={btn.id} onClick={btn.onClick} className={classes.completeBtn} variant={btn.variant}>
            <IntlMessages id={btn.text} />
          </MCardsButton>
        ))}
      </div>
    </PWModal>
  );
};

const useStyles = makeStyles(() => ({
  modalContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
  },
  mainImg: {
    borderRadius: '12px',
    filter: 'drop-shadow(0px 4.48043px 8.96085px rgba(0, 0, 0, 0.25))',
    minHeight: '100%',
  },
  completeBtn: {
    margin: '0.5rem 0',
  },
}));

WizardCompletedModal.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  openModal: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  mainImage: PropTypes.string,
};

export default WizardCompletedModal;
