import React from 'react';
import CmtImage from '@coremat/CmtImage';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import MCardsSelectInput from './MCardsForm/MCardsSelectInput';
import fetchFormError from 'helpers/validators/fetchFormError';

const CountryCodeSelector = ({ countryOptions, input, meta, width }) => {
  const classes = useStyles();

  return (
    <MCardsSelectInput
      {...input}
      className={classes.countrySelect}
      error={!!fetchFormError(meta)}
      width={width}
      options={countryOptions.map(({ country_code_number }) => ({
        value: country_code_number,
        label: <LabelItem classes={classes} country_code_number={country_code_number} />,
      }))}
    />
  );
};

const LabelItem = ({ classes, country_code_number }) => (
  <Box className={classes.optionContainer}>
    <CmtImage src={`/images/icons/country_codes/${country_code_number}.png`} className={classes.countryImg} />
    <span>+{country_code_number}</span>
  </Box>
);

const useStyles = makeStyles(() => ({
  countrySelect: {
    '& .MuiSelect-root': {
      paddingRight: '2rem!important',
    },
  },
  optionContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  countryImg: {
    maxHeight: '16px',
    marginRight: '5px',
  },
}));
export default CountryCodeSelector;
