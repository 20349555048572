import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Box, Grid, Typography } from '@material-ui/core';
import TerminalIdentifierCell from './TerminalRow/TerminalIdentifierCell';
import TerminalNameCell from './TerminalRow/TerminalNameCell';
import TerminalStatusCell from './TerminalRow/TerminalStatusCell';
import TerminalVerificationCell from './TerminalRow/TerminalVerificationCell';
import TerminalDeleteCell from './TerminalRow/TerminalDeleteCell';
import makeStyles from '@material-ui/styles/makeStyles';
import AddTerminalForm from '../AddTerminalForm';

const TerminalsTable = ({ terminals, location, onTerminalUpdated }) => {
  const classes = useStyles();
  const headers = [
    { id: 'terminal_id', xs: 3 },
    { id: 'merchant_trade_name', xs: 4 },
    { id: 'status', xs: 2 },
    { id: '', xs: 3 },
  ];

  return terminals?.length > 0 ? (
    <Box>
      <Box className={classes.headers}>
        <Grid container spacing={2} style={{ marginLeft: '2rem' }}>
          {headers.map(({ id, xs }) => (
            <Grid key={id} item xs={xs}>
              <Typography variant="h4" component="h4">
                {!!id && <IntlMessages id={`pages.manage.locations.details.tab.terminals.table.${id}`} />}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>

      {terminals.map((terminal, index) => (
        <Box key={index} className={classes.gridRow}>
          <Box className={classes.addIconSpace}>{/* filling for missing (+) icon */}</Box>
          <Grid container spacing={2} alignItems="center" className={classes.gridRowContent}>
            <Grid item xs={3}>
              <TerminalIdentifierCell terminal={terminal} showIcon={false} />
            </Grid>
            <Grid item xs={4}>
              <TerminalNameCell terminal={terminal} showIcon={false} />
            </Grid>
            <Grid item xs={2}>
              <TerminalStatusCell terminal={terminal} location={location} onTerminalUpdated={onTerminalUpdated} />
            </Grid>
            <Grid item xs={3}>
              <TerminalVerificationCell terminal={terminal} />
            </Grid>
          </Grid>
          <TerminalDeleteCell terminal={terminal} />
        </Box>
      ))}
      {!!location && <AddTerminalForm existingLocation={location} />}
    </Box>
  ) : (
    <IntlMessages id="pages.manage.locations.components.terminals.emptyTerminalsList" />
  );
};

const useStyles = makeStyles((theme) => ({
  headers: {
    margin: theme.spacing(4, 11.5, 4, 0),
    padding: theme.spacing(0, 4),
  },
  gridRow: {
    alignItems: 'center',
    display: 'flex',
    margin: theme.spacing(6, 0),
  },
  gridRowContent: {
    background: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.17)',
    padding: theme.spacing(0, 4),
  },
  addIconSpace: {
    marginLeft: '2rem',
    width: '30px',
  },
}));

export default TerminalsTable;
