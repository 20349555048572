import DistributorService from '.';
import { adaptServiceGetAllResults } from './adaptResultsPromise';

class RecipientsService extends DistributorService {
  constructor(campaignUuid) {
    super(`campaigns/${campaignUuid}/items`);
  }

  retrySMS = async (uuid, body) => (await this.api()).post(`${this.resource}/${uuid}/retry`, { item: body });
}

export default (campaignUuid) => adaptServiceGetAllResults(new RecipientsService(campaignUuid), 'items');
