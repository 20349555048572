import actionTypes from './locations.actionTypes';

export default {
  getStatsStart: () => ({
    type: actionTypes.GET_STATS_START,
  }),

  getStatsSuccess: (data, locationUuid) => ({
    type: actionTypes.GET_STATS_SUCCESS,
    currentLocationUuid: locationUuid,
    payload: data,
  }),

  getStatsError: (errorMessage) => ({
    type: actionTypes.GET_STATS_ERROR,
    payload: errorMessage,
  }),

  getOneStart: () => ({
    type: actionTypes.GET_ONE_START,
  }),

  getOneSuccess: (data) => ({
    type: actionTypes.GET_ONE_SUCCESS,
    payload: data,
  }),

  getOneError: (errorMessage) => ({
    type: actionTypes.GET_ONE_ERROR,
    payload: errorMessage,
  }),

  createStart: () => ({
    type: actionTypes.CREATE_START,
  }),

  createSuccess: (data) => ({
    type: actionTypes.CREATE_SUCCESS,
    payload: data,
  }),

  createError: (errorMessage) => ({
    type: actionTypes.CREATE_ERROR,
    payload: errorMessage,
  }),

  addTerminalStart: () => ({
    type: actionTypes.ADD_TERMINAL_START,
  }),

  addTerminalSuccess: (data) => ({
    type: actionTypes.ADD_TERMINAL_SUCCESS,
    payload: data,
  }),

  addTerminalError: (errorMessage) => ({
    type: actionTypes.ADD_TERMINAL_ERROR,
    payload: errorMessage,
  }),
};
