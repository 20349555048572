import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import CampaignsService from 'services/distributor_api/campaigns.service';
import actions from './campaigns.actions';
import { consolidateGenericErrorMessages } from '../../../../helpers/response';

export const getAllCampaignsAsync = (dispatch, filters, page = null) => {
  dispatch(actions.campaignGetAllStart());
  dispatch(fetchStart());

  CampaignsService.getAll(filters, page)
    .then((response) => {
      const { results, pagination } = response.data;

      dispatch(actions.campaignGetAllSuccess({ results, pagination }));
      dispatch(fetchSuccess());
    })
    .catch((error) => {
      dispatch(actions.campaignGetAllError(error.message));
      dispatch(fetchError(error.message));
    });
};

export const getOneCampaignAsync = (dispatch, campaignUuid) => {
  dispatch(actions.campaignGetOneStart());
  dispatch(fetchStart());

  CampaignsService.getByUuid(campaignUuid)
    .then((response) => {
      const campaign = response.data;

      dispatch(actions.campaignGetOneSuccess(campaign));
      dispatch(fetchSuccess());
    })
    .catch((error) => {
      dispatch(actions.campaignGetOneError(error.message));
      dispatch(fetchError(error.message));
    });
};

export const createCampaignAsync = (campaign, dispatch, { preventTransition } = {}) => {
  dispatch(actions.campaignCreateStart(preventTransition));
  dispatch(fetchStart());

  CampaignsService.create(campaign)
    .then((response) => {
      dispatch(actions.campaignCreateSuccess(response.data));
      if (!preventTransition) dispatch(fetchSuccess());
    })
    .catch((error) => {
      dispatch(actions.campaignCreateError(error.message));
      dispatch(fetchError(error.message));
    });
};

export const updateCampaignAsync = (campaign, dispatch, { preventTransition } = {}) => {
  dispatch(actions.campaignUpdateStart(preventTransition));
  dispatch(fetchStart());

  if (campaign.status === 'draft') campaign.status = 'unpublished';

  return CampaignsService.update(campaign['uuid'], campaign)
    .then((response) => {
      dispatch(actions.campaignUpdateSuccess(response.data));
      if (!preventTransition) dispatch(fetchSuccess());
      return response;
    })
    .catch((error) => {
      let message = consolidateGenericErrorMessages(error);
      dispatch(actions.campaignUpdateError(message));
      dispatch(fetchError(message));
      throw error;
    });
};

export const createOrUpdateCampaignAsync = (campaign, dispatch, opts) => {
  return campaign['uuid'] != null
    ? updateCampaignAsync(campaign, dispatch, opts)
    : createCampaignAsync({ campaign: campaign }, dispatch, opts);
};

export const scheduleCampaignAsync = (campaign, deliveryOptions, dispatch) => {
  campaign['delivery_options'] = deliveryOptions;

  updateCampaignAsync(campaign, dispatch);
};

export const publishCampaignAsync = (campaign, dispatch) => {
  campaign.status = 'published';

  dispatch(actions.campaignPublishStart());

  updateCampaignAsync(campaign, dispatch)
    .then((response) => dispatch(actions.campaignPublishSuccess(response.data)))
    .catch((error) => dispatch(actions.campaignPublishError(error.message)));
};
