import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import AccessEntitiesService from 'services/distributor_api/access_entities.service';
import actions from './access_entities.actions';

export const getAccessEntitiesAsync = (dispatch) => {
  dispatch(actions.accessEntitiesGetStart());
  dispatch(fetchStart());

  AccessEntitiesService.get()
    .then((response) => {
      const access_entities = response.data;

      dispatch(actions.accessEntitiesGetSuccess(access_entities));
      dispatch(fetchSuccess());
    })
    .catch((error) => {
      dispatch(actions.accessEntitiesGetError(error.message));
      dispatch(fetchError(error.message));
    });
};
