import actionTypes from './stats.actionTypes';

export default {
  getAllStart: () => ({
    type: actionTypes.GET_ALL_START,
  }),

  getAllSuccess: (data) => ({
    type: actionTypes.GET_ALL_SUCCESS,
    payload: data,
  }),

  getAllError: (errorMessage) => ({
    type: actionTypes.GET_ALL_ERROR,
    payload: errorMessage,
  }),
};
