import DistributorService from '.';
import { adaptServiceGetAllResults } from './adaptResultsPromise';

class CampaignsService extends DistributorService {
  constructor() {
    super('campaigns');
  }

  uploadRecipientsFile = async (uuid, body) => (await this.api()).post(`${this.resource}/${uuid}/items/process_csv`, body);
}

export default adaptServiceGetAllResults(new CampaignsService(), 'campaigns');
