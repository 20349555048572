import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import actions from './stats.actions';
import service from 'services/distributor_api/cardholders.service';

export const getCardholdersStatsAsync = (dispatch) => {
  dispatch(actions.getAllStart());
  dispatch(fetchStart());

  service
    .getRelative('stats')
    .then((response) => {
      dispatch(actions.getAllSuccess(response.data));
      dispatch(fetchSuccess());
    })
    .catch((error) => {
      dispatch(actions.getAllError(error.message));
      dispatch(fetchError(error.message));
    });
};
