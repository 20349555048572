import { fetchError, fetchStart, fetchSuccess } from '../../../actions';
import actions from './locations.action';
import service from 'services/distributor_api/locations.service';

export const getStats = (dispatch, locationUuid = null) => {
  dispatch(actions.getStatsStart());
  dispatch(fetchStart());

  service
    .getRelative(locationUuid ? `${locationUuid}/stats` : 'stats')
    .then((response) => {
      dispatch(actions.getStatsSuccess(response.data, locationUuid));
      dispatch(fetchSuccess());
    })
    .catch((error) => {
      dispatch(actions.getStatsError(error.message));
      dispatch(fetchError(error.message));
    });
};

export const getOne = (dispatch, locationUUID, identifierType = null) => {
  dispatch(actions.getOneStart());

  service
    .getByUuid(locationUUID, { params: { identifier_type: identifierType } })
    .then((response) => {
      dispatch(actions.getOneSuccess(response.data));
    })
    .catch((error) => {
      dispatch(actions.getOneError(error.message));
    });
};

export const create = (dispatch, locationName, addressAttributes, terminalsAttributes) => {
  dispatch(actions.createStart());

  const payload = {
    name: locationName,
    address_attributes: addressAttributes,
    terminals_attributes: terminalsAttributes,
  };

  service
    .create(payload)
    .then((response) => {
      dispatch(actions.createSuccess(response.data));
    })
    .catch((error) => {
      dispatch(actions.createError(error.message));
    });
};

export const addTerminal = (dispatch, locationUUID, terminalsAttributes) => {
  dispatch(actions.addTerminalStart());

  service
    .update(locationUUID, { terminals_attributes: terminalsAttributes })
    .then((response) => {
      dispatch(actions.addTerminalSuccess(response.data));
    })
    .catch((error) => {
      dispatch(actions.addTerminalError(error.message));
    });
};
