import axios from 'axios';
import { auth0Client } from '../auth/auth0';

class DistributorService {
  #pageNumber;

  constructor(resource) {
    this.resource = resource;
    this.perPage = 10;
    this.#pageNumber = 1;
  }

  api = async () => {
    const auth0 = await auth0Client;
    const token = await auth0.getTokenSilently();

    return axios.create({
      headers: {
        'content-type': 'application/json',
        'X-Authorization-AccessJWT': `Auth0 ${token}`,
      },
      baseURL: process.env.REACT_APP_SERVER_URL,
      responseType: 'json',
    });
  };

  get = async (params = {}) => (await this.api()).get(this.resource, params);

  getAll = (filters, page, others = {}) =>
    this.get({
      params: {
        ...filters,
        ...others,
        per_page: this.perPage,
        page: page || this.#pageNumber,
      },
    });

  getRelative = async (relativePath, params = {}) => (await this.api()).get(`${this.resource}/${relativePath}`, params);

  getByUuid = async (uuid, params = {}) => (await this.api()).get(`${this.resource}/${uuid}`, params);

  create = async (data) => (await this.api()).post(this.resource, data);

  update = async (uuid, data) => (await this.api()).patch(`${this.resource}/${uuid}`, data);

  delete = async (uuid) => (await this.api()).delete(`${this.resource}/${uuid}`);
}

export default DistributorService;
