import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import actions from './cardholders.actions';
import service from 'services/distributor_api/cardholders.service';
import cardsService from 'services/distributor_api/cards.service';

const toggleCard = (dispatch, card, toggleFn) => {
  dispatch(actions.toggleCardStart());
  dispatch(fetchStart());

  cardsService[toggleFn](card.cardholder.uuid, card.uuid)
    .then((response) => {
      dispatch(actions.toggleCardSuccess({ ...card, card: response.data }));
      dispatch(fetchSuccess());
    })
    .catch((error) => {
      dispatch(actions.toggleCardError(error.message));
      dispatch(fetchError(error.message));
    });
};

export const activateCard = (dispatch, card) => toggleCard(dispatch, card, 'activate');
export const deactivateCard = (dispatch, card) => toggleCard(dispatch, card, 'deactivate');

export const getOne = (dispatch, uuid) => {
  dispatch(actions.getOneStart());
  dispatch(fetchStart());

  service
    .getByUuid(uuid)
    .then((response) => {
      dispatch(actions.getOneSuccess(response.data));
      dispatch(fetchSuccess());
    })
    .catch((error) => {
      dispatch(actions.getOneError(error.message));
      dispatch(fetchError(error.message));
    });
};
