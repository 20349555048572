import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { ArrowBackRounded } from '@material-ui/icons';
import makeStyles from '@material-ui/styles/makeStyles';

const MCardsArrowBack = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Box className={classes.iconWrapper} onClick={onClick}>
      <ArrowBackRounded fontSize="small" />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #000000',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    height: '30px',
    width: '30px',

    '&:hover': {
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.5)',
    },
  },
}));

MCardsArrowBack.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default MCardsArrowBack;
