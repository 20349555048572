import actionTypes from './access_entities.actionTypes';

const accessEntitiesGetStart = () => ({
  type: actionTypes.ACCESS_ENTITIES_GET_START,
});

const accessEntitiesGetSuccess = (data) => ({
  type: actionTypes.ACCESS_ENTITIES_GET_SUCCESS,
  payload: data,
});

const accessEntitiesGetError = (errorMessage) => ({
  type: actionTypes.ACCESS_ENTITIES_GET_ERROR,
  payload: errorMessage,
});

export default {
  // get
  accessEntitiesGetStart,
  accessEntitiesGetSuccess,
  accessEntitiesGetError,
};
