import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getOne } from 'redux/reducers/distributor_api/cardholders/cardholders.thunks';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import MCardsStatsGrid from 'components/mcards/MCardsStatsGrid';
import { useIntl } from 'react-intl';
import SearchableList from 'components/SearchableList';
import { CardActivitiesRows } from 'components/mcards/Cardholders/CardActivities';
import CardholderCardsList from 'components/mcards/Cardholders/CardholderCardsList';
import DatePeriod from 'components/filters/DatePeriod';
import SearchText from 'components/filters/SearchText';
import TabbedView from 'components/TabbedView';
import CardholderHeading from './CardholderHeading';
import { CardHolderService } from 'services/distributor_api/cardholders.service';
import DropdownFilter from 'components/filters/DropdownFilter';
import IntlMessages from '@jumbo/utils/IntlMessages';

const containerStyle = makeStyles((_theme) => ({
  container: {
    padding: '3.625rem',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
  },
}));

const option = (type, value = type) => {
  return { value: value, label: getActivityTypeLabel(type) };
};
const getActivityTypeLabel = (type) => <IntlMessages id={`pages.manage.cardholders.cardactivity.type.${type}`} />;

const ActivityList = ({ cardholder_uuid }) => {
  const intl = useIntl();
  const service = CardHolderService(cardholder_uuid);

  return (
    <SearchableList
      getAll={service.getAll}
      selector={(state) => state.cardholders}
      ListComponent={CardActivitiesRows}
      filters={[
        <SearchText
          placeholder={intl.formatMessage({ id: 'pages.manage.cardholders.details.search.placeholder' })}
          searchBarXs={5}
        />,
        <DatePeriod />,
        <DropdownFilter
          type="activity-type"
          placeholder="Activity Type"
          filterBy="filter_type"
          options={[
            option('all', ''),
            option('cancelled', 'Transactions[Cancelled]'),
            option('declined', 'Transactions[Declined]'),
            option('transactions', 'Transactions[All]'),
            option('gifts', 'Gifts[All]'),
            option('points', 'Points[All]'),
          ]}
        />,
      ]}
    />
  );
};

const Cardholder = ({ cardholder }) => {
  const classes = containerStyle();

  return cardholder ? (
    <Paper className={classes.container}>
      <CardholderHeading cardholder={cardholder} />
      <MCardsStatsGrid statistics={cardholder.statistics} />
      <Box className={classes.outerContainer}>
        <TabbedView
          tabs={[
            {
              value: '1',
              label: 'Cards',
              tabPanel: <CardholderCardsList currentList={cardholder.cards} />,
            },
            {
              value: '2',
              label: 'Card Activities',
              tabPanel: <ActivityList cardholder_uuid={cardholder.uuid} />,
            },
          ]}
        />
      </Box>
    </Paper>
  ) : null;
};

const CardholderDetailsPage = () => {
  const { cardholder_uuid } = useParams();

  const cardholder = useSelector((state) => state.cardholders.cardholder);

  const dispatch = useDispatch();

  useEffect(() => {
    if (cardholder == null || cardholder.uuid !== cardholder_uuid) {
      getOne(dispatch, cardholder_uuid);
    }
  }, [cardholder, dispatch, cardholder_uuid]);

  return !cardholder ? <PageLoader /> : <Cardholder cardholder={cardholder} />;
};

export default CardholderDetailsPage;
