import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import PWModal from 'components/PWModal';
import MCardsModalTitleIcon from 'components/mcards/MCardsModalTitleIcon';
import MCardsButton from 'components/mcards/MCardsForm/MCardsButton';
import IntlMessages from '@jumbo/utils/IntlMessages';

export const DetailsTitle = ({ id }) => (
  <Typography component="h6" variant="h6" gutterBottom style={{ color: '#333' }}>
    <IntlMessages id={`pages.manage.terminals.modals.${id}`} />
  </Typography>
);

export const DetailsText = ({ children }) => (
  <Typography component="p" variant="body2" gutterBottom style={{ color: '#999' }}>
    {children}
  </Typography>
);

const TerminalsModal = ({ isOpen, onClose, type, titleIcon, buttons, errorMessage, children }) => {
  const classes = useStyles();

  return (
    <PWModal size="xs" modalOpen={isOpen} onClose={onClose}>
      <div className={classes.modalContainer}>
        <MCardsModalTitleIcon src={titleIcon} />

        <Typography align="center" variant="h1" className={classes.title}>
          <IntlMessages id={`pages.manage.terminals.modals.${type}.title`} />
        </Typography>

        <Typography variant="subtitle1" color="textSecondary" align="center">
          <IntlMessages id={`pages.manage.terminals.modals.${type}.subtitle`} />
        </Typography>

        {!!errorMessage && (
          <Box className={classes.error}>
            <Typography component="h5" variant="h5" gutterBottom align="center">
              {errorMessage}
            </Typography>
          </Box>
        )}

        <Box className={classes.details}>{children}</Box>

        <Grid container spacing={4} className={classes.actionBtns} justifyContent="center">
          {buttons?.map((button) => (
            <Grid key={button.id} item xs={6}>
              <MCardsButton
                type={button.type}
                color={button.color}
                disabled={button.isDisabled}
                onClick={button.onClick}
                maxWidth>
                <IntlMessages id={`pages.manage.terminals.modals.buttons.${button.id}`} />
              </MCardsButton>
            </Grid>
          ))}
        </Grid>
      </div>
    </PWModal>
  );
};

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    padding: '1rem',
  },
  title: {
    margin: '2rem 0',
    fontSize: '28px',
  },
  details: {
    border: '1px solid #DADADA',
    borderRadius: '10px',
    margin: theme.spacing(5, 0, 4),
    padding: theme.spacing(4),
    width: '100%',
  },
  error: {
    backgroundColor: 'rgba(235, 8, 8, .05)',
    border: `1px solid ${theme.status.error}`,
    borderRadius: '10px',
    color: theme.status.error,
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
    width: '100%',
    '& h5': {
      fontWeight: theme.typography.fontWeightMedium,
      wordBreak: 'break-word',
    },
  },
  actionBtns: {
    marginTop: theme.spacing(6),
  },
}));

TerminalsModal.defaultProps = {
  isOpen: false,
  onClose: null,
  titleIcon: '/images/icons/check.png',
};

TerminalsModal.propTypes = {
  type: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  titleIcon: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      color: PropTypes.oneOf(['default', 'primary', 'secondary', 'inherit']).isRequired,
      onClick: PropTypes.func,
      isDisabled: PropTypes.bool,
    }),
  ).isRequired,
  errorMessage: PropTypes.string,
};

export default TerminalsModal;
