import DistributorService from '.';

class CardsService extends DistributorService {
  constructor() {
    super('cards');
  }

  activate = async (cardholderUUID, cardUUID) =>
    (await this.api()).put(`cardholders/${cardholderUUID}/cards/${cardUUID}/enable`);

  deactivate = async (cardholderUUID, cardUUID) =>
    (await this.api()).put(`cardholders/${cardholderUUID}/cards/${cardUUID}/disable`);
}

export default new CardsService();
