import React from 'react';
import { Box, Grid } from '@material-ui/core';
import TerminalsModal, { DetailsText, DetailsTitle } from './index';

const ValidateSuccessModal = ({ location, isOpen, onClose, terminal }) => {
  const type = 'validate_success';
  const buttons = [{ id: 'done', type: 'button', color: 'primary', onClick: onClose, isDisabled: false }];

  return (
    <TerminalsModal type={type} isOpen={isOpen} onClose={onClose} location={location} terminal={terminal} buttons={buttons}>
      <>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <DetailsTitle id={`${type}.field_terminal_id`} />
            <DetailsText>{terminal?.card_acceptor_id}</DetailsText>
          </Grid>

          <Grid item xs={6}>
            <DetailsTitle id={`${type}.field_merchant_trade_name`} />
            <DetailsText>{terminal?.merchant_trade_name}</DetailsText>
          </Grid>
        </Grid>

        <Box mt={4}>
          <DetailsTitle id={`${type}.field_google_place_id`} />
          <DetailsText>{location?.address?.google_place_id}</DetailsText>
        </Box>
      </>
    </TerminalsModal>
  );
};

export default ValidateSuccessModal;
