import { Box, IconButton, Typography } from '@material-ui/core';
import LocationSummary from './LocationSummary';
import { HighlightOff } from '@material-ui/icons';
import React from 'react';

const SelectedLocations = ({ selectedLocations, removeLocationHandler }) => {
  return (
    selectedLocations.length > 0 && (
      <Box marginTop="2rem" textAlign="left" style={{ maxHeight: '200px', overflowY: 'auto' }}>
        <Typography variant="h2">Selected Locations</Typography>
        {selectedLocations.map((location, index) => (
          <Box key={index} display="flex" alignItems="center" margin="1rem 2px 0px 2px">
            <LocationSummary
              fullAddress={location.formatted_address}
              name={location.name}
              latitude={location.latitude}
              longitude={location.longitude}
            />
            <IconButton style={{ width: '48px' }} onClick={() => removeLocationHandler(location)}>
              <HighlightOff />
            </IconButton>
          </Box>
        ))}
      </Box>
    )
  );
};

export default SelectedLocations;
