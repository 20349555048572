import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import reducers from '../reducers';
import cardprogramsInitialState from '../reducers/distributor_api/cardprograms/cardprograms.initialState';
import carddesignsInitialState from 'redux/reducers/distributor_api/carddesigns/carddesigns.initialState';
import campaignsInitialState from 'redux/reducers/distributor_api/campaigns/campaigns.initialState';
import accessEntitiesInitialState from 'redux/reducers/distributor_api/access_entities/access_entities.initialState';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

function configureStore(initialState = {}) {
  initialState = {
    ...initialState,
    cardprograms: cardprogramsInitialState,
    carddesigns: carddesignsInitialState,
    campaigns: campaignsInitialState,
    accessEntities: accessEntitiesInitialState,
  };

  const store = createStore(reducers(history), initialState, bindMiddleware([routeMiddleware, thunk]));
  return store;
}
export default configureStore;
export { history };
