import React, { useState } from 'react';
import PlaceRemovalConfirmation from './PlaceRemovalConfirmation';
import SelectedLocations from './SelectedLocations';
import GoogleMapLocationSelector from 'components/mcards/GoogleMapLocationSelector';

const LocationList = ({ list, onAdd, onRemove, shouldConfirmOnRemoval }) => {
  const [locationToRemove, setLocationToRemove] = useState(null);
  const [locationRemovalModalVisible, setLocationRemovalModalVisible] = useState(false);

  const placeMightHaveHadTransactions = (place) => shouldConfirmOnRemoval() && !!place.place_id;

  const isLocationAdded = (place) => list.findIndex((obj) => obj.place_id === place.place_id) !== -1;

  const addRestrictedLocation = (location) => {
    const newLocation = {
      ...location,
      latitude: location.geometry.location.lat(),
      longitude: location.geometry.location.lng(),
    };
    onAdd(newLocation);
  };

  const removeLocationOrPromptConfirmation = (place) => {
    if (placeMightHaveHadTransactions(place)) {
      setLocationToRemove(place);
      setLocationRemovalModalVisible(true);
    } else {
      onRemove(place);
    }
  };

  const handlePlaceRemoval = (removalConfirmed) => {
    if (locationToRemove && removalConfirmed) onRemove(locationToRemove);
    setLocationToRemove(null);
    setLocationRemovalModalVisible(false);
  };

  return (
    <>
      <PlaceRemovalConfirmation
        place={locationToRemove}
        onConfirm={handlePlaceRemoval}
        modalOpen={locationRemovalModalVisible}
      />
      <GoogleMapLocationSelector
        removeLocationHandler={removeLocationOrPromptConfirmation}
        locationAddedHandler={addRestrictedLocation}
        isLocationAdded={isLocationAdded}
      />
      <SelectedLocations selectedLocations={list} removeLocationHandler={removeLocationOrPromptConfirmation} />
    </>
  );
};

export default LocationList;
