import React from 'react';
import { Field } from 'react-final-form';
import validatePresence from 'helpers/validators/validatePresence';
import MCardsTextField from 'components/mcards/MCardsForm/MCardsTextField';
import fetchFormError from 'helpers/validators/fetchFormError';

const SimpleTextField = ({ name, label, placeholder, validator = validatePresence }) => {
  return (
    <Field name={name} validate={validator}>
      {({ input, meta }) => (
        <MCardsTextField
          {...input}
          label={label}
          placeholder={placeholder}
          error={!!fetchFormError(meta)}
          helperText={fetchFormError(meta)}
          fullWidth
        />
      )}
    </Field>
  );
};

export default SimpleTextField;
