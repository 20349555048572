import moment from 'moment';

/**
 * Returns a string representation of a date, relating it to the present. Examples:
 * - Just now
 * - Today, 10:00 AM
 * - Yesterday, 10:00 AM
 * - 09/07/22, 10:00 AM
 */
export function formatDateRelative(date) {
  let dateMoment = moment(date);
  let momentFormatted = dateMoment.format('MM/DD/YY, h:mm A');
  if (moment().format('MM/DD/YY, h:mm A') === momentFormatted) {
    momentFormatted = 'Just now';
  } else {
    let momentDateFormatted = dateMoment.format('MM/DD/YY');
    let yesterdayFormatted = moment().subtract(1, 'days').format('MM/DD/YY');
    if (yesterdayFormatted === momentDateFormatted) {
      momentFormatted = 'Yesterday, ' + dateMoment.format('h:mm A');
    } else if (moment().format('MM/DD/YY') === momentDateFormatted) {
      momentFormatted = 'Today, ' + dateMoment.format('h:mm A');
    }
  }
  return momentFormatted;
}
