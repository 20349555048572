import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import MCardsButton from '../../../../MCardsForm/MCardsButton';
import { publishCampaignAsync } from 'redux/reducers/distributor_api/campaigns/campaigns.thunks';
import { useDispatch } from 'react-redux';

const PublishButton = ({ campaign, getCampaignFn, ...rest }) => {
  const dispatch = useDispatch();
  const publish = () => publishCampaignAsync(campaign || getCampaignFn(), dispatch);

  return (
    <MCardsButton onClick={publish} type="button" {...rest}>
      <IntlMessages id="pages.campaignsPage.create.publish" />
    </MCardsButton>
  );
};

export default PublishButton;
