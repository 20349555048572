import PWModal from '../../../../../PWModal';
import CmtImage from '../../../../../../@coremat/CmtImage';
import { Box, Typography } from '@material-ui/core';
import MCardsButton from '../../../../MCardsForm/MCardsButton';
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const PlaceRemovalConfirmation = ({ onConfirm, place, modalOpen }) => {
  const classes = useStyles();
  const placeToRemoveMessage = place ? `${place.name} at ${place.formatted_address}` : 'all locations';
  const handleChoice = (remove) => onConfirm(remove);

  return (
    <PWModal size="xs" modalOpen={modalOpen} onClose={() => handleChoice(false)}>
      <Box className={classes.placeRemovalModalContainer}>
        <Box className={classes.warningIconContainer}>
          <CmtImage src="/images/icons/warning.png" />
        </Box>

        <Typography align="center" variant="h1" className={classes.modalTitle}>
          Remove Location?
        </Typography>

        <Typography align="center" variant="subtitle1" color="textSecondary">
          You are about to remove a location which has already been promoted to your users.
        </Typography>

        <Typography align="center" variant="h4" className={classes.placeConfirmationMessage}>
          Are you sure you want to remove {placeToRemoveMessage}?
        </Typography>

        <Box className={classes.actionButtonsContainer}>
          <MCardsButton type="button" color={'default'} onClick={() => handleChoice(true)}>
            Remove
          </MCardsButton>

          <MCardsButton type="button" color={'primary'} onClick={() => handleChoice(false)} style={{ marginLeft: '1rem' }}>
            Cancel
          </MCardsButton>
        </Box>
      </Box>
    </PWModal>
  );
};

const useStyles = makeStyles(() => ({
  placeConfirmationMessage: {
    marginTop: '1rem',
  },
  placeRemovalModalContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    padding: '1rem',
  },
  modalTitle: {
    margin: '2rem 0',
  },
  warningIconContainer: {
    marginTop: '1rem',
    borderRadius: '50%',
    border: '1px solid #F0F0F0',
    padding: '6px',
  },
  actionButtonsContainer: {
    marginTop: '3rem',
  },
}));

export default PlaceRemovalConfirmation;
