import { Box } from '@material-ui/core';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React, { useCallback, useEffect, useState } from 'react';
import SearchBoxField from './SearchBoxField';
import SearchResultsList from './SearchResultsList';
import makeStyles from '@material-ui/core/styles/makeStyles';

const gaLibraries = ['places'];
const GoogleMapLocationSelector = ({
  removeLocationHandler = () => {},
  locationAddedHandler,
  onResultsChange,
  isLocationAdded,
  multipleSelectionMode = true,
  initialSearchText,
}) => {
  const [places, setPlaces] = useState([]);
  const [markers, setMarkers] = useState({});
  const [map, setMap] = useState(null);
  const [loadedInitialPlaces, setLoadedInitialPlaces] = useState(false);
  const onLoad = useCallback((map) => setMap(map), []);
  const onUnload = useCallback(() => setMap(null), []);

  const cleanMarkers = () => {
    if (markers) {
      Object.keys(markers).forEach((place_id) => {
        markers[place_id].setMap(null);
      });

      setMarkers(null);
    }
  };

  const onPlacesChanged = (places) => {
    setPlaces(places);
    if (typeof onResultsChange === 'function') onResultsChange(places);

    cleanMarkers();

    if (places?.length) {
      const bounds = new window.google.maps.LatLngBounds();
      let markers = {};

      places.forEach((place, index) => {
        const marker = new window.google.maps.Marker({
          position: place.geometry.location,
          map: map,
          zIndex: index,
        });

        markers[place.place_id] = marker;
        bounds.extend(marker.position);
      });

      setMarkers(markers);
      map.fitBounds(bounds);
    }
  };

  const onSearchResultMouseOver = (place) => {
    Object.keys(markers).forEach((place_id, index) => {
      const marker = markers[place_id];

      if (place_id === place.place_id) {
        marker.setZIndex(99);
        marker.setAnimation(window.google.maps.Animation.BOUNCE);
      } else {
        marker.setZIndex(index);
        marker.setAnimation(null);
      }
    });

    document.getElementById(`place-${place.place_id}`).style.backgroundColor = '#EEE';
  };
  const containerStyle = {
    width: 'calc(50% - 0.5rem)',
    height: '300px',
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: gaLibraries,
  });
  const classes = useStyles();

  /*
   * Loading results based on initialSearchText, since the SearchBoxField won't
   * trigger automatically a search based on the initial value.
   */
  useEffect(() => {
    if (isLoaded && map && initialSearchText && !loadedInitialPlaces) {
      setLoadedInitialPlaces(true);
      new window.google.maps.places.PlacesService(map).findPlaceFromQuery(
        { query: initialSearchText, fields: ['name', 'geometry', 'place_id', 'formatted_address'] },
        (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
            onPlacesChanged(results);
          }
        },
      );
    }
  }, [map, isLoaded, initialSearchText, loadedInitialPlaces, onPlacesChanged]);

  return (
    isLoaded && (
      <Box>
        <SearchBoxField onPlacesChanged={onPlacesChanged} initialSearchText={initialSearchText} />
        <Box className={classes.mapAndResultsContainer} style={{ display: places.length > 0 ? 'flex' : 'none' }}>
          <GoogleMap mapContainerStyle={containerStyle} zoom={14} onLoad={onLoad} onUnmount={onUnload} />
          <SearchResultsList
            containerStyle={containerStyle}
            onResultMouseOver={onSearchResultMouseOver}
            places={places}
            isLocationAdded={isLocationAdded}
            locationAddedHandler={locationAddedHandler}
            multipleSelectionMode={multipleSelectionMode}
            removeLocationHandler={removeLocationHandler}
          />
        </Box>
      </Box>
    )
  );
};

const useStyles = makeStyles((theme) => ({
  mapAndResultsContainer: {
    justifyContent: 'space-between',
    boxShadow: '0px 0px 4px rgb(0 0 0 / 17%)',
    borderRadius: '10px',
    overflow: 'hidden',
    margin: '2rem 2px 0px 2px',
  },
}));

export default GoogleMapLocationSelector;

export const sameGooglePlace = (placeA, placeB) => placeA?.place_id === placeB?.place_id;

export const GoogleMapSingleLocationSelector = ({ onLocationSelect, setLocationsFound, initialSearchText }) => {
  const [chosenLocation, setChosenLocation] = useState(null);

  const clearChosenLocationIfLocationListDoesNotContainIt = (locations) => {
    if (!locations.find((location) => sameGooglePlace(location, chosenLocation))) {
      setChosenLocation(null);
    }
  };

  return (
    <GoogleMapLocationSelector
      locationAddedHandler={(selectedPlace) => {
        setChosenLocation(selectedPlace);
        if (onLocationSelect) onLocationSelect(selectedPlace);
      }}
      isLocationAdded={(place) => sameGooglePlace(place, chosenLocation)}
      onResultsChange={(places) => {
        clearChosenLocationIfLocationListDoesNotContainIt(places);
        setLocationsFound(places);
      }}
      multipleSelectionMode={false}
      initialSearchText={initialSearchText}
    />
  );
};
