export default {
  // getAll
  CARDPROGRAMS_LOAD_START: 'CARDPROGRAMS_LOAD_START',
  CARDPROGRAMS_LOAD_SUCCESS: 'CARDPROGRAMS_LOAD_SUCCESS',
  CARDPROGRAMS_LOAD_ERROR: 'CARDPROGRAMS_LOAD_ERROR',
  // get(uuid)
  CARDPROGRAM_LOAD_START: 'CARDPROGRAM_LOAD_START',
  CARDPROGRAM_LOAD_SUCCESS: 'CARDPROGRAM_LOAD_SUCCESS',
  CARDPROGRAM_LOAD_ERROR: 'CARDPROGRAM_LOAD_ERROR',
};
