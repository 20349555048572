import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import { getOneCampaignAsync, updateCampaignAsync } from 'redux/reducers/distributor_api/campaigns/campaigns.thunks';
import { loadCardprogramsAsync } from 'redux/reducers/distributor_api/cardprograms/cardprograms.thunks';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import CampaignHeading from 'components/mcards/Campaigns/CampaignHeading';
import { Box } from '@material-ui/core';
import CreateCampaignForm from 'components/mcards/Campaigns/CreateCampaignForm';
import EditAlertModal from 'components/mcards/Campaigns/EditAlertModal';

const EditCampaign = () => {
  const { campaign_uuid } = useParams();

  const { isLoading, currentCampaign } = useSelector((state) => state.campaigns);

  const { cardprograms } = useSelector((state) => state.cardprograms);

  const dispatch = useDispatch();

  const [campaignIsUnpublished, setCampaignIsUnpublished] = useState(false);

  const [campaignStatusChecked, setCampaignStatusChecked] = useState(false);

  const [editAlertModalOpen, setEditAlertModalOpen] = useState(true);

  const markCampaignAsUnpublished = () => {
    const campaign = {
      uuid: currentCampaign.uuid,
      status: 'unpublished',
    };

    updateCampaignAsync(campaign, dispatch).then(() => setCampaignIsUnpublished(true));
  };

  const fetchCampaign = () => getOneCampaignAsync(dispatch, campaign_uuid);

  const checkCampaignStatus = () => {
    currentCampaign.status !== 'published' ? setCampaignIsUnpublished(true) : markCampaignAsUnpublished();
    setCampaignStatusChecked(true);
  };

  useEffect(() => {
    if (!campaignStatusChecked) {
      if (!currentCampaign || currentCampaign.uuid !== campaign_uuid) {
        fetchCampaign();
      } else {
        checkCampaignStatus();
      }
    }
  }, [currentCampaign]);

  useEffect(() => {
    loadCardprogramsAsync(dispatch);
  }, [dispatch]);

  return isLoading && !campaignIsUnpublished ? (
    <PageLoader />
  ) : (
    currentCampaign && campaignIsUnpublished && cardprograms && (
      <PageContainer heading={<CampaignHeading currentCampaign={currentCampaign} />}>
        <Box>
          <EditAlertModal
            isOpen={editAlertModalOpen}
            confirmButtonHandler={() => setEditAlertModalOpen(false)}
            campaignImageURL={currentCampaign?.image_url}
          />
          <CreateCampaignForm isEdit={true} />
        </Box>
      </PageContainer>
    )
  );
};

export default EditCampaign;
