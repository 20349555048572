import { Typography } from '@material-ui/core';
import React from 'react';
import IntlMessages from '../@jumbo/utils/IntlMessages';

const CountHeading = ({ title = <IntlMessages id="countHeading.title" />, count }) => (
  <Typography component="h2" variant="h2">
    {title}
    {count ? ` (${count})` : ''}
  </Typography>
);

export default CountHeading;
