export default {
  isLoading: false,
  errorMessage: null,
  location: null,
  newLocation: null,
  createError: null,
  addedTerminal: null,
  addTerminalError: null,
  currentLocationUuid: null,
  stats: null,
  isLoadingStats: false,
};
