import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import CmtImage from '@coremat/CmtImage';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Avatar, Box, Typography } from '@material-ui/core';

/**
 * DetailSectionHeader:
 *
 * @param {string} imgSrc - A valid path to an asset image
 *
 * @param {string} messageIntlID - A valid Internationalization ID
 *
 *
 * @returns {JSX.Element}
 *
 * USAGE:
 *  - basic:
 *    const imgSrc = "/images/icons/terminal.png"
 *    const messageIntlID = "component.selects.simple"
 *
 *    <DetailSectionHeader imgSrc={imgSrc} messageIntlID={messageIntlID} />
 */

const DetailSectionHeader = ({ imgSrc, messageIntlID }) => {
  const classes = useStyles();

  return (
    <Box className={classes.headerContainer}>
      <Avatar aria-label="icon" className={classes.avatar}>
        <CmtImage src={imgSrc} />
      </Avatar>

      <Typography variant="h2" component="h2">
        <IntlMessages id={messageIntlID} />
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    alignItems: 'baseline',
    display: 'flex',
    columnGap: '1rem',
  },
  avatar: {
    background: 'rgba(8, 38, 235, 0.04)',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
  },
}));

export default DetailSectionHeader;
