import { Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const StyledPagination = ({ page, per_page, total_count, setChosenPage }) => {
  const classes = useStyles();
  const totalPages = Math.ceil(total_count / per_page);

  if (totalPages <= 1) return null;

  return (
    <Box my={8} display="flex" justifyContent="center">
      <Pagination
        page={page}
        count={totalPages}
        color="primary"
        shape="rounded"
        onChange={(_event, value) => setChosenPage(value)}
        classes={{
          ul: classes.paginationUl,
        }}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  paginationUl: {
    '& li .MuiPaginationItem-root': {
      borderRadius: '6px',
      fontSize: '1rem',
      fontWeight: theme.typography.fontWeightBold,
      margin: theme.spacing(0, 1.25),
    },
    '& li:not(:first-child):not(:last-child)': {
      '& .MuiPaginationItem-root': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.15)',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        '&.Mui-selected': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
        '&.MuiPaginationItem-ellipsis': {
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: 'inherit',
          },
        },
      },
    },
  },
}));

export default StyledPagination;
