import actionTypes from './recipients.actionTypes';

const recipientUpdateStart = () => ({
  type: actionTypes.UPDATE_START,
});

const recipientUpdateSuccess = (data) => ({
  type: actionTypes.UPDATE_SUCCESS,
  payload: data,
});

const recipientUpdateError = (errorMessage) => ({
  type: actionTypes.UPDATE_ERROR,
  payload: errorMessage,
});

const recipientDeleteSuccess = (data) => ({
  type: actionTypes.DELETE_SUCCESS,
  payload: data,
});

const recipientDeleteError = (errorMessage) => ({
  type: actionTypes.DELETE_ERROR,
  payload: errorMessage,
});

export default {
  // update
  recipientUpdateStart,
  recipientUpdateSuccess,
  recipientUpdateError,

  // delete
  recipientDeleteSuccess,
  recipientDeleteError,
};
