import React from 'react';
import PropTypes from 'prop-types';
import { Box, InputAdornment, SvgIcon } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardDateTimePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { CalendarTodayRounded, ChevronRightRounded, Schedule } from '@material-ui/icons';
import TextInputHelperText from './MCardsForm/MCardsTextField/TextInputHelperText';

class DateFormat {
  constructor(displayFormat, outputFormat = displayFormat) {
    this.displayFormat = displayFormat;
    this.outputFormat = outputFormat;
  }

  formatForOutput(date) {
    return !!date ? date.format(this.outputFormat) : null;
  }
}

const dateFormats = {
  date: new DateFormat('DD/MM/yyyy', 'YYYY-MM-DD'),
  time: new DateFormat('HH:mm'),
  datetime: new DateFormat('DD/MM/yyyy HH:mm', 'YYYY-MM-DDTHH:mm:ss.SSSZ'),
};

export const formatToDisplay = (type) => {
  return dateFormats[type];
};

/**
 * MCardsDateTimePicker - wraps MUI's date and time picker with custom MCards styling.
 * Returns formatted date considering the type informed.
 * @param name
 * @param type - date, time or datetime.
 * @param formatOutput - whether the output should be formatted or not.
 * @param label
 * @param placeholder
 * @param value
 * @param onChange receives the date as a moment object or backend-friendly formatted if formatOutput param is set.
 * @param inUtc
 * @param autoOk
 * @param ampm
 * @param views - array of views to be displayed in the picker. Possible values: 'year', 'month', 'date', 'hours', 'minutes'.
 * @param minDate Minimum selectable date
 * @param maxDate Maximum selectable date
 * @param disablePast Disable past dates
 * @param disableFuture Disable future dates
 * @param error
 * @param helperText
 * @returns {JSX.Element}
 * @constructor
 */
const MCardsDateTimePicker = ({
  name,
  type,
  formatOutput = false,
  label,
  placeholder,
  value,
  onChange,
  inUtc,
  autoOk,
  ampm,
  views,
  minDate,
  maxDate,
  disablePast,
  disableFuture,
  error,
  helperText,
}) => {
  const classes = useStyles();

  const selectComponent = {
    date: KeyboardDatePicker,
    time: KeyboardTimePicker,
    datetime: KeyboardDateTimePicker,
  };
  const DateComponent = selectComponent[type];

  let dateFormat = formatToDisplay(type);

  const displayingHoursOnly = () => type === 'time' && views.length === 1 && views[0] === 'hours';

  const onChangeDateTime = (date) => {
    date = inUtc ? date?.utc() : date;
    if (date != null && date._isValid) {
      if (displayingHoursOnly()) {
        date = date?.startOf('hour');
      }

      if (formatOutput) {
        onChange(dateFormat.formatForOutput(date));
      } else {
        onChange(date);
      }
    } else {
      onChange(null);
    }
  };

  return (
    <Box>
      <DateComponent
        name={name}
        variant="inline"
        inputVariant="outlined"
        label={label}
        placeholder={placeholder}
        format={dateFormat?.displayFormat}
        value={!!value ? value : null}
        className={classes.datePickerRoot}
        InputAdornmentProps={{ position: 'start' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SvgIcon>
                <ChevronRightRounded />
              </SvgIcon>
            </InputAdornment>
          ),
        }}
        keyboardIcon={
          <SvgIcon fontSize="small">
            {(type === 'date' || type === 'datetime') && <CalendarTodayRounded />}
            {type === 'time' && <Schedule />}
          </SvgIcon>
        }
        onChange={onChangeDateTime}
        autoOk={autoOk}
        ampm={ampm}
        views={views}
        minDate={minDate}
        maxDate={maxDate}
        disablePast={disablePast}
        disableFuture={disableFuture}
        error={error}
        helperText={<TextInputHelperText text={getHelperText(helperText, type)} error={error} />}
      />
    </Box>
  );
};

const getHelperText = (text, type) => {
  return text?.toLowerCase() === 'invalid' ? `Invalid ${type} format` : text;
};

const useStyles = makeStyles((theme) => ({
  datePickerRoot: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#EBEBEB',
        transition: 'all ease-in-out 0.25s',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
        transition: 'all ease-in-out 0.25s',
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
      borderRadius: '5.76365px',
      padding: theme.spacing(0, 2),
      '& .MuiInputAdornment-positionStart .MuiButtonBase-root': {
        color: theme.palette.primary.main,
        padding: 0,
      },
      '& .MuiOutlinedInput-input': {
        fontSize: '.875rem',
        padding: '.875rem 0',
      },
      '& .MuiInputAdornment-positionEnd': {
        color: '#999',
        padding: 0,
      },
      '&.Mui-error': {
        '&:hover fieldset': {
          borderColor: theme.status.error,
        },
        '&.Mui-focused fieldset': {
          borderColor: theme.status.error,
        },
      },
    },
    '& .MuiFormHelperText-root': {
      alignItems: 'center',
      display: 'flex',
      fontStyle: 'italic',
      marginLeft: 0,
      marginTop: '5px',
      '&.Mui-error': {
        color: theme.status.error,
      },
      '& img': {
        marginRight: '5px',
      },
    },
  },
}));

MCardsDateTimePicker.defaultProps = {
  value: undefined,
  inUtc: false,
  autoOk: true,
  ampm: false,
  views: ['year', 'month', 'date'],
  minDate: undefined,
  maxDate: undefined,
  disablePast: false,
  disableFuture: false,
};

MCardsDateTimePicker.propTypes = {
  type: PropTypes.oneOf(['date', 'time', 'datetime']).isRequired,
  formatOutput: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  inUtc: PropTypes.bool,
  autoOk: PropTypes.bool,
  ampm: PropTypes.bool,
  views: PropTypes.array,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
};

export default MCardsDateTimePicker;
