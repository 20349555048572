import { Grid } from '@material-ui/core';
import MCardsDateTimePicker from '../mcards/MCardsDateTimePicker';
import React, { useState } from 'react';

/*
 * This component is used to filter by date period: from_date and to_date.
 * It has two date pickers: one for the start date and one for the end date.
 * The onChange function is called with either the start or end dates as parameters.
 */
const DatePeriod = ({ onChange }) => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const onChangeFromDate = (date) => {
    setFromDate(date);
    onChange({ from_date: date });
  };
  const onChangeToDate = (date) => {
    setToDate(date);
    onChange({ to_date: date });
  };

  return (
    <>
      <Grid item xs>
        <MCardsDateTimePicker
          type="date"
          name="search-from-date"
          placeholder="From Date"
          formatOutput
          value={fromDate}
          onChange={onChangeFromDate}
        />
      </Grid>
      <Grid item xs>
        <MCardsDateTimePicker
          type="date"
          name="search-to-date"
          placeholder="To Date"
          formatOutput
          value={toDate}
          onChange={onChangeToDate}
        />
      </Grid>
    </>
  );
};

export default DatePeriod;
