import React, { useState } from 'react';
import PWModal from '../../../PWModal';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Form } from 'react-final-form';
import MCardsButton from '../../MCardsForm/MCardsButton';
import CountryCodeField from '../../MCardsForm/fields/CountryCodeField';
import PhoneField from '../../MCardsForm/fields/PhoneField';
import service from '../../../../services/distributor_api/recipients.service';
import { useIntl } from 'react-intl';

const i18nPath = 'pages.manage.campaigns.recipients.resend';

const ErrorDetail = ({ labelI18n, text }) => {
  const classes = useStyles();

  return (
    <Box className={classes.detailContainer}>
      <Box component="span">
        <Typography className={classes.detailLabel}>
          <IntlMessages id={`${i18nPath}.error_details.${labelI18n}`} />
        </Typography>
      </Box>
      <Box component="span" className={classes.detailTextContainer}>
        <Typography className={classes.detailText}>{text}</Typography>
      </Box>
    </Box>
  );
};

const NewPhoneForm = ({ onSubmit, responseErrorMessage, resending }) => {
  const classes = useStyles();

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box display="flex">
            <Box className={classes.countryCodeFieldContainer}>
              <CountryCodeField name="country_code_number" />
            </Box>
            <Box className={classes.phoneFieldContainer}>
              <PhoneField name="phone_without_country_code" label={null} placeholder="Mobile number" />
            </Box>
          </Box>
          {!!responseErrorMessage && (
            <Box className={classes.submitErrorContainer}>
              <Typography>{responseErrorMessage}</Typography>
            </Box>
          )}
          <Box className={classes.submitButtonContainer}>
            <MCardsButton disabled={resending}>
              <IntlMessages id={`${i18nPath}.submit_button_text`} />
            </MCardsButton>
          </Box>
        </form>
      )}
    />
  );
};

const ResendSMSModal = ({ recipient, isOpen, onClose }) => {
  const classes = useStyles();
  const intl = useIntl();

  const [responseErrorMessage, setResponseErrorMessage] = useState(null);
  const [resending, setResending] = useState(false);

  const resendSMS = ({ country_code_number, phone_without_country_code }) => {
    const RecipientsService = service(recipient.campaign.uuid);
    const retryParams = {
      country_code_number: country_code_number,
      phone: phone_without_country_code,
    };
    setResponseErrorMessage(null);
    setResending(true);

    RecipientsService.retrySMS(recipient.uuid, retryParams)
      .then(() => onClose({ success: true }))
      .catch(({ response }) => {
        let errorMessage;

        if (response.status === 403) {
          errorMessage = intl.formatMessage({ id: 'messages.generic.forbidden' });
        } else if (response.data?.errors[0].detail) {
          errorMessage = response.data?.errors[0].detail;
        } else {
          errorMessage = intl.formatMessage({ id: 'messages.generic.fallbackError' });
        }

        setResponseErrorMessage(errorMessage);
      })
      .finally(() => setResending(false));
  };

  return (
    <PWModal size="xs" modalOpen={isOpen} onClose={onClose}>
      <Box className={classes.modalContainer}>
        <Box alignContent="center" className={classes.iconContainer}>
          <CmtImage src="/images/icons/send-aeroplane-icon-white.png" />
        </Box>
        <Typography variant="h1" align="center">
          <IntlMessages id={`${i18nPath}.title`} />
        </Typography>
        <Typography align="center">
          <IntlMessages id={`${i18nPath}.subtitle`} />
        </Typography>
        <Box className={classes.detailsContainer}>
          <ErrorDetail labelI18n="attempted_number_label" text={recipient.phone} />
          <ErrorDetail labelI18n="sms_label" text={recipient.message} />
          <ErrorDetail labelI18n="error_message_label" text={recipient.processing_errors} />
        </Box>
        <Typography align="center">
          <IntlMessages id={`${i18nPath}.instructions`} />
        </Typography>
        <NewPhoneForm onSubmit={resendSMS} resending={resending} responseErrorMessage={responseErrorMessage} />
      </Box>
    </PWModal>
  );
};

const useStyles = makeStyles(() => ({
  modalContainer: {
    '& > *': {
      marginTop: '1rem',
    },
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem',
  },
  submitErrorContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '.5rem',
    color: 'red',
    wordBreak: 'break-word',
  },
  detailsContainer: {
    border: '1px solid #DADADA',
    borderRadius: '2%',
    padding: '1.5rem 1.5rem 0',
  },
  detailContainer: {
    display: 'flex',
    marginBottom: '1rem',
    gap: '1rem',
  },
  'detailContainer:last-child': {
    marginBottom: '0rem',
  },
  detailTextContainer: {
    marginLeft: 'auto',
    maxWidth: '80%',
  },
  detailText: {
    textAlign: 'right',
  },
  detailLabel: {
    fontWeight: 600,
  },
  countryCodeFieldContainer: {
    flex: 1,
    minWidth: 'unset',
  },
  phoneFieldContainer: {
    flex: 2,
    minWidth: 'unset',
    marginLeft: '1rem',
  },
}));

export default ResendSMSModal;
