import React, { useState } from 'react';
import StatusLabel from '../../../../StatusLabel';
import IntlMessages from '@jumbo/utils/IntlMessages';
import EditRecipientModal from '../EditRecipientModal';
import ResendSMSModal from '../ResendSMSModal';
import PropTypes from 'prop-types';

export const getStatusLabel = (status) => <IntlMessages id={`pages.manage.campaigns.recipients.status.${status}`} />;

const Status = ({ campaign, item, onRecipientUpdated = null }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const openEditModal = () => setEditModalOpen(true);

  const [resendModalOpened, setResendModalOpened] = useState(false);
  const openResendModal = () => setResendModalOpened(true);

  const status = item.status;
  const labels = {
    activated: { text: getStatusLabel('activated'), state: 'success' },
    not_activated: { text: getStatusLabel('not_activated'), state: 'warning' },
    cancelled: { text: getStatusLabel('cancelled'), state: 'danger' },
    failed: { text: getStatusLabel('failed'), state: 'error', onClick: openResendModal },
    scheduled: { text: getStatusLabel('scheduled'), state: 'pending', onClick: openEditModal },
  };

  const onResendModalClosed = ({ success }) => {
    if (success && !!onRecipientUpdated) {
      onRecipientUpdated();
    }

    setResendModalOpened(false);
  };

  return (
    <>
      <StatusLabel state={labels[status].state} text={labels[status].text} onClick={labels[status].onClick || null} />

      {status === 'scheduled' && editModalOpen && (
        <EditRecipientModal
          recipient={item}
          campaign={campaign}
          isOpen={editModalOpen}
          closeModal={() => setEditModalOpen(false)}
          onRecipientUpdated={onRecipientUpdated}
        />
      )}

      {status === 'failed' && resendModalOpened && (
        <ResendSMSModal isOpen={resendModalOpened} onClose={onResendModalClosed} recipient={item} />
      )}
    </>
  );
};

Status.propTypes = {
  campaign: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  onRecipientUpdated: PropTypes.func,
};

export default Status;
