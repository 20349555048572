import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import CardProgramsService from 'services/distributor_api/cardprograms.service';
import actions from './cardprograms.actions';
import { AuthMethods } from 'services/auth';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';

export const loadCardprogramsAsync = (dispatch) => {
  dispatch(actions.cardprogramsLoadStart());
  dispatch(fetchStart());

  CardProgramsService.get()
    .then((response) => {
      dispatch(actions.cardprogramsLoadSuccess(response.data.card_programs));
      dispatch(fetchSuccess());
    })
    .catch((error) => {
      dispatch(actions.cardprogramsLoadError(error.message));

      if (error.response?.status === 403) {
        dispatch(AuthMethods[CurrentAuthMethod].onLogout(true));
      } else {
        dispatch(fetchError(error.message));
      }
    });
};

export const loadCardprogramAsync = (uuid, dispatch) => {
  dispatch(actions.cardprogramsLoadStart());
  dispatch(fetchStart());

  CardProgramsService.getByUuid(uuid)
    .then((response) => {
      dispatch(actions.cardprogramLoadSuccess(response.data));
      dispatch(fetchSuccess());
    })
    .catch((error) => {
      dispatch(actions.cardprogramLoadError(error.message));
      dispatch(fetchError(error.message));
    });
};
