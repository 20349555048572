import React from 'react';
import { Box } from '@material-ui/core';
import CmtImage from '@coremat/CmtImage';
import makeStyles from '@material-ui/styles/makeStyles';
import { formatDateRelative } from 'helpers/dates';

const SentAt = ({ sentDate, deliveryDate }) => {
  const classes = useStyles({ isSent: !!sentDate });
  const date = sentDate || deliveryDate;

  if (date) {
    return (
      <Box component="span" className={classes.sentAtBox}>
        {!sentDate && <CmtImage src="/images/icons/clock-schedule.png" className={classes.clockIcon} />}
        {formatDateRelative(date)}
      </Box>
    );
  } else {
    return null;
  }
};

const useStyles = makeStyles((theme) => ({
  sentAtBox: {
    alignItems: 'center',
    color: (props) => (props.isSent ? '' : theme.status.warning),
    display: 'flex',
  },
  clockIcon: {
    marginRight: '5px',
  },
}));

export default SentAt;
