import actionTypes from './cardholders.actionTypes';
import initialState from './cardholders.initialState';

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_ONE_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case actionTypes.GET_ONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cardholder: payload,
      };
    case actionTypes.GET_ONE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.TOGGLE_CARD_START:
      return {
        ...state,
        isUpdatingCard: true,
        errorMessage: null,
      };
    case actionTypes.TOGGLE_CARD_SUCCESS:
      return {
        ...state,
        isUpdatingCard: false,
        cardholder: payload,
        errorMessage: null,
      };
    case actionTypes.TOGGLE_CARD_ERROR:
      return {
        ...state,
        isUpdatingCard: false,
        errorMessage: payload,
      };
    default:
      return state;
  }
};

export default reducer;
