import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PWModal from '../../PWModal';
import { Paper } from '@material-ui/core';
import CmtImage from '../../../@coremat/CmtImage';

const RecipientsModal = ({ isOpen, onClose, children }) => {
  const classes = useStyles();
  return (
    <PWModal size="md" fullWidth={true} modalOpen={isOpen} onClose={onClose}>
      <Paper className={classes.modalContainer}>
        <div className={classes.iconWrapper}>
          <div className={classes.iconCircle}>
            <div className={classes.activeDot} />
            <CmtImage src="/images/icons/active-recipients-icon.png" />
          </div>
        </div>

        {children}
      </Paper>
    </PWModal>
  );
};

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    border: 'none',
    boxShadow: 'none',
    textAlign: 'center',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(6),
  },
  activeDot: {
    background: theme.status.success,
    border: '4px solid #FFFFFF',
    borderRadius: '100%',
    boxShadow: '0px 0px 2.125px rgba(0, 0, 0, 0.13)',
    position: 'absolute',
    right: 0,
    top: 0,
    height: '20px',
    width: '20px',
  },
  iconCircle: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
    borderRadius: '100%',
    padding: '16px',
    position: 'relative',
    height: '70px',
    width: '70px',
  },
}));

export default RecipientsModal;
