import React from 'react';
import validatePhone from 'helpers/validators/validatePhone';
import { Field } from 'react-final-form';
import MCardsTextField from 'components/mcards/MCardsForm/MCardsTextField';
import fetchFormError from 'helpers/validators/fetchFormError';

const PhoneField = ({ name, label = 'Mobile Number', placeholder }) => {
  return (
    <Field name={name} type="tel" validate={validatePhone}>
      {({ input, meta }) => (
        <MCardsTextField
          {...input}
          label={label}
          placeholder={placeholder}
          error={!!fetchFormError(meta)}
          helperText={fetchFormError(meta)}
          fullWidth
        />
      )}
    </Field>
  );
};

export default PhoneField;
