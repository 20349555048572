import actionTypes from './recipients.actionTypes';
import initialState from './recipients.initialState';

const recipientsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.UPDATE_START:
      return {
        ...state,
        errorMessage: null,
      };
    case actionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        recipient: payload,
      };
    case actionTypes.UPDATE_ERROR:
      return {
        ...state,
        errorMessage: payload,
      };
    case actionTypes.DELETE_SUCCESS:
      return {
        ...state,
        recipient: payload,
      };
    case actionTypes.DELETE_ERROR:
      return {
        ...state,
        errorMessage: payload,
      };
    default:
      return state;
  }
};

export default recipientsReducer;
