import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import MCardsButton from 'components/mcards/MCardsForm/MCardsButton';
import LocationsStats from 'components/mcards/Locations/LocationsStats';
import { useHistory } from 'react-router-dom';
import LocationsList from 'components/mcards/Locations/LocationsList';

const LocationsListPage = () => {
  const history = useHistory();
  return (
    <PageContainer
      heading={<IntlMessages id="pages.manage.locations.title" />}
      subtitle={<IntlMessages id="pages.manage.locations.subtitle" />}
      ctaButtons={[
        <MCardsButton onClick={() => history.push('/locations/new')}>
          <IntlMessages id="pages.manage.locations.new.buttonText" />
        </MCardsButton>,
      ]}>
      <LocationsStats />
      <LocationsList />
    </PageContainer>
  );
};

export default LocationsListPage;
