const switchKeyToResults = (onFulfilled, resultsKey) => (response) =>
  onFulfilled({ data: { ...response.data, results: response.data[resultsKey] } });

/*
 * Example for resultsKey = 'campaigns':
 * { data: { campaigns: {} } } ---> { data: { results: {} } }
 */
export const adaptResultsPromise = (promise, originalKey = 'results') => ({
  then: (onFulfilled, onRejected) => promise.then(switchKeyToResults(onFulfilled, originalKey), onRejected),
});

export const adaptServiceGetAllResults = (service, originalKey = 'results') => {
  const adaptedGetAll = (...params) => adaptResultsPromise(service.getAll(...params), originalKey);
  return { ...service, getAll: adaptedGetAll };
};
