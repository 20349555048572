import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';
import SearchText from '../../filters/SearchText';
import DatePeriod from '../../filters/DatePeriod';
import SearchableList from 'components/SearchableList';
import MCardsTable from '../MCardsTable';
import DropdownFilter from 'components/filters/DropdownFilter';
import {
  CardholderEmail,
  CardholderName,
  CardholderPhone,
  CardholderJoinedDate,
  CardholderStatus,
  CardholderTotalSpent,
  CardholderTransactions,
} from './CardholderRow';
import service from 'services/distributor_api/cardholders.service';

const CardholderRows = ({ currentList }) => {
  return (
    <MCardsTable
      headers={[
        <IntlMessages id="pages.manage.cardholders.list.table.col.joined" />,
        <IntlMessages id="pages.manage.cardholders.list.table.col.cardholder" />,
        <IntlMessages id="pages.manage.cardholders.list.table.col.status" />,
        <IntlMessages id="pages.manage.cardholders.list.table.col.mobile_number" />,
        <IntlMessages id="pages.manage.cardholders.list.table.col.email" />,
        <IntlMessages id="pages.manage.cardholders.list.table.col.transactions" />,
        <IntlMessages id="pages.manage.cardholders.list.table.col.spend" />,
      ]}
      rows={currentList?.map((cardholder) => [
        <CardholderJoinedDate cardholder={cardholder} />,
        <CardholderName cardholder={cardholder} />,
        <CardholderStatus cardholder={cardholder} />,
        <CardholderPhone cardholder={cardholder} />,
        <CardholderEmail cardholder={cardholder} />,
        <CardholderTransactions cardholder={cardholder} />,
        <CardholderTotalSpent cardholder={cardholder} />,
      ])}
      cellPadding={'1rem 1.5rem'}
    />
  );
};

const CardholdersList = () => {
  const intl = useIntl();
  const getStatusLabel = (status) => <IntlMessages id={`pages.manage.cardholders.status.${status}`} />;

  return (
    <SearchableList
      getAll={service.getAll}
      ListComponent={CardholderRows}
      searchBarXs={6}
      filters={[
        <SearchText
          placeholder={intl.formatMessage({ id: 'pages.manage.cardholders.search.placeholder' })}
          searchBarXs={6}
        />,
        <DatePeriod />,
        <DropdownFilter
          type="status"
          placeholder="Cardholder Status"
          filterBy="status"
          options={[
            { label: getStatusLabel('all') },
            { value: 'active', label: getStatusLabel('active') },
            { value: 'inactive', label: getStatusLabel('inactive') },
            { value: 'removed', label: getStatusLabel('removed') },
          ]}
        />,
      ]}
    />
  );
};

export default CardholdersList;
