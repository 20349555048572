import React from 'react';
import PWModal from '../../PWModal';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import MCardsButton from '../MCardsForm/MCardsButton';
import MCardsModalTitleIcon from '../MCardsModalTitleIcon';

const EditAlertModal = ({ isOpen, confirmButtonHandler, campaignImageURL }) => {
  const classes = useStyles();

  return (
    <PWModal size="xs" modalOpen={isOpen}>
      <div className={classes.modalContainer}>
        <MCardsModalTitleIcon src="/images/icons/pencil.png" />

        <Typography align="center" variant="h1" className={classes.title}>
          ALERT
        </Typography>

        <Typography variant="subtitle1" color="textSecondary">
          Your campaign is paused until you finish editing all steps. While paused, your recipients won't receive new cards,
          but the ones who already own a card will still be able to transact normally.
        </Typography>

        <div className={classes.campaignImgContainer} style={{ backgroundImage: `url('${campaignImageURL}')` }} />

        <MCardsButton type="button" onClick={confirmButtonHandler} style={{ width: '66%', margin: '1rem 0' }}>
          Done
        </MCardsButton>
      </div>
    </PWModal>
  );
};

const useStyles = makeStyles(() => ({
  modalContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    padding: '1rem',
  },
  campaignImgContainer: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '200px',
    width: '300px',
    borderRadius: '3%',
    margin: '3rem 0 2rem',
  },
  title: {
    margin: '2rem 0',
    fontSize: '28px',
  },
}));

export default EditAlertModal;
