import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import MCardsDateTimePicker from '../../../MCardsDateTimePicker';
import { Box, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment/moment';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { useIntl } from 'react-intl';
import HiddenField from '../../../MCardsForm/fields/HiddenField';
import validateDate from '../../../../../helpers/validators/validateDate';
import fetchFormError from '../../../../../helpers/validators/fetchFormError';

const MultipleDatesForm = ({ onSubmit, deliverableItems, children }) => {
  const initFormState = () => {
    if (deliverableItems?.length > 0) {
      setInitialFormState({
        delivery_dates: deliverableItems.map(({ delivery_options: { delivery_date } }) => {
          const deliveryDate = !!delivery_date ? moment(delivery_date) : null;
          return { date: deliveryDate, time: deliveryDate };
        }),
      });
    }
  };

  useEffect(initFormState, [deliverableItems]);

  const [initialFormState, setInitialFormState] = useState({ delivery_dates: [] });

  const classes = useStyles();
  const i18nPath = 'pages.manage.recipients.carousel.steps.schedule';
  const intl = useIntl();

  const fullName = ({ first_name, last_name }) => `${first_name} ${last_name}`;
  const formattedAmount = ({ amount }) => `$${amount}`;

  return (
    deliverableItems?.length > 0 && (
      <Form
        onSubmit={onSubmit}
        initialValues={initialFormState}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FieldArray name="delivery_dates">
              {({ fields }) =>
                fields.map((name, index) => (
                  <Box key={name} className={classes.deliveryDetailsContainer}>
                    <Box className={classes.recipientDetailsContainer} mr={10}>
                      <Box ml={3} width="2em">
                        <Typography component="span">{formattedAmount(deliverableItems[index])}</Typography>
                      </Box>
                      <Box ml={20}>
                        <Typography component="span">{fullName(deliverableItems[index])}</Typography>
                      </Box>
                    </Box>
                    <HiddenField name={`${name}[uuid]`} initialValue={deliverableItems[index].uuid} />
                    <Box mr={3}>
                      <Field name={`${name}[date]`} key={`delivery-date-for-recipient-${index}`} validate={validateDate}>
                        {({ input, meta }) => (
                          <MCardsDateTimePicker
                            type="date"
                            placeholder={intl.formatMessage({ id: `${i18nPath}.datepicker.date` })}
                            formatOutput
                            name={input.name}
                            value={input.value}
                            onChange={input.onChange}
                            disablePast={true}
                            error={!!fetchFormError(meta)}
                            helperText={fetchFormError(meta)}
                          />
                        )}
                      </Field>
                    </Box>
                    <Field name={`${name}[time]`} key={`delivery-time-for-recipient-${index}`} validate={validateDate}>
                      {({ input, meta }) => (
                        <MCardsDateTimePicker
                          type="time"
                          placeholder={intl.formatMessage({ id: `${i18nPath}.datepicker.time` })}
                          name={input.name}
                          value={input.value}
                          onChange={input.onChange}
                          views={['hours']}
                          error={!!fetchFormError(meta)}
                          helperText={fetchFormError(meta)}
                        />
                      )}
                    </Field>
                  </Box>
                ))
              }
            </FieldArray>

            {children}
          </form>
        )}></Form>
    )
  );
};

const useStyles = makeStyles(() => ({
  deliveryDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '1em',
  },
  recipientDetailsContainer: {
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    width: '20em',
    maxHeight: '3.25em',
  },
}));
export default MultipleDatesForm;
