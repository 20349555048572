import actionTypes from './cardholders.actionTypes';

export default {
  getOneStart: () => ({
    type: actionTypes.GET_ONE_START,
  }),

  getOneSuccess: (data) => ({
    type: actionTypes.GET_ONE_SUCCESS,
    payload: data,
  }),

  getOneError: (errorMessage) => ({
    type: actionTypes.GET_ONE_ERROR,
    payload: errorMessage,
  }),

  toggleCardStart: () => ({
    type: actionTypes.TOGGLE_CARD_START,
  }),

  toggleCardSuccess: (data) => ({
    type: actionTypes.TOGGLE_CARD_SUCCESS,
    payload: data,
  }),

  toggleCardError: (errorMessage) => ({
    type: actionTypes.TOGGLE_CARD_ERROR,
    payload: errorMessage,
  }),
};
