import actionTypes from './carddesigns.actionTypes';
import initialState from './carddesigns.initialState';

const carddesignsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.CARDDESIGN_LOAD_START:
      return {
        ...state,
        isLoading: true,
        currentCarddesign: null,
        errorMessage: null,
      };
    case actionTypes.CARDDESIGN_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentCarddesign: payload,
      };
    case actionTypes.CARDDESIGN_LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    default:
      return state;
  }
};

export default carddesignsReducer;
