const validatePhone = (value) => {
  const phoneRegex = new RegExp(/^[0-9]{8,12}$/i);

  if (value) {
    return phoneRegex.test(value) ? undefined : 'Invalid mobile number';
  } else {
    return 'Required';
  }
};

export default validatePhone;
