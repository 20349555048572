import React from 'react';
import MCardsButton from '../../../MCardsForm/MCardsButton';
import { Box, Typography } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { makeStyles } from '@material-ui/styles';

const TerminalVerificationCell = ({ terminal }) => {
  const classes = useStyles();

  return (
    <Box component="span">
      {!!terminal.verified_by ? (
        <Typography component="span" variant="body2">
          {terminal.verified_by?.name}
        </Typography>
      ) : (
        <MCardsButton type="button" size="small" disabled className={classes.button}>
          <IntlMessages id="pages.manage.locations.components.terminals.verifyButtonText" />
        </MCardsButton>
      )}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  button: {
    height: '30px',
  },
}));

export default TerminalVerificationCell;
