import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { ArrowBack } from '@material-ui/icons';
import CmtCarousel from '@coremat/CmtCarousel';
import StepsIcon from './StepsIcon';

const FormCarousel = ({ steps, onSliderChange }) => {
  const [slider, setSlider] = useState(null);

  const updateSlider = (slider) => {
    onSliderChange(slider);
    setSlider(slider);
  };

  const initialStep = 0;
  const [currentStep, setCurrentStep] = useState(initialStep);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false, // Hides arrows UI elements
    accessibility: false, // Prevents keyboard navigation
    initialSlide: initialStep,
    swipe: false,
    swipeToSlide: false,
    touchMove: false,
    vertical: false,
    verticalSwiping: false,
    adaptiveHeight: false,
    infinite: false,
    lazyLoad: true,
    beforeChange: (_current, next) => setCurrentStep(next),
    appendDots: (dots) => (
      <ul>
        {dots.map((dot, index) => (
          <StepsIcon
            key={index}
            stepIcons={steps[index].stepIcons}
            currentStep={currentStep}
            index={index}
            onClick={() => slider.slickGoTo(index)}
            {...dot.props}
          />
        ))}
      </ul>
    ),
    ref: (slider) => updateSlider(slider),
  };

  const renderRow = (item, index) => {
    return (
      <Box key={index}>
        <Typography align="center" variant="h2" style={{ marginTop: '4rem', marginBottom: '1.8rem' }}>
          <IntlMessages id={`${item.intl_path}.title`} />
        </Typography>
        {item.hasSubtitle && (
          <Typography align="center" variant="subtitle1" color="textSecondary">
            <IntlMessages id={`${item.intl_path}.subtitle`} />
          </Typography>
        )}
        <Box style={{ textAlign: 'center' }}>{item.content}</Box>
      </Box>
    );
  };

  return (
    <>
      <IconButton
        onClick={() => slider.slickPrev()}
        style={{
          border: '1px solid',
          padding: '0.1rem',
        }}
        disabled={currentStep === 0}>
        <ArrowBack />
      </IconButton>
      <CmtCarousel
        outline={false}
        color="#EEEEEE"
        activeColor="#0826EB"
        dotSize={0}
        settings={settings}
        data={steps}
        dotPosition="top"
        renderRow={renderRow}
      />
    </>
  );
};

export default FormCarousel;
