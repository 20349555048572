import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateCampaignAsync } from 'redux/reducers/distributor_api/campaigns/campaigns.thunks';
import PlaceRemovalConfirmation from './LocationsStep/PlaceRemovalConfirmation';
import LocationList from './LocationsStep/LocationList';
import ActionButtonsContainer from './ActionButtons/ActionButtonsContainer';
import SaveButton from './ActionButtons/SaveButton';
import PublishButton from './ActionButtons/PublishButton';

const LocationsStep = ({ isEdit }) => {
  const dispatch = useDispatch();
  const { currentCampaign, isUpdating, isPublishing } = useSelector((state) => state.campaigns);

  // states
  const [addedLocations, setAddedLocations] = useState([]);
  const [initialFormState, setInitialFormState] = useState(null);
  const [allLocationsRemovalModalVisible, setAllLocationsRemovalModalVisible] = useState(false);
  const [selectedLocationType, setSelectedLocationType] = useState(null);

  const removeLocation = (place) => {
    setAddedLocations(addedLocations.filter((obj) => obj.place_id !== place.place_id));
  };

  const campaignHasCardsDelivered = () => currentCampaign?.delivery_statistics.sent_count > 0;

  const restrictedBySpecificLocations = () => selectedLocationType === 'specific';

  const campaignWithSelectedRestrictions = () => {
    let locationsRestrictions = [];
    if (restrictedBySpecificLocations()) {
      locationsRestrictions = addedLocations.map((place) => ({
        type: 'google_place_id_restriction',
        value: place.place_id,
      }));
    }
    return { ...currentCampaign, location_restrictions_attributes: locationsRestrictions };
  };

  const onSubmit = () => {
    updateCampaignAsync(campaignWithSelectedRestrictions(), dispatch).finally(() => {
      if (!restrictedBySpecificLocations()) setAddedLocations([]);
    });
  };

  const toGoogleMapsPlace = ({ restrictable }) => {
    if (!restrictable) return null;

    return {
      ...restrictable,
      place_id: restrictable.google_place_id,
    };
  };

  const initFormState = (campaignRestrictions) => {
    setSelectedLocationType(campaignRestrictions ? 'specific' : 'all');
    setInitialFormState({
      location_restrictions_type: selectedLocationType,
      location_restrictions_attributes: addedLocations,
    });
  };

  const onSelectedAllLocations = () => {
    if (campaignHasCardsDelivered() && addedLocations.length > 0) {
      setAllLocationsRemovalModalVisible(true);
    } else {
      setSelectedLocationType('all');
    }
  };

  const onSelectedSpecificLocations = () => setSelectedLocationType('specific');

  const handleAllLocationsRemoval = (removalConfirmed) => {
    if (removalConfirmed) setSelectedLocationType('all');
    setAllLocationsRemovalModalVisible(false);
  };

  // Hooks declaration

  useEffect(() => {
    if (currentCampaign) {
      const campaignRestrictions = currentCampaign.location_restrictions;

      if (campaignRestrictions) setAddedLocations(campaignRestrictions.map(toGoogleMapsPlace).filter((p) => p));
      if (!initialFormState) initFormState(campaignRestrictions);
    } else {
      setAddedLocations([]);
    }
  }, [currentCampaign]);

  useEffect(() => {
    if (selectedLocationType === 'all') setAddedLocations([]);
  }, [selectedLocationType]);

  return (
    initialFormState && (
      <Form
        onSubmit={onSubmit}
        initialValues={initialFormState}
        render={({ handleSubmit }) => (
          <form
            onSubmit={handleSubmit}
            onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
            style={{ minWidth: '100%', marginTop: '2rem' }}>
            <PlaceRemovalConfirmation onConfirm={handleAllLocationsRemoval} modalOpen={allLocationsRemovalModalVisible} />
            <Field name="location_restrictions_type" type="radio">
              {(props) => (
                <FormControl style={{ width: '100%', alignItems: 'center', marginBottom: '2rem' }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={selectedLocationType}
                    value={selectedLocationType}
                    name={props.input.name}
                    style={{ flexDirection: 'row' }}>
                    <FormControlLabel
                      value="all"
                      control={<Radio color="primary" />}
                      label="All Locations"
                      onChange={onSelectedAllLocations}
                    />
                    <FormControlLabel
                      value="specific"
                      control={<Radio color="primary" />}
                      label="Specific Locations"
                      onChange={onSelectedSpecificLocations}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </Field>
            {selectedLocationType === 'specific' && (
              <LocationList
                list={addedLocations}
                onAdd={(newLocation) => setAddedLocations(addedLocations.concat(newLocation))}
                onRemove={removeLocation}
                shouldConfirmOnRemoval={campaignHasCardsDelivered}
              />
            )}
            <ActionButtonsContainer>
              <SaveButton disabled={isUpdating || isPublishing} />
              {isEdit && (
                <PublishButton getCampaignFn={campaignWithSelectedRestrictions} disabled={isUpdating || isPublishing} />
              )}
            </ActionButtonsContainer>
          </form>
        )}
      />
    )
  );
};

export default LocationsStep;
