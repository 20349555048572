import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, CssBaseline, InputAdornment, Typography } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { createOrUpdateCampaignAsync } from 'redux/reducers/distributor_api/campaigns/campaigns.thunks';
import { makeStyles } from '@material-ui/styles';
import { useDropzone } from 'react-dropzone';
import MCardsTextField from 'components/mcards/MCardsForm/MCardsTextField';
import MCardsSelectInput from 'components/mcards/MCardsForm/MCardsSelectInput';
import validatePresence from '../../../../../helpers/validators/validatePresence';
import fetchFormError from '../../../../../helpers/validators/fetchFormError';
import IntlMessages from '../../../../../@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';
import SaveButton from './ActionButtons/SaveButton';
import ActionButtonsContainer from './ActionButtons/ActionButtonsContainer';
import { AssignmentInd, CardMembership, Image } from '@material-ui/icons';

const MB = 1000000;
const MAX_IMG_FILE_SIZE = process?.env?.MAX_IMG_FILE_SIZE || 5 * MB;

const BasicDetailsStep = () => {
  const dispatch = useDispatch();

  const [formState, setFormState] = useState({ campaignName: '' });
  const [imageData, setImageData] = useState(null);
  const [imageName, setImageName] = useState('');
  const [imageError, setImageError] = useState('');

  const { currentCampaign, isUpdating } = useSelector((state) => state.campaigns);
  const { cardprograms } = useSelector((state) => state.cardprograms);

  useEffect(() => {
    if (currentCampaign) {
      setFormState((prevState) => ({
        ...prevState,
        campaignName: currentCampaign.name,
        campaignMarketerCard: currentCampaign.marketer_card_uuid,
      }));
    }
  }, [currentCampaign]);

  const clearImageSelection = useCallback(() => {
    setImageName('');
    setImageData(null);
    setImageError('');
  }, []);

  const onDropAccepted = useCallback((files) => {
    setImageName(files[0].name);
    setImageError('');
    convertImageToBase64(files[0]);
  }, []);

  const onDropRejected = useCallback((rejectedFiles) => {
    clearImageSelection();
    const errors = rejectedFiles[0]?.errors;

    if (errors[0]?.code === 'file-too-large') {
      setImageError(`File is larger than ${MAX_IMG_FILE_SIZE / 1000000} MB`);
    } else {
      setImageError(errors[0]?.message);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    onFileDialogCancel: clearImageSelection,
    noDrag: true,
    multiple: false,
    maxSize: MAX_IMG_FILE_SIZE,
  });

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const convertImageToBase64 = async (file) => {
    const imgRegex = new RegExp(/(^.*)\.(jpg|png|jpeg|svg|gif)/gi);

    if (file && imgRegex.test(file?.name)) {
      const base64 = await convertBase64(file);
      setImageData(base64);
    } else {
      setImageData(null);
      setImageError('Failed to process your file, please try with an image.');
    }
  };

  const onSubmit = (form) => {
    const campaign = {
      uuid: currentCampaign?.uuid,
    };

    if (!!form['campaignName']) campaign['name'] = form['campaignName'];
    if (!!imageData) campaign['image'] = imageData;
    if (!!form['campaignMarketerCard']) campaign['marketer_card_uuid'] = form['campaignMarketerCard'];

    createOrUpdateCampaignAsync(campaign, dispatch);
  };

  const classes = useStyles();

  const intlPath = 'pages.manage.campaigns.carousel.steps.basic_details';
  const intl = useIntl();

  return (
    <Container component="main">
      <CssBaseline />
      <Box className={classes.paper}>
        <Form
          onSubmit={onSubmit}
          initialValues={formState}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <Box display="flex">
                <Box flex="1" padding=".75rem">
                  <Typography align="left">
                    <IntlMessages id={`${intlPath}.labels.name.title`} />
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" className={classes.fieldLabelSubtitle}>
                    <IntlMessages id={`${intlPath}.labels.name.subtitle`} />
                  </Typography>
                </Box>
                <Box flex="1">
                  <Field id="campaignName" name="campaignName" validate={validatePresence}>
                    {({ input, meta }) => (
                      <MCardsTextField
                        id={input.id}
                        placeholder={intl.formatMessage({ id: `${intlPath}.input.name` })}
                        name={input.name}
                        type={input.type}
                        onChange={input.onChange}
                        value={input.value}
                        margin="normal"
                        error={!!fetchFormError(meta)}
                        helperText={fetchFormError(meta)}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AssignmentInd className={classes.icon} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </Field>
                </Box>
              </Box>

              <Box {...getRootProps()} display="flex">
                <Box flex="1" padding=".75rem">
                  <Typography align="left">
                    <IntlMessages id={`${intlPath}.labels.image.title`} />
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" className={classes.fieldLabelSubtitle}>
                    <IntlMessages id={`${intlPath}.labels.image.subtitle`} />
                  </Typography>
                </Box>
                <Box flex="1">
                  <MCardsTextField
                    id="imageName"
                    placeholder={intl.formatMessage({ id: `${intlPath}.input.image` })}
                    name="imageName"
                    value={imageName}
                    margin="normal"
                    fullWidth
                    error={!!imageError}
                    helperText={imageError}
                    InputProps={{
                      className: classes.imageField,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Image className={classes.icon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <input {...getInputProps()} accept="image/*" />
                </Box>
              </Box>

              <Box display="flex">
                <Box flex="1" padding=".75rem">
                  <Typography align="left">
                    <IntlMessages id={`${intlPath}.labels.marketer_card.title`} />
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" className={classes.fieldLabelSubtitle}>
                    <IntlMessages id={`${intlPath}.labels.marketer_card.subtitle`} />
                  </Typography>
                </Box>
                <Box flex="1">
                  <Field id="campaignMarketerCard" name="campaignMarketerCard" validate={validatePresence}>
                    {({ input, meta }) => (
                      <MCardsSelectInput
                        {...input}
                        placeholder={intl.formatMessage({ id: `${intlPath}.input.marketer_card` })}
                        onChange={input.onChange}
                        options={cardprograms.map((card) => ({ label: card.title, value: card.uuid }))}
                        margin="normal"
                        error={!!fetchFormError(meta)}
                        helperText={fetchFormError(meta)}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <CardMembership className={classes.icon} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </Field>
                </Box>
              </Box>

              <ActionButtonsContainer>
                <SaveButton disabled={isUpdating || !!imageError} />
              </ActionButtonsContainer>
            </form>
          )}
        />
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  btnContent: {
    margin: theme.spacing(0, 8),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  icon: {
    color: '#6A6A6A',
    fontSize: '1.8rem',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fieldLabelSubtitle: {
    textAlign: 'left',
    fontSize: '.75rem',
    fontStyle: 'italic',
  },
  imageField: {
    backgroundColor: '#EBEBEB',
    cursor: 'pointer',
    '& input': {
      cursor: 'pointer',
      caretColor: 'transparent',
    },
  },
}));

export default BasicDetailsStep;
