import React, { useState } from 'react';
import SearchableList from 'components/SearchableList';
import { useIntl } from 'react-intl';
import MCardsTable from 'components/mcards/MCardsTable';
import { Box, Link, Typography } from '@material-ui/core';
import { formatDateRelative } from 'helpers/dates';
import DatePeriod from 'components/filters/DatePeriod';
import SearchText from 'components/filters/SearchText';
import TerminalStatusCell from 'components/mcards/Terminals/TerminalsList/TerminalRow/TerminalStatusCell';
import DropdownFilter from 'components/filters/DropdownFilter';
import IntlMessages from '@jumbo/utils/IntlMessages';
import service from 'services/distributor_api/cardactivities.service';
import CmtImage from '@coremat/CmtImage';

const TerminalCreatedAtDate = ({ item }) => (item.created_at ? formatDateRelative(item.created_at) : null);

const CardholderName = ({ uuid, name }) => (!!uuid ? <Link href={`/reports/cardholders/${uuid}`}>{name}</Link> : name);

const TerminalName = ({ uuid, name }) => (!!uuid ? <Link href={`/reports/locations/${uuid}`}>{name}</Link> : name);

const LocationName = ({ location, merchantTradeName }) =>
  !!location?.uuid ? (
    <Link href={`/reports/locations/${location.uuid}`}>{location.name}</Link>
  ) : location ? (
    location.name
  ) : (
    merchantTradeName
  );

const Address = ({ location }) => location?.address || null;

const TerminalStatus = ({ item, onTerminalUpdated }) => {
  const location = item.location;

  return (
    <Box component="span" display="flex" alignItems="center">
      <TerminalStatusCell
        terminal={{
          uuid: item.terminal_uuid,
          verification_status: item.location_verified_status,
          merchant_trade_name: item.merchant_trade_name,
          card_acceptor_id: item.card_acceptor_id,
        }}
        location={
          location && {
            uuid: location.uuid,
            name: location.name,
            address: {
              long_address: location.address,
              google_place_id: location.google_place_id,
              ...location.geolocation,
            },
          }
        }
        onTerminalUpdated={onTerminalUpdated}
      />
    </Box>
  );
};

const GearIcon = ({ item }) => {
  return (
    <Box
      component={Link}
      href={`/locations/new?card_acceptor_id=${item.card_acceptor_id}&merchant_trade_name=${item.merchant_trade_name}`}
      width={20}
      height={20}
      display="flex"
      alignItems="left">
      {['auto_verified', 'unverified'].includes(item.location_verified_status) && (
        <CmtImage src="/images/icons/gear_cog.png" />
      )}
    </Box>
  );
};

const ActivitiesRows = ({ currentList, onTerminalUpdated }) => {
  let headerNames = ['time', 'cardholder', 'terminal', 'associated_location', 'address', 'status', 'edit'];

  return (
    <MCardsTable
      headers={headerNames.map((header) => (
        <IntlMessages id={`pages.manage.terminals.list.table.col.${header}`} />
      ))}
      rows={currentList?.map((item) => [
        <TerminalCreatedAtDate item={item} />,
        <CardholderName uuid={item.cardholder_uuid} name={item.cardholder_name} />,
        <TerminalName uuid={item.location?.uuid} name={item.merchant_trade_name} />,
        <LocationName location={item.location} merchantTradeName={item.merchant_trade_name} />,
        <Address location={item.location} />,
        <TerminalStatus item={item} onTerminalUpdated={onTerminalUpdated} />,
        <GearIcon item={item} />,
      ])}
      widths={['150px', '150px', '150px', '150px', '200px', '100px', '20px']}
      cellPadding={'0.5rem 1.5rem'}
    />
  );
};

const ActivityFeed = () => {
  const intl = useIntl();
  const [timeOfRender, setTimeOfRender] = useState(null);

  const reloadList = () => setTimeOfRender(new Date().getTime());

  const getVerifiedStatusLabel = (status) => (
    <IntlMessages id={`pages.manage.locations.location_verified_status.${status}`} />
  );

  return (
    <Box my={4}>
      <Typography component="h1" variant="h1">
        Activity Feed
      </Typography>
      <SearchableList
        getAll={(filters, page) =>
          service.getAll(filters, page, {
            action_type: 'Shared::Core::PaymentNetworkCardTransaction',
          })
        }
        selector={(state) => state.terminals}
        ListComponent={(props) => <ActivitiesRows {...props} onTerminalUpdated={reloadList} />}
        searchBarXs={6}
        filters={[
          <SearchText
            placeholder={intl.formatMessage({ id: 'pages.manage.terminals.search.placeholder' })}
            searchBarXs={6}
          />,
          <DatePeriod />,
          <DropdownFilter
            type="location-verified-status"
            placeholder="Verified Status"
            filterBy="location_verified_status"
            options={[
              { label: getVerifiedStatusLabel('all') },
              { value: 'verified', label: getVerifiedStatusLabel('verified') },
              { value: 'unverified,auto_verified', label: getVerifiedStatusLabel('unverified') },
            ]}
          />,
        ]}
        timeOfRender={timeOfRender}
      />
    </Box>
  );
};

export default ActivityFeed;
