import actionTypes from './cardprograms.actionTypes';

const cardprogramsLoadStart = () => ({
  type: actionTypes.CARDPROGRAMS_LOAD_START,
});

const cardprogramsLoadSuccess = (cardprograms) => ({
  type: actionTypes.CARDPROGRAMS_LOAD_SUCCESS,
  payload: cardprograms,
});

const cardprogramsLoadError = (errorMessage) => ({
  type: actionTypes.CARDPROGRAMS_LOAD_ERROR,
  payload: errorMessage,
});

const cardprogramLoadStart = () => ({
  type: actionTypes.CARDPROGRAM_LOAD_START,
});

const cardprogramLoadSuccess = (cardprogram) => ({
  type: actionTypes.CARDPROGRAM_LOAD_SUCCESS,
  payload: cardprogram,
});

const cardprogramLoadError = (errorMessage) => ({
  type: actionTypes.CARDPROGRAM_LOAD_ERROR,
  payload: errorMessage,
});

export default {
  cardprogramsLoadStart,
  cardprogramsLoadSuccess,
  cardprogramsLoadError,
  cardprogramLoadStart,
  cardprogramLoadSuccess,
  cardprogramLoadError,
};
