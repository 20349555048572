import { Grid } from '@material-ui/core';
import MCardsSelectInput from '../mcards/MCardsForm/MCardsSelectInput';
import React, { useState } from 'react';

/*
 * This component is used to filter a collection by a value in a select dropdown list.
 */

const DropdownFilter = ({ type, placeholder, options, filterBy, onChange, initialValue = '' }) => {
  const [value, setValue] = useState(initialValue);

  const onChangeVerifiedStatus = (newValue) => {
    setValue(newValue);
    onChange({ [filterBy]: newValue });
  };

  return (
    <Grid item xs>
      <MCardsSelectInput
        id={`filter-${type}`}
        name={`filter-${type}`}
        label={placeholder}
        value={value}
        options={options}
        onChange={(event) => onChangeVerifiedStatus(event.target.value)}
      />
    </Grid>
  );
};

export default DropdownFilter;
