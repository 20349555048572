import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import MCardsButton from '../../../MCardsForm/MCardsButton';
import RecipientsList from '../../CampaignReport/RecipientsList';
import RecipientsModal from '../../RecipientsModal';

const ActiveRecipientsModal = ({ campaign, isOpen, onClose }) => {
  const { uuid, name } = campaign;

  return (
    <RecipientsModal isOpen={isOpen} onClose={onClose}>
      <Box mb={8}>
        <Typography variant="h1">Active Recipients</Typography>
      </Box>

      <RecipientsList campaign={{ uuid, name }} deliveryStatus="delivered" />

      <Box mt={8}>
        <MCardsButton onClick={onClose}>Done</MCardsButton>
      </Box>
    </RecipientsModal>
  );
};

ActiveRecipientsModal.propTypes = {
  campaign: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActiveRecipientsModal;
