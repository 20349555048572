import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import MCardsStatsBox from './MCardsStatsBox';
import { makeStyles } from '@material-ui/styles';
import { Skeleton } from '@material-ui/lab';

const MCardsStatsGrid = ({ statistics, loading, skeletons }) => {
  const classes = useStyles();

  return (
    <Box className={classes.statsContainer}>
      <CardContent className={classes.content}>
        <Grid container className={classes.grid} justifyContent="space-between" spacing={5}>
          {loading ? (
            <>
              {[...Array(skeletons).keys()].map((number) => (
                <Grid item index={number} key={number} className={classes.statContainer} xs={12} sm={6} md={3}>
                  <StatsSkeleton />
                </Grid>
              ))}
            </>
          ) : (
            statistics?.map((data, index) => (
              <Grid item index={index} key={data.name} className={classes.statContainer} xs={12} sm={6} md={3}>
                <MCardsStatsBox data={data} />
              </Grid>
            ))
          )}
        </Grid>
      </CardContent>
    </Box>
  );
};

const StatsSkeleton = () => {
  const classes = useStyles();

  return (
    <Card className={classes.skeletonRoot}>
      <CardContent className={classes.skeletonContent}>
        <Box mb={3}>
          <Skeleton animation="wave" variant="circle" width={40} height={40} />
        </Box>

        <Typography component="h1" variant="h1">
          <Skeleton height={40} width={40} />
        </Typography>

        <Typography variant="subtitle2" className={classes.skeletonTitle}>
          <Skeleton />
        </Typography>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  statsContainer: {
    background: '#ffffff',
    margin: theme.spacing(4, 0),
  },
  content: {
    padding: '0px',
    marginBottom: '1.5rem',
  },
  grid: {
    gap: '1rem',
  },
  statContainer: {
    width: '100%',
    flex: 1,
    maxWidth: '100%',
  },
  skeletonRoot: {
    background: '#ffffff',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: 10,
    margin: theme.spacing(0),
    padding: theme.spacing(1),
    position: 'relative',
    textAlign: 'center',
    height: '100%',
  },
  skeletonContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  skeletonTitle: {
    width: '50%',
  },
}));

MCardsStatsGrid.defaultProps = {
  skeletons: 4,
};

MCardsStatsGrid.propTypes = {
  statistics: PropTypes.array,
  loading: PropTypes.bool,
  skeletons: PropTypes.number,
};

export default MCardsStatsGrid;
