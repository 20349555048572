/*import Firebase from './firebase';
import JWTAuth from './jwt';*/
import auth0 from './auth0';
import BasicAuth from './Basic';

export const AuthMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  basic: BasicAuth,
  auth0: auth0,
};
