import React from 'react';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { Avatar, Box } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import moment from 'moment';

export const LocationSummary = ({ location }) => {
  const classes = summaryStyles();

  return (
    <Box className={classes.summaryContainer}>
      <Box className={classes.columnContainer}>
        <Avatar aria-label="icon" className={classes.avatar}>
          <CmtImage src="/images/icons/location-marker.png" />
        </Avatar>
      </Box>
      <Box className={classes.columnContainer}>
        <Typography variant="h1">{location.name}</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Joined: {moment(location.created_at).format('DD/MM/YYYY')}
        </Typography>
      </Box>
      <Box className={classes.columnContainer}>
        <Typography>
          <CmtImage src="/images/icons/landline-phone.png" className={classes.summarySmallIcon} />
          {location.address?.phone || 'N/A'}
        </Typography>
        <Typography>
          <CmtImage src="/images/icons/mail.png" className={classes.summarySmallIcon} />
          {location.address?.email || 'N/A'}
        </Typography>
      </Box>
    </Box>
  );
};

const summaryStyles = makeStyles((theme) => ({
  summaryContainer: {
    display: 'flex',
    columnGap: '4rem',
    marginBottom: '3.6rem',
  },
  columnContainer: {
    display: 'flex',
    rowGap: '1rem',
    flexDirection: 'column',
  },
  avatar: {
    background: 'rgba(8, 38, 235, 0.04)',
    color: theme.palette.primary.main,
    height: '100px',
    width: '100px',
  },
  summarySmallIcon: {
    marginRight: '1rem',
  },
}));
