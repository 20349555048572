import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Card, CardContent, Grid, Icon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import dollars from 'helpers/dollars';
import InfoTooltip from '../InfoTooltip';

const SubItemsBreakdown = ({ data, classes }) => {
  if (!!data?.subitems?.length) {
    return (
      <Grid container spacing={1}>
        {data.subitems.map((item, index) => (
          <Grid item xs key={index}>
            <Typography component="h2" variant="h2">
              {item.type === 'dollars' ? dollars(item.value) : item.value}
            </Typography>
            <Typography variant="subtitle2" className={classes.title}>
              {item.title}
            </Typography>

            <Typography variant="subtitle2">{item.subtitle}</Typography>
          </Grid>
        ))}
      </Grid>
    );
  }

  return null;
};

const MCardsStatsBox = ({ data }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        {data.description_key && (
          <Box className={classes.tooltipContainer}>
            <InfoTooltip i18nMessageKey={`messages.statistics.${data.description_key}`} />
          </Box>
        )}
        <Box mb={3}>
          <Avatar aria-label="icon" className={classes.avatar}>
            <Icon fontSize="small">{data.icon}</Icon>
          </Avatar>
        </Box>

        <Typography component="h1" variant="h1">
          {data.type === 'dollars' ? dollars(data.value) : data.value}
        </Typography>
        <Typography variant="subtitle2" className={classes.title}>
          {data.title}
        </Typography>

        <SubItemsBreakdown data={data} classes={classes} />

        {data.subtitle && <Typography variant="subtitle2">{data.subtitle}</Typography>}
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    background: 'rgba(8, 38, 235, 0.04)',
    color: theme.palette.primary.main,
  },
  tooltipContainer: {
    position: 'absolute',
    top: theme.spacing(3),
    left: theme.spacing(3),
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    background: '#ffffff',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: 10,
    margin: theme.spacing(0),
    padding: theme.spacing(1),
    position: 'relative',
    textAlign: 'center',
    height: '100%',
  },
  title: {
    color: '#999999',
    fontWeight: 500,
    margin: theme.spacing(3, 0),
  },
}));

MCardsStatsBox.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    type: PropTypes.string,
    icon: PropTypes.string,
    subitems: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default MCardsStatsBox;
