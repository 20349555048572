import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

const FullName = ({ recipient }) => {
  const classes = useStyles();
  const cardExist = recipient.card && recipient.card.cardholder_uuid;

  return (
    <Box
      className={cardExist ? classes.withLink : ''}
      component={cardExist ? Link : 'span'}
      to={cardExist ? `/reports/cardholders/${recipient.card.cardholder_uuid}` : null}>
      {`${recipient.first_name} ${recipient.last_name}`}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  withLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default FullName;
