import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import PWModal from '../../../PWModal';
import MCardsButton from '../../MCardsForm/MCardsButton';
import MCardsModalTitleIcon from '../../MCardsModalTitleIcon';
import { cancelRecipient, updateRecipient } from 'redux/reducers/distributor_api/recipients/recipients.thunks';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Field, Form } from 'react-final-form';
import clsx from 'clsx';
import { extractPhoneFromItem } from 'helpers/phone';
import MCardsTextField from '../../MCardsForm/MCardsTextField';
import AmountField from '../../MCardsForm/fields/AmountField';
import CountryCodeField from '../../MCardsForm/fields/CountryCodeField';
import PhoneField from '../../MCardsForm/fields/PhoneField';
import EmailField from '../../MCardsForm/fields/EmailField';
import HiddenField from '../../MCardsForm/fields/HiddenField';
import MultilineField from '../../MCardsForm/fields/MultilineField';
import SimpleTextField from '../../MCardsForm/fields/SimpleTextField';

const EditRecipientModal = ({ recipient, campaign, isOpen, closeModal, onRecipientUpdated = null }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [isUpdating, setIsUpdating] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);

  const formState = {
    ...recipient,
    campaign_name: campaign.name,
    balances_attributes: recipient.balances,
  };

  const cancel = (campaign, recipient) => {
    setIsCancelling(true);
    cancelRecipient(dispatch, campaign.uuid, recipient.uuid, onRecipientUpdated);
  };

  const update = (updatedRecipient) => {
    if (updatedRecipient) {
      setIsUpdating(true);
      delete updatedRecipient.campaign_name;

      const payload = {
        ...updatedRecipient,
        phone: extractPhoneFromItem(updatedRecipient),
        balances_attributes: [
          {
            ...updatedRecipient.balances_attributes[0],
            value: updatedRecipient.balances_attributes[0].value.replace(/[^0-9.]/g, ''),
          },
        ],
      };

      updateRecipient(dispatch, campaign.uuid, payload, onRecipientUpdated);
    }
  };

  return (
    <PWModal size="xs" fullWidth modalOpen={isOpen}>
      <div className={classes.modalContainer}>
        <Box className={classes.iconBox}>
          <MCardsModalTitleIcon src="/images/icons/edit.png" />
        </Box>

        <Typography align="center" variant="h1" className={classes.title}>
          <IntlMessages id="pages.manage.campaigns.recipients.edit_modal.title" />
        </Typography>

        <Typography variant="subtitle1" color="textSecondary" className={classes.subtitle}>
          <IntlMessages id="pages.manage.campaigns.recipients.edit_modal.subtitle" />
        </Typography>

        <Box my={7}>
          <Button
            type="button"
            className={classes.cancelBtn}
            disabled={isUpdating || isCancelling}
            onClick={() => cancel(campaign, recipient)}>
            <IntlMessages
              id={`pages.manage.campaigns.recipients.edit_modal.button.${isCancelling ? 'cancel_busy' : 'cancel_item'}`}
            />
          </Button>
        </Box>

        <Box>
          <Form
            onSubmit={update}
            initialValues={formState}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Box className={classes.formRow}>
                  <Typography component="p" variant="body2" className={classes.formLabel}>
                    Campaign
                  </Typography>
                  <Field id="campaignName" name="campaign_name">
                    {({ input }) => <MCardsTextField {...input} fullWidth disabled />}
                  </Field>
                </Box>

                <Box className={classes.formRow}>
                  <Typography component="p" variant="body2" className={classes.formLabel}>
                    Amount
                  </Typography>
                  <AmountField name="balances_attributes[0][value]" label={null} />
                </Box>

                <Box className={clsx(classes.formRow, classes.withMarginRight)}>
                  <Typography component="p" variant="body2" className={classes.formLabel}>
                    To
                  </Typography>
                  <SimpleTextField name="first_name" placeholder="First name" />
                  <SimpleTextField name="last_name" placeholder="Last name" />
                </Box>

                <Box className={clsx(classes.formRow, classes.withMarginRight)}>
                  <Typography component="p" variant="body2" className={classes.formLabel}>
                    At
                  </Typography>
                  <CountryCodeField name="phone_country_code" width="50" />
                  <PhoneField name="phone_without_country_code" label={null} placeholder="Mobile number" />
                </Box>

                <Box className={classes.formRow}>
                  <EmailField name="email" label={null} placeholder="Email" />
                </Box>

                <Box>
                  <MultilineField name="message" minRows={4} placeholder="Message" />
                </Box>

                <HiddenField name="uuid" />
                <HiddenField name="balances_attributes[0][type]" initialValue="mcash_balance" />

                <Grid container spacing={4} className={classes.actionBtns}>
                  <Grid item xs={6}>
                    <MCardsButton type="button" color="default" onClick={closeModal} maxWidth>
                      <IntlMessages id="pages.manage.campaigns.recipients.edit_modal.button.back" />
                    </MCardsButton>
                  </Grid>

                  <Grid item xs={6}>
                    <MCardsButton type="submit" disabled={isUpdating || isCancelling} maxWidth>
                      <IntlMessages
                        id={`pages.manage.campaigns.recipients.edit_modal.button.${isUpdating ? 'busy' : 'confirm'}`}
                      />
                    </MCardsButton>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </Box>
      </div>
    </PWModal>
  );
};

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    border: 'none',
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    margin: '2rem 0 1rem',
    fontSize: '28px',
  },
  subtitle: {
    marginBottom: '1rem',
    textAlign: 'center',
  },
  formRow: {
    alignItems: 'center',
    display: 'flex',
    margin: theme.spacing(2, 0),
  },
  withMarginRight: {
    '& > :nth-child(2)': {
      marginRight: theme.spacing(2),
    },
  },
  formLabel: {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(2),
  },
  actionBtns: {
    marginTop: theme.spacing(1),
  },
  cancelBtn: {
    background: '#FFFFFF',
    border: `3px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    color: theme.palette.primary.main,
    padding: theme.spacing(1, 8),
    width: '100%',
    '&.Mui-disabled': {
      borderColor: 'rgba(0, 0, 0, 0.26)',
    },
  },
}));

export default EditRecipientModal;
