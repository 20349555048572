import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import MCardsButton from '../../../../MCardsForm/MCardsButton';

const SaveButton = ({ disabled, ...rest }) => {
  return (
    <MCardsButton variant="outlined" type="submit" disabled={disabled} {...rest}>
      <IntlMessages id="pages.campaignsPage.create.save" />
    </MCardsButton>
  );
};

export default SaveButton;
