import React, { useEffect } from 'react';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { useParams } from 'react-router';
import { LocationSummary } from './LocationSummary';
import LocationPageHeading from 'components/mcards/Locations/LocationPageHeading';
import CardActivities from '../../../../components/mcards/Cardholders/CardActivities';
import LocationsStats from 'components/mcards/Locations/LocationsStats';
import TerminalsList from 'components/mcards/Terminals/TerminalsList';
import { useDispatch, useSelector } from 'react-redux';
import { getOne } from 'redux/reducers/distributor_api/locations/locations.thunks';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import { Box, makeStyles } from '@material-ui/core';
import TabbedView from 'components/TabbedView';
import { useIntl } from 'react-intl';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
    padding: '3.625rem',
    borderRadius: '0.625rem',
  },
}));

const LocationDetailsPage = () => {
  const classes = useStyles();
  const { location_uuid } = useParams();
  const { isLoading, location } = useSelector((state) => state.locations);
  const dispatch = useDispatch();
  const Intl = useIntl();

  useEffect(() => {
    if (!location || location.uuid !== location_uuid) getOne(dispatch, location_uuid);
  }, [dispatch, location, location_uuid]);

  return isLoading && !location ? (
    <PageLoader />
  ) : (
    location && (
      <PageContainer heading={<LocationPageHeading />}>
        <Box className={classes.outerContainer}>
          <LocationSummary className={classes.summary} location={location} />
          <LocationsStats locationUuid={location_uuid} />

          <TabbedView
            tabs={[
              {
                value: '1',
                label: Intl.formatMessage({ id: 'pages.manage.locations.details.tab.terminals' }),
                tabPanel: <TerminalsList locationUuid={location_uuid} />,
              },
              {
                value: '2',
                label: Intl.formatMessage({ id: 'pages.manage.locations.details.tab.activity' }),
                tabPanel: (
                  <CardActivities
                    heading={<IntlMessages id="pages.manage.cardholders.cardactivity.list.title" />}
                    locationUuid={location_uuid}
                  />
                ),
              },
            ]}
          />
        </Box>
      </PageContainer>
    )
  );
};

export default LocationDetailsPage;
