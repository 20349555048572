import React from 'react';
import { Form } from 'react-final-form';
import { Typography } from '@material-ui/core';
import IntlMessages from '../../../../../@jumbo/utils/IntlMessages';

const InstantForm = ({ onSubmit, children }) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Typography align="center" variant="subtitle1" color="textSecondary">
            <IntlMessages id="pages.manage.recipients.carousel.steps.schedule.instant.text" />
          </Typography>

          {children}
        </form>
      )}></Form>
  );
};

export default InstantForm;
