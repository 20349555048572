import React, { useState } from 'react';
import { CardMedia, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';
import dollars from 'helpers/dollars';
import { formatDateRelative } from 'helpers/dates';
import PublishCampaignModal from './PublishCampaignModal';
import IntlMessages from '@jumbo/utils/IntlMessages';
import StatusLabel from '../../../StatusLabel';

export const CampaignImage = ({ imageUrl }) => {
  const classes = useStyles();
  return <CardMedia className={classes.campaignImage} image={imageUrl} title="campaign image" />;
};

export const CampaignName = ({ name, uuid }) => {
  const classes = useStyles();

  return (
    <Link href={`/reports/campaigns/${uuid}`} className={classes.nameLink}>
      {name}
    </Link>
  );
};

export const TotalValueDelivered = ({ amount }) => dollars(amount, 0);

export const Scheduled = ({ deliveryOptions }) => {
  return deliveryOptions?.delivery_date ? formatDateRelative(deliveryOptions.delivery_date) : null;
};

export const statusToLabel = (campaignStatus) => {
  const labels = {
    published: { text: <IntlMessages id="pages.manage.campaigns.list.status.published" />, state: 'success' },
    unpublished: { text: <IntlMessages id="pages.manage.campaigns.list.status.unpublished" />, state: 'error' },
    draft: { text: <IntlMessages id="pages.manage.campaigns.list.status.draft" />, state: 'warning' },
    deleted: { text: <IntlMessages id="pages.manage.campaigns.list.status.deleted" />, state: 'danger' },
  };
  return labels[campaignStatus];
};

export const CampaignStatus = ({ campaign, showDot = true }) => {
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const status = campaign.status;

  if (!status) return null;

  const isNotPublished = status !== 'published';

  const onStatusClick = (uuid, status) => {
    if (status === 'unpublished') setModalOpen(true);
    if (status === 'draft') history.push(`/campaigns/${uuid}/edit`);
  };

  return (
    <>
      <StatusLabel
        state={statusToLabel(status).state}
        text={statusToLabel(status).text}
        onClick={isNotPublished ? () => onStatusClick(campaign.uuid, status) : null}
        showDot={showDot}
      />

      {status === 'unpublished' && (
        <PublishCampaignModal isOpen={modalOpen} campaign={campaign} closeModal={() => setModalOpen(false)} />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  campaignImage: {
    borderRadius: '.25rem',
    height: 40,
    width: 40,
  },
  nameLink: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));
