import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { Grid, Typography } from '@material-ui/core';
import MCardsButton from 'components/mcards/MCardsForm/MCardsButton';
import makeStyles from '@material-ui/styles/makeStyles';
import { create, addTerminal } from 'redux/reducers/distributor_api/locations/locations.thunks';
import MCardsTextField from 'components/mcards/MCardsForm/MCardsTextField';
import GenericErrorModal from 'components/mcards/GenericErrorModal';
import ValidateSuccessModal from 'components/mcards/Terminals/TerminalsModal/ValidateSuccessModal';
import DetailSectionHeader from 'components/mcards/DetailSectionHeader';

const someEmpty = (...fields) => fields.some((field) => !field);

const AddTerminalForm = ({ suggestedMerchantTradeName, suggestedCardAcceptorId, place, existingLocation }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const redirectToDetails = () => {
    history.push(`/reports/locations/${newLocation?.uuid}`);
  };

  const closeSuccessModal = () => setSuccessModalOpened(false);

  const onValidateSuccess = existingLocation ? closeSuccessModal : redirectToDetails;

  const { isLoading, newLocation, createError, addedTerminal, addedTerminalError } = useSelector((state) => state.locations);

  const [successModalOpened, setSuccessModalOpened] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setSuccessModalOpened(!!(addedTerminal || newLocation));
    setError(!!(createError || addedTerminalError));
  }, [newLocation, createError, addedTerminal, addedTerminalError]);

  const initialFormState = {
    terminalId: suggestedCardAcceptorId ?? '',
    merchantTradeName: suggestedMerchantTradeName ?? '',
  };

  const anyFieldIsEmpty = (values) => someEmpty(values.terminalId, values.merchantTradeName);

  const terminalsAttributesFor = (values) => [
    { card_acceptor_id: values.terminalId, merchant_trade_name: values.merchantTradeName },
  ];

  const createLocationAndTerminal = (values) => {
    const addressAttributes = {
      long_address: place.formatted_address,
      google_place_id: place.place_id,
    };
    const terminalsAttributes = terminalsAttributesFor(values);

    create(dispatch, place.name, addressAttributes, terminalsAttributes);
  };

  const createTerminalForExistingLocation = (values) => {
    addTerminal(dispatch, existingLocation.uuid, terminalsAttributesFor(values));
  };

  const validateTerminal = (values, form) => {
    existingLocation ? createTerminalForExistingLocation(values) : createLocationAndTerminal(values);
    form.reset();
  };

  // TODO use onSubmit function when "Send Verification" is clicked.
  const onSubmit = (form) => console.log(form);

  return (
    <>
      <DetailSectionHeader
        imgSrc="/images/icons/plus-icon.png"
        messageIntlID="pages.manage.locations.components.terminals.addTerminalText"
      />
      <Form
        onSubmit={onSubmit}
        initialValues={initialFormState}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} noValidate className={classes.form}>
            <Grid container spacing={5} alignItems="flex-end">
              <Grid item sm={4}>
                <Typography component="h4" variant="h4" gutterBottom>
                  Terminal ID
                </Typography>
                <Field id="terminalId" name="terminalId">
                  {({ input }) => <MCardsTextField {...input} fullWidth />}
                </Field>
              </Grid>

              <Grid item sm={4}>
                <Typography component="h4" variant="h4" gutterBottom>
                  Merchant Trade Name
                </Typography>
                <Field id="merchantTradeName" name="merchantTradeName">
                  {({ input }) => <MCardsTextField {...input} fullWidth />}
                </Field>
              </Grid>

              <Grid item sm={4}>
                <MCardsButton
                  type="button"
                  className={classes.btn}
                  disabled={isLoading || anyFieldIsEmpty(values)}
                  onClick={() => validateTerminal(values, form)}>
                  Validate
                </MCardsButton>
              </Grid>
            </Grid>
          </form>
        )}
      />

      <ValidateSuccessModal
        location={newLocation ?? existingLocation}
        isOpen={successModalOpened}
        terminal={addedTerminal}
        onClose={onValidateSuccess}
      />

      <GenericErrorModal isOpen={error} onClose={() => setError(false)} />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {
    margin: '3rem 2rem 4rem',
  },
  btn: {
    width: '100%',
  },
}));

AddTerminalForm.propTypes = {
  suggestedMerchantTradeName: PropTypes.string,
  suggestedCardAcceptorId: PropTypes.string,
  place: PropTypes.object,
  existingLocation: PropTypes.object,
};

export default AddTerminalForm;
