import React from 'react';
import { Box, Typography } from '@material-ui/core';
import CmtImage from '@coremat/CmtImage';
import makeStyles from '@material-ui/styles/makeStyles';
import PropTypes from 'prop-types';

const TerminalNameCell = ({ terminal, showIcon = true }) => {
  const classes = useStyles();

  return (
    <Box component="span">
      {showIcon && (
        <Box component="span" className={classes.rowIconContainer}>
          <CmtImage src="/images/icons/location-marker.png" className={classes.locationIcon} />
        </Box>
      )}
      <Typography component="span" variant="body2">
        {terminal.merchant_trade_name}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  rowIconContainer: {
    verticalAlign: 'middle',
    marginRight: '0.5rem',
  },
  locationIcon: {
    height: '17px',
  },
}));

TerminalNameCell.defaultProps = {
  showIcon: true,
};

TerminalNameCell.propTypes = {
  terminal: PropTypes.object,
  showIcon: PropTypes.bool,
};

export default TerminalNameCell;
