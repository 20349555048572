import React from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

/**
 * MCardsTable:
 * This is a custom build Table component specifically designed after mCards styling.
 *
 * @param {array} headers - An array of elements to be displayed in header row.
 *                         Elements can be either pure strings or {JSX.Element} instances.
 *
 * @param {array} rows - An array of elements to be displayed in the table.
 *                      Elements can be either pure strings or {JSX.Element} instances.
 *
 * @param {array} widths - An array of string which determine the width of each column.
 *                        If a column's width is not specified, this component will default to (100 / widths.length).
 *
 * @param {string} maxHeight - Whole component's max height property
 *
 * @param {string} cellPadding - String containing the padding applied to each cell of the table.
 *                               Defaults to '1rem 2rem'.
 *
 * @param {boolean} lastColAlign - String containing the value of text-align to be applied to the last column header. Defaults to 'left'.
 *
 * @returns {JSX.Element}
 *
 * USAGE:
 *  - basic:
 *    const headers = ['Team', 'Matches Played', 'Wins', 'Draws', 'Losses', 'Points'];
 *    const rows = [
 *      ['Brasil', '3', '3', '0', '0', '9'],
 *      ['Argentina', '3', '2', '0', '1', '6'],
 *      ['Uruguai', '3', '1', '0', '2', '3'],
 *      ['Paraguai', '3', '0', '0', '3', '0'],
 *    ];
 *    const widths = ['20%', '16%', '16%', '16%', '16%', '16%'];
 *
 *    <MCardsTable headers={headers} rows={rows} widths={widths} />
 */

const MCardsTable = ({
  headers = [],
  rows = [],
  widths = [],
  maxHeight = '',
  cellPadding = '1rem 2rem',
  lastColAlign = 'left',
}) => {
  const classes = useStyles({ maxHeight });

  return (
    <Box className={classes.tableBox}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  key={index}
                  style={{
                    width: widths[index] ? widths[index] : `${100 / headers.length}%`,
                    minWidth: widths[index] ? widths[index] : `${100 / headers.length}%`,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    padding: cellPadding,
                    textAlign: index === headers.length - 1 ? lastColAlign : 'left',
                  }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={index}>
                {row.map((item, iIndex) => (
                  <TableCell
                    key={iIndex}
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      backgroundColor: index % 2 === 0 ? '#F8F8F8' : 'initial',
                      textAlign: iIndex === row.length - 1 ? lastColAlign : 'left',
                      padding: cellPadding,
                      width: widths[iIndex] ? widths[iIndex] : `${100 / row.length}%`,
                      minWidth: widths[iIndex] ? widths[iIndex] : `${100 / row.length}%`,
                    }}>
                    <Typography variant="body2" color="textSecondary" component="span">
                      {item}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!rows?.length && (
        <Box padding="1rem 2rem" style={{ boxShadow: '0px 0px 6px rgb(0 0 0 / 15%)', backgroundColor: '#F8F8F8' }}>
          <Typography variant="body2" color="textSecondary" style={{ fontStyle: 'italic' }} align="center">
            No data
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  tableBox: {
    background: theme.palette.common.white,
    borderRadius: '10px',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
    maxHeight: (props) => props.maxHeight,
    overflow: 'auto',
  },
}));

export default MCardsTable;
