import MCardsTextField from '../mcards/MCardsForm/MCardsTextField';
import { Grid } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { debounce } from 'lodash';

const SearchText = ({ onChange, placeholder = 'Search by name or keyword', searchBarXs = 8, debounceInMs = 500 }) => {
  const [keyword, setKeyword] = useState('');

  const onChangeDebounced = useCallback(
    debounce((newValue) => onChange({ search_text: newValue }), debounceInMs),
    [onChange],
  );

  const onKeywordChange = (newValue) => {
    setKeyword(newValue);
    onChangeDebounced(newValue);
  };

  return (
    <Grid item xs={searchBarXs}>
      <MCardsTextField
        type="search"
        name={placeholder}
        value={keyword}
        label={placeholder}
        onChange={(event) => onKeywordChange(event.target.value)}
        fullWidth
      />
    </Grid>
  );
};

export default SearchText;
