import React from 'react';
import validateEmail from 'helpers/validators/validateEmail';
import { Field } from 'react-final-form';
import MCardsTextField from 'components/mcards/MCardsForm/MCardsTextField';
import fetchFormError from 'helpers/validators/fetchFormError';

const EmailField = ({ name, label = 'Email', placeholder }) => {
  return (
    <Field name={name} type="email" validate={validateEmail}>
      {({ input, meta }) => (
        <MCardsTextField
          {...input}
          label={label}
          placeholder={placeholder}
          error={!!fetchFormError(meta)}
          helperText={fetchFormError(meta)}
          fullWidth
        />
      )}
    </Field>
  );
};

export default EmailField;
