import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const ActionButtonsContainer = ({ children }) => {
  const classes = useStyles();

  return <Box className={classes.ctaBtns}>{children}</Box>;
};

const useStyles = makeStyles((theme) => ({
  ctaBtns: {
    margin: theme.spacing(12, 0, 2),
    '& button': {
      margin: theme.spacing(0, 4),
      width: '10em',
    },
  },
}));

export default ActionButtonsContainer;
