import React, { useEffect, useState } from 'react';
import PageLoader from '../@jumbo/components/PageComponents/PageLoader';
import ErrorMessage from './ErrorMessage';

const GenericError = () => {
  return <ErrorMessage message="Error loading data. Try again later." />;
};

const RemoteData = ({ get, onload, children, errorComponent, loadingComponent, useEffectDeps }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);

  const getDataRemotely = () => {
    setIsLoading(true);

    get()
      .then(({ data }) => {
        setResult(data);

        if (typeof onload === 'function') onload(data);
      })
      .catch((error) => setError(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getDataRemotely();
  }, useEffectDeps);

  let componentToRender;

  if (isLoading) {
    componentToRender = loadingComponent;
  } else if (!!error) {
    componentToRender = errorComponent;
  } else {
    try {
      componentToRender = children(result);
    } catch (error) {
      console.error(error);
      componentToRender = errorComponent;
    }
  }

  return componentToRender;
};

RemoteData.defaultProps = {
  loadingComponent: <PageLoader />,
  errorComponent: <GenericError />,
  useEffectDeps: [],
  debounceTimeInMs: 0,
  onload: () => {},
};

export default RemoteData;
