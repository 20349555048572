import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCardholdersStatsAsync } from 'redux/reducers/distributor_api/cardholders/stats/stats.thunks';
import MCardsStatsGrid from '../MCardsStatsGrid';

const CardholdersStatistics = () => {
  const { isLoading, results } = useSelector((state) => state.cardholdersStats);
  const dispatch = useDispatch();
  const getAsyncFn = getCardholdersStatsAsync;

  useEffect(() => {
    if (!results && getAsyncFn) {
      getAsyncFn(dispatch);
    }
  }, [dispatch, results, getAsyncFn]);

  return <MCardsStatsGrid statistics={results} loading={isLoading} />;
};

export default CardholdersStatistics;
