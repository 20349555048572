import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@material-ui/core';

const ActionButtonsContainer = ({ children }) => {
  const classes = useStyles();

  return <Box className={classes.btnContainer}>{children}</Box>;
};

const useStyles = makeStyles((theme) => ({
  btnContainer: {
    margin: theme.spacing(12, 0, 2),
    '& button': {
      marginRight: theme.spacing(10),
      width: '8rem',
    },
  },
}));

export default ActionButtonsContainer;
