import React from 'react';
import { Box, Button, Grid, Hidden, Typography, Menu, MenuItem } from '@material-ui/core';
import CmtImage from '@coremat/CmtImage';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import RemoteData from 'components/RemoteData';
import RegionsService from 'services/distributor_api/regions.service';

/**
 * Presents a flag and the name of the region base on the region code.
 */
const RegionFlag = ({ regionCode }) => {
  const lowerCaseRegionCode = regionCode.toLowerCase();
  const upperCaseRegionCode = regionCode.toUpperCase();
  const classes = regionFlagStyles();
  const iconImage = `/images/regions/${lowerCaseRegionCode}.svg`;

  return (
    <Grid container direction="row" alignItems="center">
      <CmtImage src={iconImage} alt={upperCaseRegionCode} className={classes.regionIcon} />
      <Hidden xsDown>
        <Grid item>
          <Typography variant="subtitle1" className={classes.regionName}>
            <IntlMessages id={`component.region.${lowerCaseRegionCode}`} />
          </Typography>
        </Grid>
      </Hidden>
    </Grid>
  );
};

const regionFlagStyles = makeStyles((theme) => ({
  regionIcon: {
    width: '3em',
    alignItems: 'center',
    textAlign: 'center',
  },
  regionName: {
    color: theme.palette.text.primary,
    fontSize: 18,
    marginLeft: '0.5em',
    alignItems: 'center',
    textAlign: 'center',
  },
}));

/**
 * Presents a button with the flag of the current region and a menu with the flags of all regions.
 * When a region is selected, the user is redirected to the portal of that region.
 * @returns {JSX.Element}
 */
const Region = () => {
  const classes = useStyles();
  const [showMenu, setShowMenu] = React.useState(null);

  const onShowMenu = (event) => {
    setShowMenu(event.currentTarget);
  };

  const onHideMenu = () => {
    setShowMenu(null);
  };

  const navigateToRegion = (url) => {
    window.location.href = url;
  };

  return (
    <Box>
      <Button variant="outlined" className={classes.button} endIcon={<ArrowDropDownIcon />} onClick={onShowMenu}>
        <RegionFlag regionCode={(process.env.REACT_APP_REGION_CODE || 'au').toLowerCase()} />
      </Button>
      <RemoteData get={() => RegionsService.getAll([], 1)} errorComponent={null}>
        {(response) => (
          <Menu id="simple-menu" anchorEl={showMenu} keepMounted open={Boolean(showMenu)} onClose={onHideMenu}>
            {response?.map((region) => (
              <MenuItem key={region.code} onClick={() => navigateToRegion(region.portalUrl)}>
                <RegionFlag regionCode={region.code} />
              </MenuItem>
            ))}
          </Menu>
        )}
      </RemoteData>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  regionMenu: {
    border: '0px',
  },
  button: {
    border: '0px',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    height: '100%',
    minWidth: 100,
  },
}));

export default Region;
