import initialState from './locations.initialState';
import actionTypes from './locations.actionTypes';

const reducer = (state = initialState, { type, payload, currentLocationUuid }) => {
  switch (type) {
    case actionTypes.GET_ONE_START:
    case actionTypes.CREATE_START:
    case actionTypes.ADD_TERMINAL_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
        addedTerminal: null,
      };
    case actionTypes.ADD_TERMINAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addedTerminal: payload.terminals.at(-1),
        newLocation: null,
        location: payload,
      };
    case actionTypes.GET_ONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        newLocation: null,
        addedTerminal: null,
        location: payload,
      };
    case actionTypes.CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        newLocation: payload,
        addedTerminal: payload.terminals.at(-1),
      };
    case actionTypes.GET_ONE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
        location: null,
      };
    case actionTypes.CREATE_ERROR:
      return {
        ...state,
        isLoading: false,
        createError: payload,
      };
    case actionTypes.ADD_TERMINAL_ERROR:
      return {
        ...state,
        isLoading: false,
        updateError: payload,
      };
    //  STATS
    case actionTypes.GET_STATS_START:
      return {
        ...state,
        isLoadingStats: true,
        errorMessage: null,
      };
    case actionTypes.GET_STATS_SUCCESS:
      return {
        ...state,
        isLoadingStats: false,
        stats: payload,
        currentLocationUuid,
      };
    case actionTypes.GET_STATS_ERROR:
      return {
        ...state,
        isLoadingStats: false,
        errorMessage: payload,
      };
    default:
      return state;
  }
};

export default reducer;
