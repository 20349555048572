import React from 'react';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Box, Typography } from '@material-ui/core';
import CardholdersStatistics from 'components/mcards/Cardholders/CardholdersStatistics';
import CardActivities from 'components/mcards/Cardholders/CardActivities';

const HomePage = () => {
  return (
    <PageContainer
      heading={<IntlMessages id="pages.homePage.title" />}
      subtitle={<IntlMessages id="pages.homePage.subtitle" />}>
      <Box>
        <CardholdersStatistics />

        <Typography component="h2" variant="h2">
          <IntlMessages id="pages.homePage.activities.title" />
        </Typography>

        <CardActivities />
      </Box>
    </PageContainer>
  );
};

export default HomePage;
