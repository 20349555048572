import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import PropTypes from 'prop-types';

export const MCardsIconAndText = ({ icon, text }) => {
  const classes = iconAndText();
  const Icon = icon;

  return text ? (
    <>
      <Box display="flex" alignItems="center">
        {icon && (
          <Box className={classes.icon}>
            <Icon />
          </Box>
        )}
        <Typography component="h4" variant="h4">
          {text}
        </Typography>
      </Box>
    </>
  ) : null;
};

const iconAndText = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
    borderRadius: '100%',
    padding: '.25rem',
    width: '2rem',
    height: '2rem',
    backgroundColor: '#F5F6FE',
    marginRight: '1rem',
  },
}));

MCardsIconAndText.propTypes = {
  icon: PropTypes.elementType,
  text: PropTypes.string,
};

export const PhoneIconAndText = ({ phone }) => {
  return <MCardsIconAndText icon={PhoneIcon} text={phone} />;
};

PhoneIconAndText.propTypes = {
  phone: PropTypes.string,
};

export const EmailIconAndText = ({ email }) => {
  return <MCardsIconAndText icon={EmailIcon} text={email} />;
};

EmailIconAndText.propTypes = {
  email: PropTypes.string,
};
