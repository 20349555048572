import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { useIntl } from 'react-intl';
import MCardsTextField from '../MCardsForm/MCardsTextField';

const SearchBoxField = ({ onPlacesChanged, initialSearchText }) => {
  const intl = useIntl();
  const [searchBox, setSearchBox] = useState(null);
  const i18nLabelID = 'pages.manage.locations.new.page.map.input.placeholder';
  const [text, setText] = useState(initialSearchText);
  const onPlaceSelected = (value) => {
    setText(value);
    onPlacesChanged(searchBox.getPlaces());
  };

  return (
    <Field name="merchant_name_search">
      {(props) => (
        <div style={{ position: 'relative' }}>
          <StandaloneSearchBox
            onLoad={(ref) => setSearchBox(ref)}
            onPlacesChanged={() => onPlaceSelected(document.getElementsByName(props.input.name)[0].value)}>
            <MCardsTextField
              name={props.input.name}
              value={text}
              label={intl.formatMessage({ id: i18nLabelID })}
              onChange={(e) => setText(e.target.value)}
              fullWidth
            />
          </StandaloneSearchBox>
        </div>
      )}
    </Field>
  );
};

export default SearchBoxField;
