import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

/**
 * MCardsButton:
 * This is a custom build Button component specifically designed after mCards styling.
 * @param {JSX.Element} children
 * @param {string} color
 * @param {boolean} disabled
 * @param {func} onClick
 * @param {string} type
 * @param {string} variant
 * @param {string} size
 * @param className
 * @param {boolean} maxWidth
 * @param rest
 * @returns {JSX.Element}
 *
 * USAGE:
 *  - basic:
 *    <MCardsButton onClick={onButtonClick}>Confirm & Submit</MCardsButton>
 *
 *  - with extra style wrapped around the button text:
 *    <MCardsButton type="submit">
 *      <span className={classes.btnContent}>Next</span>
 *    </MCardsButton>
 */

const MCardsButton = ({ children, color, size, disabled, onClick, type, variant, className, maxWidth, ...rest }) => {
  const classes = useStyles({ maxWidth });

  return (
    <Button
      type={type}
      variant={variant}
      color={color}
      size={size}
      disabled={disabled}
      onClick={onClick}
      className={className}
      classes={{ root: classes.btn }}
      {...rest}>
      {children}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  btn: {
    borderRadius: '5px',
    padding: theme.spacing(1.75, 8),
    width: (props) => (props.maxWidth ? '100%' : 'auto'),
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
}));

MCardsButton.defaultProps = {
  type: 'submit',
  color: 'primary',
  variant: 'contained',
  size: 'medium',
  disabled: false,
};

MCardsButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  type: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'inherit']).isRequired,
  variant: PropTypes.string.isRequired,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default MCardsButton;
