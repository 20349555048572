import React from 'react';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Box } from '@material-ui/core';
import CardholdersStatistics from 'components/mcards/Cardholders/CardholdersStatistics';
import CardholdersList from 'components/mcards/Cardholders/CardholdersList';

const CardholderListPage = () => {
  return (
    <PageContainer
      heading={<IntlMessages id="pages.manage.cardholders.title" />}
      subtitle={<IntlMessages id="pages.manage.cardholders.subtitle" />}>
      <Box marginTop="3rem">
        <CardholdersStatistics />
        <CardholdersList />
      </Box>
    </PageContainer>
  );
};

export default CardholderListPage;
