import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const ErrorMessage = ({ message }) => {
  const classes = useStyles();

  return (
    <Box className={classes.error}>
      <Typography component="h5" variant="h5" gutterBottom align="center">
        {message}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  error: {
    backgroundColor: 'rgba(235, 8, 8, .05)',
    border: `1px solid ${theme.status.error}`,
    borderRadius: '10px',
    color: theme.status.error,
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
    width: '100%',
    '& h5': {
      fontWeight: theme.typography.fontWeightMedium,
      wordBreak: 'break-word',
    },
  },
}));

export default ErrorMessage;
