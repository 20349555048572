import React from 'react';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import { CloseOutlined } from '@material-ui/icons';

const TerminalDeleteCell = ({ terminal }) => {
  const classes = useStyles();

  return (
    <Box className={classes.deleteIcon}>
      <CloseOutlined fontSize="small" color="inherit" />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  deleteIcon: {
    alignItems: 'center',
    background: '#FFFFFF',
    borderRadius: '100%',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
    color: 'rgba(51, 51, 51, 0.54)',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '1rem',
    height: '30px',
    transition: 'all ease-in-out 0.25s',
    width: '30px',

    '&:hover': {
      boxShadow: '0px 0px 3px rgba(8, 38, 235, 0.9)',
      color: 'rgba(8, 38, 235, 0.8)',
      transition: 'all ease-in-out 0.25s',
    },
  },
}));

export default TerminalDeleteCell;
