import actionTypes from './carddesigns.actionTypes';

const carddesignLoadStart = () => ({
  type: actionTypes.CARDDESIGN_LOAD_START,
});

const carddesignLoadSuccess = (carddesign) => ({
  type: actionTypes.CARDDESIGN_LOAD_SUCCESS,
  payload: carddesign,
});

const carddesignLoadError = (errorMessage) => ({
  type: actionTypes.CARDDESIGN_LOAD_ERROR,
  payload: errorMessage,
});

export default {
  carddesignLoadStart,
  carddesignLoadSuccess,
  carddesignLoadError,
};
