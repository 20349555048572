import React from 'react';

const SketchBoard = () => {
  return (
    <>
      <h1>Draw it up, reload fast!</h1>
    </>
  );
};

export default SketchBoard;
