import React, { useEffect } from 'react';
import CmtImage from '@coremat/CmtImage';
import { Box, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { loadCardprogramsAsync } from 'redux/reducers/distributor_api/cardprograms/cardprograms.thunks';
import { loadCarddesignAsync } from 'redux/reducers/distributor_api/carddesigns/carddesigns.thunks';
import CreateCampaignForm from 'components/mcards/Campaigns/CreateCampaignForm';
import { Skeleton } from '@material-ui/lab';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '@jumbo/utils/IntlMessages';
import actions from 'redux/reducers/distributor_api/campaigns/campaigns.actions';
import makeStyles from '@material-ui/styles/makeStyles';

const CreateCampaign = () => {
  const classes = useStyles();

  // create campaign flow
  const { isLoading, cardprograms, errorMessage } = useSelector((state) => state.cardprograms);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.campaignRestartCreation());
    loadCardprogramsAsync(dispatch);
  }, [dispatch]);

  return isLoading ? (
    <PageLoader />
  ) : (
    cardprograms && (
      <PageContainer heading={<IntlMessages id="pages.campaignsPage.create.title" />}>
        <Box className={classes.container} display="flex" flexWrap="wrap" justifyContent="center">
          {isLoading && (
            <Box width="313px" margin="0px auto">
              <Skeleton variant="rect" className={classes.cardImage} />
              <Skeleton component="h1" />
            </Box>
          )}
          {errorMessage && <Typography>{errorMessage}</Typography>}
          {!isLoading && !cardprograms?.length && (
            <div style={{ margin: '0px auto' }}>
              <CmtImage src="/images/mcards/empty-card-list.png" alt="An image representing an empty card list" />
              <Typography>No Cards</Typography>
            </div>
          )}
          {!isLoading &&
            cardprograms?.length &&
            cardprograms.map((cardprogram) => (
              <div key={cardprogram.uuid}>
                {cardprogram && (
                  <div style={{ margin: '0px 20px' }}>
                    <CmtImage src={cardprogram.image_url} alt="The card design image" className={classes.cardImage} />
                    <Typography variant="h1" component="h1">
                      {cardprogram.title}
                    </Typography>
                  </div>
                )}
              </div>
            ))}
        </Box>

        <Box padding="4em 2em" style={{ position: 'relative' }}>
          <CreateCampaignForm />
        </Box>
      </PageContainer>
    )
  );
};

const useStyles = makeStyles(() => ({
  container: {
    boxShadow: '0px 0px 4.51245px rgba(0, 0, 0, 0.16)',
    borderRadius: '9.0249px',
    minWidth: '100%',
    padding: '32px 16px',
    textAlign: 'center',
  },
  cardImage: {
    borderRadius: '11px',
    filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25))',
    margin: '0 auto 1rem',
    minHeight: '198px',
    maxHeight: '198px',
  },
}));

export default CreateCampaign;
