import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import CountryCodeSelector from 'components/mcards/CountryCodeSelector';
import { useSelector } from 'react-redux';
import validatePresence from 'helpers/validators/validatePresence';

const CountryCodeField = ({ name, width }) => {
  const { currentAccessEntity } = useSelector((state) => state.accessEntities);
  const [optionsWithNoDuplicates, setOptionsWithNoDuplicates] = useState(null);

  useEffect(() => {
    if (!optionsWithNoDuplicates && currentAccessEntity?.allowed_recipient_countries) {
      const _optionsWithNoDuplicates = Object.values(
        currentAccessEntity.allowed_recipient_countries.reduce((countries, { country_code_number }) => {
          countries[country_code_number] = { country_code_number: country_code_number };
          return countries;
        }, {}),
      );

      setOptionsWithNoDuplicates(_optionsWithNoDuplicates);
    }
  }, [currentAccessEntity, optionsWithNoDuplicates]);

  return (
    optionsWithNoDuplicates && (
      <Field name={name} validate={validatePresence}>
        {({ input, meta }) => (
          <CountryCodeSelector input={input} meta={meta} countryOptions={optionsWithNoDuplicates} width={width} />
        )}
      </Field>
    )
  );
};

export default CountryCodeField;
