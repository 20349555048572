const actionTypesFor = (prefix) => {
  return {
    GET_ONE_START: `${prefix}.GET_ONE_START`,
    GET_ONE_SUCCESS: `${prefix}.GET_ONE_SUCCESS`,
    GET_ONE_ERROR: `${prefix}.GET_ONE_ERROR`,
    TOGGLE_CARD_START: `${prefix}.TOGGLE_CARD_START`,
    TOGGLE_CARD_SUCCESS: `${prefix}.TOGGLE_CARD_SUCCESS`,
    TOGGLE_CARD_ERROR: `${prefix}.TOGGLE_CARD_ERROR`,
  };
};

export default actionTypesFor('CARDHOLDER_ACTIVITIES');
