import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import RecipientsStep from './RecipientsStep';
import FormCarousel from '../../MCardsForm/FormCarousel';
import ScheduleStep from './ScheduleStep';
import ReviewStep from './ReviewStep';

const ManageRecipientsCarousel = ({ campaign }) => {
  const [currentCampaign, setCurrentCampaign] = useState(campaign);
  const [slider, setSlider] = useState(null);

  const onCampaignUpdated = (campaign, { preventTransition = false } = {}) => {
    setCurrentCampaign(campaign);
    if (!preventTransition && slider) slider.slickNext();
  };
  const goToPreviousStep = () => slider.slickPrev();

  const steps = [
    // step 1 - Basic details
    {
      intl_path: 'pages.manage.recipients.carousel.steps.recipients',
      stepIcons: {
        default: 'icon-step-advanced.png',
        active: 'icon-step-basic-details-active.png',
        complete: 'icon-step-basic-details-complete.png',
      },
      content: <RecipientsStep currentCampaign={currentCampaign} onCampaignUpdated={onCampaignUpdated} />,
    },
    // step 2 - schedule
    {
      intl_path: 'pages.manage.recipients.carousel.steps.schedule',
      hasSubtitle: true,
      stepIcons: {
        default: 'icon-step-schedule.png',
        active: 'icon-step-schedule-active.png',
        complete: 'icon-step-schedule-complete.png',
      },
      content: (
        <ScheduleStep
          currentCampaign={currentCampaign}
          onCampaignUpdated={onCampaignUpdated}
          onBackClick={goToPreviousStep}
        />
      ),
    },
    // step 3 - review
    {
      intl_path: 'pages.manage.recipients.carousel.steps.confirmation',
      hasSubtitle: true,
      stepIcons: {
        default: 'icon-step-review.png',
        active: 'icon-step-review-finished.png',
        complete: null,
      },
      content: <ReviewStep currentCampaign={currentCampaign} onBackClick={goToPreviousStep} />,
    },
  ];

  return (
    <Box className="container">
      <FormCarousel steps={steps} onSliderChange={setSlider} />
    </Box>
  );
};

export default ManageRecipientsCarousel;
