export default {
  // getAll
  CAMPAIGN_GET_ALL_START: 'CAMPAIGN_GET_ALL_START',
  CAMPAIGN_GET_ALL_SUCCESS: 'CAMPAIGN_GET_ALL_SUCCESS',
  CAMPAIGN_GET_ALL_ERROR: 'CAMPAIGN_GET_ALL_ERROR',
  // getOne
  CAMPAIGN_GET_ONE_START: 'CAMPAIGN_GET_ONE_START',
  CAMPAIGN_GET_ONE_SUCCESS: 'CAMPAIGN_GET_ONE_SUCCESS',
  CAMPAIGN_GET_ONE_ERROR: 'CAMPAIGN_GET_ONE_ERROR',
  // create
  CAMPAIGN_CREATE_START: 'CAMPAIGN_CREATE_START',
  CAMPAIGN_CREATE_SUCCESS: 'CAMPAIGN_CREATE_SUCCESS',
  CAMPAIGN_CREATE_ERROR: 'CAMPAIGN_CREATE_ERROR',
  // update
  CAMPAIGN_UPDATE_START: 'CAMPAIGN_UPDATE_START',
  CAMPAIGN_UPDATE_SUCCESS: 'CAMPAIGN_UPDATE_SUCCESS',
  CAMPAIGN_UPDATE_ERROR: 'CAMPAIGN_UPDATE_ERROR',
  // publish
  CAMPAIGN_PUBLISH_START: 'CAMPAIGN_PUBLISH_START',
  CAMPAIGN_PUBLISH_SUCCESS: 'CAMPAIGN_PUBLISH_SUCCESS',
  CAMPAIGN_PUBLISH_ERROR: 'CAMPAIGN_PUBLISH_ERROR',
  // restart
  CAMPAIGN_RESTART_CREATION: 'CAMPAIGN_RESTART_CREATION',
};
