import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '@jumbo/utils/IntlMessages';
import React from 'react';
import QuickSightDashboard from 'components/mcards/QuickSightDashboard';

const DashboardPage = () => {
  return (
    <PageContainer heading={<IntlMessages id="pages.dashboardPage.title" />}>
      <QuickSightDashboard />
    </PageContainer>
  );
};

export default DashboardPage;
