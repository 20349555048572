import React from 'react';
import { Box, Typography } from '@material-ui/core';
import CmtImage from '@coremat/CmtImage';
import IntlMessages from '@jumbo/utils/IntlMessages';
import makeStyles from '@material-ui/styles/makeStyles';
import PropTypes from 'prop-types';

const TerminalIdentifierCell = ({ terminal, showIcon = true }) => {
  const classes = useStyles();

  return (
    <Box component="span">
      {showIcon && (
        <Box component="span" className={classes.rowIconContainer}>
          <CmtImage src={`/images/icons/${!!terminal.card_acceptor_id ? 'circled-check.png' : 'question-mark.png'}`} />
        </Box>
      )}
      {terminal.card_acceptor_id ?? (
        <Typography component="span" variant="body2">
          <IntlMessages id="pages.manage.locations.components.terminals.unknownTerminal" />
        </Typography>
      )}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  rowIconContainer: {
    verticalAlign: 'middle',
    marginRight: '0.5rem',
  },
}));

TerminalIdentifierCell.defaultProps = {
  showIcon: true,
};

TerminalIdentifierCell.propTypes = {
  terminal: PropTypes.object,
  showIcon: PropTypes.bool,
};

export default TerminalIdentifierCell;
