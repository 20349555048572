import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import IntlMessages from '@jumbo/utils/IntlMessages';
import MCardsButton from 'components/mcards/MCardsForm/MCardsButton';
import TerminalVerificationModal from '../../TerminalsModal/TerminalVerificationModal';
import StatusLabel from '../../../../StatusLabel';

const getVerificationLabelText = (status) => (
  <IntlMessages id={`pages.manage.locations.components.terminals.${status}_label`} />
);

const TerminalStatusCell = ({ terminal, location, onTerminalUpdated }) => {
  const classes = useStyles();
  const status = terminal.verification_status;
  const colors = { verified: 'success', unverified: 'warning', auto_verified: 'danger' };

  const [verifyModalOpen, setVerifyModalOpen] = useState(false);
  const toggleVerifyModal = () => setVerifyModalOpen((prevState) => !prevState);

  return status === 'auto_verified' ? (
    <Box component="span">
      <MCardsButton size="small" className={classes.verifyBtn} onClick={toggleVerifyModal}>
        <IntlMessages id="pages.manage.locations.components.terminals.verifyButtonText" />
      </MCardsButton>

      {verifyModalOpen && (
        <TerminalVerificationModal
          location={location}
          terminal={terminal}
          isOpen={verifyModalOpen}
          onClose={toggleVerifyModal}
          onTerminalUpdated={onTerminalUpdated}
        />
      )}
    </Box>
  ) : (
    <StatusLabel state={colors[status]} text={getVerificationLabelText(status)} showDot={false} />
  );
};

const useStyles = makeStyles(() => ({
  verifyBtn: {
    borderRadius: '6px',
    padding: '4px 20px',
  },
}));

export default TerminalStatusCell;
