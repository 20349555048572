import React from 'react';
import { Box } from '@material-ui/core';
import CampaignStepsCarousel from './CampaignStepsCarousel';

const CreateCampaignForm = ({ isEdit }) => {
  return (
    <Box className="container">
      <CampaignStepsCarousel isEdit={isEdit} />
    </Box>
  );
};

export default CreateCampaignForm;
