import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import ActivityFeed from './ActivityFeed';

const LocationsListPage = () => {
  return (
    <PageContainer
      heading={<IntlMessages id="pages.manage.terminals.title" />}
      subtitle={<IntlMessages id="pages.manage.terminals.subtitle" />}>
      <ActivityFeed />
    </PageContainer>
  );
};

export default LocationsListPage;
