import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessEntitiesAsync } from 'redux/reducers/distributor_api/access_entities/access_entities.thunks';

const InitAccessEntitiesWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const { currentAccessEntity } = useSelector((state) => state.accessEntities);

  useEffect(() => {
    if (!currentAccessEntity) {
      getAccessEntitiesAsync(dispatch);
    }
  }, [dispatch, currentAccessEntity]);

  return <>{children}</>;
};

export default InitAccessEntitiesWrapper;
