import actionTypes from './campaigns.actionTypes';

const campaignGetAllStart = () => ({
  type: actionTypes.CAMPAIGN_GET_ALL_START,
});

const campaignGetAllSuccess = (data) => ({
  type: actionTypes.CAMPAIGN_GET_ALL_SUCCESS,
  payload: data,
});

const campaignGetAllError = (errorMessage) => ({
  type: actionTypes.CAMPAIGN_GET_ALL_ERROR,
  payload: errorMessage,
});

const campaignGetOneStart = () => ({
  type: actionTypes.CAMPAIGN_GET_ONE_START,
});

const campaignGetOneSuccess = (data) => ({
  type: actionTypes.CAMPAIGN_GET_ONE_SUCCESS,
  payload: data,
});

const campaignGetOneError = (errorMessage) => ({
  type: actionTypes.CAMPAIGN_GET_ONE_ERROR,
  payload: errorMessage,
});

const campaignCreateStart = (preventTransition) => ({
  type: actionTypes.CAMPAIGN_CREATE_START,
  preventTransition: preventTransition,
});

const campaignCreateSuccess = (campaign) => ({
  type: actionTypes.CAMPAIGN_CREATE_SUCCESS,
  payload: campaign,
});

const campaignCreateError = (errorMessage) => ({
  type: actionTypes.CAMPAIGN_CREATE_ERROR,
  payload: errorMessage,
});

const campaignUpdateStart = (preventTransition) => ({
  type: actionTypes.CAMPAIGN_UPDATE_START,
  preventTransition: preventTransition,
});

const campaignUpdateSuccess = (campaign) => ({
  type: actionTypes.CAMPAIGN_UPDATE_SUCCESS,
  payload: campaign,
});

const campaignUpdateError = (errorMessage, { csvErrors } = {}) => ({
  type: actionTypes.CAMPAIGN_UPDATE_ERROR,
  payload: errorMessage,
  csvErrors: csvErrors,
});

const campaignPublishStart = () => ({
  type: actionTypes.CAMPAIGN_PUBLISH_START,
});

const campaignPublishSuccess = (campaign) => ({
  type: actionTypes.CAMPAIGN_PUBLISH_SUCCESS,
  payload: campaign,
});

const campaignPublishError = (errorMessage) => ({
  type: actionTypes.CAMPAIGN_PUBLISH_ERROR,
  payload: errorMessage,
});

const campaignRestartCreation = () => ({
  type: actionTypes.CAMPAIGN_RESTART_CREATION,
});

export default {
  // getAll
  campaignGetAllStart,
  campaignGetAllSuccess,
  campaignGetAllError,
  // getOne
  campaignGetOneStart,
  campaignGetOneSuccess,
  campaignGetOneError,
  // create
  campaignCreateStart,
  campaignCreateSuccess,
  campaignCreateError,
  // update
  campaignUpdateStart,
  campaignUpdateSuccess,
  campaignUpdateError,
  // publish
  campaignPublishStart,
  campaignPublishSuccess,
  campaignPublishError,
  // restart
  campaignRestartCreation,
};
