import React from 'react';
import { Box, Typography } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import MCardsButton from '../MCardsForm/MCardsButton';
import makeStyles from '@material-ui/core/styles/makeStyles';

const SearchResultsList = ({
  containerStyle,
  onResultMouseOver,
  places,
  isLocationAdded,
  locationAddedHandler,
  multipleSelectionMode,
  removeLocationHandler,
}) => {
  const classes = useStyles();

  const locationSelectButtonLabelIntlKey = multipleSelectionMode
    ? 'component.maps.google.results.add'
    : 'component.maps.google.results.select';
  const locationDeselectButtonLabelIntlKey = multipleSelectionMode
    ? 'component.maps.google.results.remove'
    : 'component.maps.google.results.selected';

  const revertBackgroundStyle = (place) => {
    document.getElementById(`place-${place.place_id}`).style.backgroundColor = 'initial';
  };

  return (
    <Box width={containerStyle.width} maxHeight={containerStyle.height} overflow="auto">
      {places.map((place) => (
        <Box
          id={`place-${place.place_id}`}
          key={place.place_id}
          className={classes.placeContainer}
          onMouseOver={() => onResultMouseOver(place)}
          onMouseLeave={() => revertBackgroundStyle(place)}>
          <Box textAlign="left" paddingRight="1rem">
            <Typography variant="h4">{place.name}</Typography>
            <Typography variant="subtitle1" color="textSecondary" style={{ marginTop: '.5rem' }}>
              {place.formatted_address}
            </Typography>
          </Box>
          <Box>
            {!isLocationAdded(place) ? (
              <MCardsButton variant="outlined" color="primary" onClick={() => locationAddedHandler(place)} type="button">
                <IntlMessages id={locationSelectButtonLabelIntlKey} />
              </MCardsButton>
            ) : (
              <MCardsButton
                variant="contained"
                type="button"
                color="primary"
                onClick={() => removeLocationHandler(place)}
                style={{ minWidth: '6rem' }}>
                <IntlMessages id={locationDeselectButtonLabelIntlKey} />
              </MCardsButton>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  placeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1.5rem',
    borderBottom: '1px solid #E6E6E6',
  },
}));

export default SearchResultsList;
