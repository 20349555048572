const AUTH0_MOCKED_USER = {
  'http://emlpayments.com/eaid': [],
  'http://mcards.com/user': [
    'uuid: 6c767db0-e8d0-013a-9139-0242ac110003',
    'phone: ',
    'phone_verified: true',
    'tandc_accepted: true',
    'tandc_url: https://staging.mcards.com/tandc/legal_notices',
    'card_linking_enabled: false',
    'transaction_tracking_enabled: true',
  ],
  nickname: 'mockednickname',
  name: 'mocked@paywith.com',
  picture:
    'https://s.gravatar.com/avatar/03ca3c4db920240ce8bba351e9e257ab?s=480&r=pg&d=https%3A%2F%2Fmcards-public.s3.ap-southeast-2.amazonaws.com%2Fauth0%2Fdefault_user_picture.png',
  updated_at: '2022-12-05T22:18:30.383Z',
  email: 'employee@paywith.com',
  email_verified: false,
  sub: 'auth0|62d568569d0ccae334eb3942',
};

export default AUTH0_MOCKED_USER;
