import React, { useContext, useMemo } from 'react';

import { IntlProvider } from 'react-intl';
import MomentUtils from '@date-io/moment';
import { create } from 'jss';
import rtl from 'jss-rtl';

import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createTheme, jssPreset, StylesProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import AppContext from '../contextProvider/AppContextProvider/AppContext';
import AppLocale from '../../../i18n';
import AppLayout from '../AppLayout';
import SketchBoard from '../../../SketchBoard';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const AppWrapper = ({ children }) => {
  const {
    locale: { locale },
    theme,
  } = useContext(AppContext);

  const muiTheme = useMemo(() => createTheme(theme), [theme]);

  const appContent = process.env.REACT_APP_SKETCH_MODE === 'true' ? <SketchBoard /> : children;

  return (
    <IntlProvider locale={AppLocale[locale].locale} messages={AppLocale[locale].messages} onError={console.warn}>
      <ThemeProvider theme={muiTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <StylesProvider jss={jss}>
            <CssBaseline />
            <AppLayout>{appContent}</AppLayout>
          </StylesProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default AppWrapper;
