import React, { useState } from 'react';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '@jumbo/utils/IntlMessages';
import LocationPageHeading from 'components/mcards/Locations/LocationPageHeading';
import { Avatar, Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { Form } from 'react-final-form';
import makeStyles from '@material-ui/styles/makeStyles';
import { GoogleMapSingleLocationSelector } from 'components/mcards/GoogleMapLocationSelector';
import CmtImage from '@coremat/CmtImage';
import AddTerminalForm from './AddTerminalForm';
import useSearchParams from 'components/useSearchParams';
import TerminalsList from 'components/mcards/Terminals/TerminalsList';

const LocationCreatePage = () => {
  const searchParams = useSearchParams();
  const merchantTradeName = searchParams['merchant_trade_name'];
  const cardAcceptorId = searchParams['card_acceptor_id'];
  const [locationsFound, setLocationsFound] = useState([]);
  const [chosenLocation, setChosenLocation] = useState(null);

  const onSubmit = () => null;
  const suppressEnter = (e) => e.key === 'Enter' && e.preventDefault();

  return (
    <PageContainer
      heading={<LocationPageHeading intlTitleId="pages.manage.locations.new.page.title" />}
      subtitle={<IntlMessages id="pages.manage.locations.new.page.subtitle" />}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} onKeyPress={suppressEnter}>
            <GoogleMapSingleLocationSelector
              onLocationSelect={setChosenLocation}
              setLocationsFound={setLocationsFound}
              initialSearchText={merchantTradeName}
            />
          </form>
        )}
      />
      {locationsFound.length > 0 && (
        <RenderGooglePlaceDetails
          place={chosenLocation}
          merchantTradeName={merchantTradeName}
          cardAcceptorId={cardAcceptorId}
        />
      )}
    </PageContainer>
  );
};

export const RenderGooglePlaceDetails = ({ place, merchantTradeName, cardAcceptorId }) => {
  const classes = useStyles();

  return (
    <Box className={classes.detailsWrapper}>
      <Typography component="h1" variant="h1" align="center">
        <IntlMessages id="pages.manage.locations.new.details.title" />
      </Typography>

      <Typography variant="subtitle1" color="textSecondary" align="center">
        {!place && <IntlMessages id="pages.manage.locations.new.details.unselected" />}
      </Typography>

      {place && (
        <>
          <Box className={classes.contentWrapper}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Card className={classes.contentCards} classes={{ root: classes.withRightBorder }}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <Avatar aria-label="icon" className={classes.avatar}>
                          <CmtImage src="/images/mcards/icon-location.png" />
                        </Avatar>
                      </Grid>

                      <Grid item xs={10}>
                        <Typography component="h1" variant="h1" gutterBottom classes={{ gutterBottom: classes.titleGutter }}>
                          {place.name}
                        </Typography>

                        <Typography variant="body2">{place.formatted_address}</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={6}>
                <Card className={classes.contentCards}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <CmtImage src="/images/icons/google-maps-api-icon.png" />
                      </Grid>

                      <Grid item xs={10}>
                        <Typography component="h4" variant="h4" gutterBottom classes={{ gutterBottom: classes.titleGutter }}>
                          Google Places ID
                        </Typography>

                        <Typography variant="body1" color="textSecondary">
                          {place.place_id}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.contentWrapper}>
            <Box mb={10}>
              <AddTerminalForm
                place={place}
                suggestedMerchantTradeName={merchantTradeName}
                suggestedCardAcceptorId={cardAcceptorId}
              />
            </Box>

            <Box>
              <TerminalsList locationUuid={place.place_id} googlePlace={true} />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  detailsWrapper: {
    background: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
    margin: theme.spacing(8, 0),
    padding: theme.spacing(8),
    '& > :nth-child(2)': {
      margin: theme.spacing(4, 0, 8),
    },
  },
  contentWrapper: {
    background: '#FFFFFF',
    borderRadius: '8.09045px',
    boxShadow: '0px 0px 4.85427px rgba(0, 0, 0, 0.15)',
    margin: theme.spacing(8, 0),
    padding: theme.spacing(10),
  },
  contentCards: {
    background: 'none',
    boxShadow: 'none',
    borderRadius: 0,
  },
  withRightBorder: {
    borderRight: '1px solid #EFEFEF',
  },
  avatar: {
    background: 'rgba(8, 38, 235, 0.04)',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
  },
  titleGutter: {
    marginBottom: '1rem',
  },
  iconTitleRoot: {
    alignItems: 'baseline',
    display: 'flex',
    marginBottom: theme.spacing(4),
    '& .MuiAvatar-root': {
      margin: theme.spacing(0, 4, 0, 0),
    },
  },
}));

export default LocationCreatePage;
