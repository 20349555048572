import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';
import IntlMessages from '../../../../../../@jumbo/utils/IntlMessages';
import { useIntl } from 'react-intl';

const intlPath = 'pages.manage.campaigns.carousel.steps.confirmation.labels';

const ReviewDetailRow = ({ labelIntl, value }) => {
  const classes = useStyles();
  return (
    <Box className={classes.reviewDetailRow}>
      <Box display="flex" flex={1}>
        <Typography component="span">
          <IntlMessages id={`${intlPath}.${labelIntl}`} />
        </Typography>
      </Box>
      <Box display="flex" flex={3}>
        <Typography component="span">{value}</Typography>
      </Box>
    </Box>
  );
};

const CampaignReviewSummary = ({ name, restrictionsCount }) => {
  const classes = useStyles();
  const intl = useIntl();

  const restrictionsMessage = () => {
    let descriptor = { id: `${intlPath}.restrictions.none` };
    let values = {};

    if (restrictionsCount > 0) {
      descriptor = { id: `${intlPath}.restrictions.some` };
      values = { restrictionsCount: restrictionsCount };
    }

    return intl.formatMessage(descriptor, values);
  };

  return (
    <Box className={classes.reviewContainer}>
      <Typography align="left" variant="h3">
        <IntlMessages id={`${intlPath}.title`} />
      </Typography>
      <Box className={classes.reviewDetails}>
        <ReviewDetailRow value={name} labelIntl="name" />
        <ReviewDetailRow value={restrictionsMessage()} labelIntl="restrictions" />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  reviewContainer: {
    border: '1px solid #EBEBEB',
    borderRadius: 4,
    margin: theme.spacing(10, 20),
    padding: theme.spacing(6, 14),
  },
  reviewImageContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    flex: '2',
    marginRight: '10rem',
  },
  reviewDetailRow: {
    display: 'flex',
    flex: 1,
    textAlign: 'left',
  },
  reviewDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4, 16),
  },
  campaignIcon: {
    width: '28px',
    height: '28px',
    margin: '0 0.5rem',
  },
}));

export default CampaignReviewSummary;
