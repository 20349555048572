import React, { useEffect } from 'react';
import MCardsStatsGrid from '../../MCardsStatsGrid';
import { useDispatch, useSelector } from 'react-redux';
import { getStats } from 'redux/reducers/distributor_api/locations/locations.thunks';

const LocationsStats = ({ locationUuid = null }) => {
  const { isLoadingStats, stats } = useSelector((state) => state.locations);
  const { currentLocationUuid } = useSelector((state) => state.locations);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!stats || locationUuid !== currentLocationUuid) {
      getStats(dispatch, locationUuid);
    }
  }, [dispatch, currentLocationUuid, stats]);

  return <MCardsStatsGrid statistics={stats} loading={isLoadingStats} skeletons={3} />;
};

export default LocationsStats;
