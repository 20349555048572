import StyledPagination from './StyledPagination';
import React from 'react';

const ListWithPagination = ({ ListComponent, list, pagination }) => {
  return (
    <>
      <ListComponent currentList={list} />
      <StyledPagination {...pagination} />
    </>
  );
};

export default ListWithPagination;
