import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOne } from 'redux/reducers/distributor_api/locations/locations.thunks';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import TerminalsTable from './TerminalsTable';
import DetailSectionHeader from 'components/mcards/DetailSectionHeader';
import { Skeleton } from '@material-ui/lab';

const TerminalsList = ({ locationUuid, googlePlace }) => {
  const [timeOfRender, setTimeOfRender] = useState(new Date().getTime());
  const { isLoading, location } = useSelector((state) => state.locations);
  const dispatch = useDispatch();
  const classes = styles();

  useEffect(() => {
    if (!location || location.uuid !== locationUuid || timeOfRender) {
      const identifierType = googlePlace ? 'google_place_id' : null;
      getOne(dispatch, locationUuid, identifierType);
    }
  }, [dispatch, locationUuid, timeOfRender]);

  const reloadList = () => setTimeOfRender(new Date().getTime());

  return (
    <Box>
      <DetailSectionHeader
        imgSrc="/images/icons/terminal.png"
        messageIntlID="pages.manage.locations.components.terminals.headerText"
      />
      <Box className={classes.terminalsTableContainer}>
        {isLoading ? (
          <Skeleton variant="rect" height={40} width={'100%'} classes={{ rect: classes.rectSkeleton }} />
        ) : (
          <TerminalsTable terminals={location?.terminals} location={location} onTerminalUpdated={reloadList} />
        )}
      </Box>
    </Box>
  );
};

const styles = makeStyles(() => ({
  terminalsTableContainer: {
    margin: '2rem',
  },
  rectSkeleton: {
    borderRadius: '10px',
  },
}));

export default TerminalsList;
