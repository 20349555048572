import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import ReviewStep from './ReviewStep';
import { useDispatch, useSelector } from 'react-redux';
import LocationsStep from './LocationsStep';
import BasicDetailsStep from './BasicDetailsStep';
import WizardCompletedModal from './ReviewStep/WizardCompletedModal';
import actions from 'redux/reducers/distributor_api/campaigns/campaigns.actions';
import { useHistory } from 'react-router';
import FormCarousel from '../../../MCardsForm/FormCarousel';

const CampaignStepsCarousel = ({ isEdit = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [slider, setSlider] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const { isCreating, isUpdating, currentCampaign, preventTransition, isPublishing } = useSelector(
    (state) => state.campaigns,
  );

  const campaignIsUnpublished = () => currentCampaign?.status !== 'published';

  useEffect(() => {
    if (!preventTransition && slider) {
      if (campaignIsUnpublished() && !(isCreating || isUpdating)) slider.slickNext();
      if (!currentCampaign) slider.slickGoTo(0);
    }
  }, [currentCampaign]);

  useEffect(() => {
    setOpenDialog(!isPublishing && currentCampaign?.status === 'published');
  }, [isPublishing, currentCampaign]);

  const onComplete = () => {
    setOpenDialog(false);
    history.push(`/reports/campaigns/${currentCampaign?.uuid}`);
  };

  const onClose = () => {
    setOpenDialog(false);
    dispatch(actions.campaignRestartCreation());
  };

  const steps = [
    // step 1 - Basic details
    {
      intl_path: 'pages.manage.campaigns.carousel.steps.basic_details',
      stepIcons: {
        default: 'icon-step-advanced.png',
        active: 'icon-step-basic-details-active.png',
        complete: 'icon-step-basic-details-complete.png',
      },
      content: <BasicDetailsStep />,
    },
    // step 2 - locations restrictions
    {
      intl_path: 'pages.manage.campaigns.carousel.steps.restrictions',
      stepIcons: {
        default: 'icon-step-locations.png',
        active: 'icon-step-locations-active.png',
        complete: 'icon-step-locations-complete.png',
      },
      content: <LocationsStep isEdit={isEdit} />,
    },
    // step 3 - review / publish
    {
      intl_path: 'pages.manage.campaigns.carousel.steps.confirmation',
      hasSubtitle: true,
      stepIcons: {
        default: 'icon-step-review.png',
        active: 'icon-step-review-active.png',
        complete: null,
      },
      content: <ReviewStep isEdit={isEdit} />,
    },
  ];

  return (
    <Box className="container">
      <FormCarousel steps={steps} onSliderChange={setSlider} />

      <WizardCompletedModal
        isEdit={isEdit}
        openModal={openDialog}
        onComplete={onComplete}
        onClose={onClose}
        mainImage={currentCampaign?.image_url}
      />
    </Box>
  );
};

export default CampaignStepsCarousel;
