import { Box, Typography } from '@material-ui/core';
import CmtImage from '../../../../../../@coremat/CmtImage';
import React from 'react';

const LocationSummary = ({ name, fullAddress, latitude, longitude }) => {
  const eastOrWest = longitude < 0 ? 'W' : 'E';
  const northOrSouth = latitude < 0 ? 'S' : 'N';
  const formattedLat = Math.abs(latitude).toFixed(2);
  const formattedLng = Math.abs(longitude).toFixed(2);
  const coordinates = `${formattedLng}°${eastOrWest} ${formattedLat}°${northOrSouth}`;

  return (
    <Box
      display="flex"
      alignItems="center"
      boxShadow="0px 0px 4px rgb(0 0 0 / 17%)"
      borderRadius="10px"
      padding="0.5rem 1rem"
      width="calc(100% - 48px)">
      <CmtImage src="/images/mcards/icon-location.png" style={{ marginRight: '1rem' }} />
      <Typography
        variant="body1"
        style={{
          width: '33%',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          paddingRight: '1rem',
        }}>
        {name}
      </Typography>
      <Typography
        variant="body1"
        style={{
          width: '33%',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          paddingRight: '1rem',
        }}>
        {fullAddress}
      </Typography>
      <Typography
        variant="body1"
        style={{
          width: '33%',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          paddingRight: '1rem',
        }}>
        {coordinates}
      </Typography>
    </Box>
  );
};

export default LocationSummary;
