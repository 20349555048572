import React from 'react';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import IntlMessages from '@jumbo/utils/IntlMessages';
import MCardsArrowBack from '../MCardsArrowBack';

const LocationPageHeading = ({ intlTitleId = '' }) => {
  const history = useHistory();

  return (
    <>
      <MCardsArrowBack onClick={() => history.push('/reports/locations')} />
      {intlTitleId && (
        <Box mt={4}>
          <IntlMessages id={intlTitleId} />
        </Box>
      )}
    </>
  );
};

export default LocationPageHeading;
