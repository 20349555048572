import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

const styles = (theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '9px',
    padding: theme.spacing(4),
  },
  content: {
    padding: '1rem',
  },
});

const MUIModal = ({ size, fullWidth, classes, modalOpen, onClose, children }) => {
  useEffect(() => {
    const closeOnEsc = (event) => onclose && event.key === 'Escape' && onClose(null);

    document.addEventListener('keydown', closeOnEsc);

    return () => document.removeEventListener('keydown', closeOnEsc);
  }, [onClose]);

  return (
    <Dialog
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={modalOpen}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={size}
      classes={{ paper: classes.paper }}>
      <div className={classes.content}>{children}</div>
    </Dialog>
  );
};

MUIModal.propTypes = {
  size: PropTypes.oneOf(['xl', 'lg', 'md', 'sm', 'xs', false]).isRequired,
  classes: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
};

/**
 * PWModal:
 * This is a modal based on Material UI modal.
 * It requires a flag to display or hide itself, along with a function to be executed when the modal is closed.
 * The modal content must be a {JSX.Element} defined by the caller at the moment of usage.
 * @param {JSX.Element} children
 * @param {boolean} modalOpen
 * @param {func} onClose
 * @param {string | boolean} size
 * @param {boolean} fullWidth
 *
 * @returns {JSX.Element}
 *
 * USAGE:
 *  - basic:
 *     <PWModal size="xs" modalOpen={isOpen} onClose={onCancel}>
 *       <Typography align="center" variant="h1">
 *         This will be displayed inside the modal
 *       </Typography>
 *     </PWModal>
 *
 */
export default withStyles(styles)(MUIModal);
