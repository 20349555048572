import actionTypes from './access_entities.actionTypes';
import initialState from './access_entities.initialState';

const accessEnitiesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.ACCESS_ENTITIES_GET_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case actionTypes.ACCESS_ENTITIES_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        accessEntities: payload,
        // we initialize the currentAccessEntity with
        // the first access entity returned from back-end
        // the user will be able to change the current access entity
        // when we support multiple
        currentAccessEntity: payload['access_entities'][0],
      };
    case actionTypes.ACCESS_ENTITIES_GET_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    default:
      return state;
  }
};

export default accessEnitiesReducer;
