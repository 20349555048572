import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  pageHeaderRoot: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'row',
    },
  },
  titleRoot: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
    },
  },
  subtitleRoot: {
    marginTop: theme.spacing(2),
  },
  ctaButtonsContainer: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
  },
}));

const PageHeader = ({ heading, subtitle, ctaButtons, breadcrumbComponent, children, ...rest }) => {
  const classes = useStyles();

  return (
    <Box className="page-header" mb={{ xs: 5, md: 6, lg: 8 }} {...rest}>
      <Box className={classes.pageHeaderRoot}>
        <Typography component="h1" variant="h1" className={clsx(classes.titleRoot, 'title')}>
          {heading}
        </Typography>
        {breadcrumbComponent && <Box ml={{ sm: 'auto' }}>{breadcrumbComponent}</Box>}
        {ctaButtons?.length > 0 && (
          <Box className={classes.ctaButtonsContainer}>
            {ctaButtons.map((ctaButton, index) => (
              <Box display="flex" key={`cta-button-${index}`}>
                {ctaButton}
              </Box>
            ))}
          </Box>
        )}
      </Box>

      {subtitle && (
        <Typography variant="subtitle1" color="textSecondary" className={clsx(classes.subtitleRoot, 'subtitle')}>
          {subtitle}
        </Typography>
      )}

      {children}
    </Box>
  );
};

export default PageHeader;
