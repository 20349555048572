import { Card, CardMedia, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { CampaignStatus } from './CampaignList/CampaignRow';
import { formatDateRelative } from 'helpers/dates';

const CampaignHeading = ({ currentCampaign }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardMedia className={classes.cardImage} image={currentCampaign.image_url} title={currentCampaign.name} />
      <CardContent className={classes.cardContent}>
        <Typography component="h1" variant="h1" style={{ marginBottom: '1rem' }}>
          {currentCampaign.name}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Created: {formatDateRelative(currentCampaign.created_at)}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          <span>Status: </span>
          <CampaignStatus campaign={currentCampaign} showDot={false} />
        </Typography>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: 'none',
    display: 'flex',
  },
  cardContent: {
    textAlign: 'left',
  },
  cardImage: {
    backgroundColor: 'lightgrey',
    borderRadius: '1.25rem',
    height: 100,
    width: 100,
  },
  cardName: {
    marginBottom: '1rem',
  },
}));

export default CampaignHeading;
