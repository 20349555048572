import axios from 'axios';
import { auth0Client } from 'services/auth/auth0';

const AwsApiGateway = async () => {
  const auth0 = await auth0Client;
  const token = await auth0.getTokenSilently();

  return axios.create({
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    baseURL: process.env.REACT_APP_AWS_API_GATEWAY_URL,
    responseType: 'json',
  });
};

export default AwsApiGateway;
