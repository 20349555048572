import React from 'react';
import CmtImage from '../@coremat/CmtImage';
import { useIntl } from 'react-intl';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import WhiteTooltip from './tooltips/WhiteTooltip';

const InfoTooltip = ({ i18nMessageKey, placement }) => {
  const intl = useIntl();
  const message = intl.formatMessage({ id: i18nMessageKey });

  if (message === i18nMessageKey) return <></>;

  return (
    <WhiteTooltip title={<Typography>{message}</Typography>} placement={placement}>
      <Box component="span">
        <CmtImage src="/images/icons/info.png" />
      </Box>
    </WhiteTooltip>
  );
};

InfoTooltip.propTypes = {
  placement: PropTypes.oneOf([
    'bottom-end',
    'bottom-start',
    'bottom',
    'left-end',
    'left-start',
    'left',
    'right-end',
    'right-start',
    'right',
    'top-end',
    'top-start',
    'top',
  ]),
  i18nMessageKey: PropTypes.string.isRequired,
};

InfoTooltip.defaultProps = {
  placement: 'right',
};

/**
 * InfoTooltip:
 * Displays an info icon and, when hovered, shows a tooltip
 * containing the i18n text for the given i18n key.
 *
 * In case the given i18n key has no corresponding translation,
 * this component shall render an empty React Fragment.
 * @param {string} i18nMessageKey
 * @param {string} placement
 *
 * @returns {JSX.Element}
 *
 * USAGE:
 *
 *  - Using default(right) placement:
 *
 *    <InfoTooltip i18nMessageKey={`messages.statistics.total_spent`} /
 *
 *  - Specifying tooltip placement:
 *
 *    <InfoTooltip i18nMessageKey={`messages.statistics.total_spent`} placement="left" />
 *
 */
export default InfoTooltip;
