const actionTypesFor = (prefix) => {
  return {
    // update
    UPDATE_START: `${prefix}.UPDATE_START`,
    UPDATE_SUCCESS: `${prefix}.UPDATE_SUCCESS`,
    UPDATE_ERROR: `${prefix}.UPDATE_ERROR`,

    // delete
    DELETE_SUCCESS: `${prefix}.DELETE_SUCCESS`,
    DELETE_ERROR: `${prefix}.DELETE_ERROR`,
  };
};

export default actionTypesFor('RECIPIENT');
