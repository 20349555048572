import React from 'react';
import { HighlightOff } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

const RemoveRecipientButton = ({ items, index, onItemRemove }) => {
  const onlyOneRecipientRemaining = (items) => {
    return items.value.filter((item) => !item || item['_destroy'] !== '1').length === 1;
  };

  const removeRecipient = () => {
    if (items.value[index]['uuid']) {
      onItemRemove({ ...items.value[index], _destroy: '1' });
    }
    items.remove(index);
  };

  return (
    <IconButton style={{ width: '56px' }} disabled={onlyOneRecipientRemaining(items)} onClick={removeRecipient}>
      <HighlightOff />
    </IconButton>
  );
};

export default RemoveRecipientButton;
