import React from 'react';
import MCardsMaskedInput from 'components/mcards/MCardsForm/MCardsMaskedInput';
import { Field } from 'react-final-form';
import validatePresence from 'helpers/validators/validatePresence';
import MCardsTextField from 'components/mcards/MCardsForm/MCardsTextField';
import fetchFormError from 'helpers/validators/fetchFormError';

const AmountField = ({ name, label = 'Amount' }) => {
  return (
    <Field name={name} type="currency" validate={validatePresence}>
      {({ input, meta }) => (
        <MCardsTextField
          {...input}
          label={label}
          autoFocus={true}
          error={!!fetchFormError(meta)}
          helperText={fetchFormError(meta)}
          InputProps={{
            inputComponent: MCardsMaskedInput,
            value: input.value,
            onChange: input.onChange,
            inputProps: {
              fieldType: 'currency',
            },
          }}
          fullWidth
        />
      )}
    </Field>
  );
};

export default AmountField;
