import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';

const ReviewSummary = ({ title, rows, summaryActions }) => {
  const classes = useStyles();

  return (
    <Box className={classes.reviewContainer}>
      <Box className={classes.detailsContainer}>
        <Typography align="left" variant="h3">
          {title}
        </Typography>
        <Box className={classes.reviewDetails}>{rows}</Box>
      </Box>
      {summaryActions && <Box className={classes.summaryActionsContainer}>{summaryActions}</Box>}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  reviewContainer: {
    border: '1px solid #EBEBEB',
    borderRadius: 4,
    margin: theme.spacing(10, 20),
    padding: theme.spacing(6, 7),
    minHeight: '20rem',
    display: 'flex',
    flexDirection: 'column',
  },
  detailsContainer: {
    // margin: theme.spacing(10, 20),
    padding: theme.spacing(0, 7),
  },
  summaryActionsContainer: {
    display: 'flex',
    marginTop: 'auto',
  },
  reviewImageContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    flex: '2',
    marginRight: '10rem',
  },
  reviewDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4, 16),
  },
  campaignIcon: {
    width: '28px',
    height: '28px',
    margin: '0 0.5rem',
  },
}));

ReviewSummary.defaultProps = {
  rows: [],
  title: '',
};

export default ReviewSummary;
