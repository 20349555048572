import React from 'react';
import { TextField } from '@material-ui/core';
import { Field } from 'react-final-form';

const HiddenField = ({ name, initialValue }) => {
  return (
    <Field name={name} type="hidden" initialValue={initialValue}>
      {({ input }) => <TextField {...input} hidden />}
    </Field>
  );
};

export default HiddenField;
