import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import StatusLabel from '../../StatusLabel';
import dollars from 'helpers/dollars';
import { getFormattedDate } from '@jumbo/utils/dateHelper';

export const CardJoinedDate = ({ card }) => getFormattedDate(card.created_at, 'DD MMM YYYY');

export const CardStatus = ({ card }) => {
  const status = card.status;
  const labels = {
    pending: { text: <IntlMessages id="pages.manage.cards.status.pending" />, state: 'pending' },
    active: { text: <IntlMessages id="pages.manage.cards.status.active" />, state: 'success' },
    inactive: { text: <IntlMessages id="pages.manage.cards.status.inactive" />, state: 'warning' },
    link_failed: { text: <IntlMessages id="pages.manage.cards.status.link_failed" />, state: 'error' },
    issuer_error: { text: <IntlMessages id="pages.manage.cards.status.issuer_error" />, state: 'error' },
  };

  return <StatusLabel state={labels[status].state} text={labels[status].text} />;
};

export const CardTransactions = ({ card }) => card.total_transactions;

export const CardUuid = ({ card }) => card.uuid;

export const CardTotalSpent = ({ card }) => dollars(card.total_spent, 0);
