import { Skeleton } from '@material-ui/lab';
import React from 'react';

const Loading = () => {
  return [1, 2, 3].map((number) => (
    <Skeleton
      key={number}
      variant="rect"
      width={'100%'}
      height={40}
      style={{ borderRadius: '5px', marginTop: '1rem', boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1)' }}
    />
  ));
};

export default Loading;
