import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const WhiteTooltip = withStyles({
  tooltip: {
    background: '#ffffff',
    color: 'black',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    position: 'relative',
    textAlign: 'center',
    height: '100%',
  },
})(Tooltip);

export default WhiteTooltip;
