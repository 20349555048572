import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '@coremat/CmtImage';
import clsx from 'clsx';

const MCardsModalTitleIcon = ({ src, alt = '', className }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.iconWrapper, className)}>
      <CmtImage src={src} alt={alt} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: '60px',
    width: '60px',
  },
}));

MCardsModalTitleIcon.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default MCardsModalTitleIcon;
