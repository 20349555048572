import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Dashboard from './Dashboard';
import TaskList from './TaskList';
import MailApp from './MailApp';
import Chat from './Chat';
import ContactApp from './ContactApp';
import ProfileApp from './ProfileApp';
import WallApp from './WallApp';
import Auth from './Auth';
import Users from './Users';
import cardprogramsReducer from './distributor_api/cardprograms/cardprograms.reducer';
import carddesignsReducer from './distributor_api/carddesigns/carddesigns.reducer';
import campaignsReducer from './distributor_api/campaigns/campaigns.reducer';
import cardholdersReducer from './distributor_api/cardholders/cardholders.reducer';
import cardholdersStatsReducer from './distributor_api/cardholders/stats/stats.reducer';
import accessEntitiesReducer from './distributor_api/access_entities/access_entities.reducer';
import locationsReducer from './distributor_api/locations/locations.reducer';
import recipientsReducer from './distributor_api/recipients/recipients.reducer';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    taskList: TaskList,
    dashboard: Dashboard,
    mailApp: MailApp,
    chat: Chat,
    auth: Auth,
    contactApp: ContactApp,
    profileApp: ProfileApp,
    wallApp: WallApp,
    usersReducer: Users,
    cardprograms: cardprogramsReducer,
    carddesigns: carddesignsReducer,
    campaigns: campaignsReducer,
    cardholders: cardholdersReducer,
    cardholdersStats: cardholdersStatsReducer,
    accessEntities: accessEntitiesReducer,
    locations: locationsReducer,
    recipients: recipientsReducer,
  });
