import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import AmountField from '../../../../MCardsForm/fields/AmountField';
import PhoneField from '../../../../MCardsForm/fields/PhoneField';
import EmailField from '../../../../MCardsForm/fields/EmailField';
import RemoveRecipientButton from './RemoveRecipientButton';
import CountryCodeField from '../../../../MCardsForm/fields/CountryCodeField';
import HiddenField from '../../../../MCardsForm/fields/HiddenField';
import SimpleTextField from '../../../../MCardsForm/fields/SimpleTextField';
import MCardsButton from '../../../../MCardsForm/MCardsButton';
import IntlMessages from '../../../../../../@jumbo/utils/IntlMessages';

const RecipientsListForm = ({ onRecipientRemove, pushFn }) => {
  const classes = useStyles();

  return (
    <Box className={classes.formContainer}>
      <FieldArray name="items_attributes">
        {({ fields }) =>
          fields.map((name, index) => (
            <Box key={name} className={classes.rowContainer}>
              <Box className={classes.visibleFieldContainer}>
                <AmountField name={`${name}[balances_attributes][0][value]`} />
              </Box>
              <Box className={classes.visibleFieldContainer}>
                <SimpleTextField name={`${name}[first_name]`} label="First name" />
              </Box>
              <Box className={classes.visibleFieldContainer}>
                <SimpleTextField name={`${name}[last_name]`} label="Last name" />
              </Box>
              <Box className={classes.visibleFieldContainer}>
                <CountryCodeField name={`${name}[phone_country_code]`} />
              </Box>
              <Box className={classes.visibleFieldContainer}>
                <PhoneField name={`${name}[phone_without_country_code]`} />
              </Box>
              <Box className={classes.visibleFieldContainer}>
                <EmailField name={`${name}[email]`} />
              </Box>
              <Box className={classes.visibleFieldContainer}>
                <SimpleTextField name={`${name}[message]`} label="Message" validator={null} />
              </Box>
              <HiddenField name={`${name}[_destroy]`} />
              <HiddenField name={`${name}[uuid]`} />
              <HiddenField name={`${name}[balances_attributes][0][type]`} initialValue="mcash_balance" />
              <RemoveRecipientButton items={fields} index={index} onItemRemove={onRecipientRemove} />
            </Box>
          ))
        }
      </FieldArray>

      <Box textAlign="left" marginTop="1rem">
        <MCardsButton
          variant="text"
          type="button"
          style={{ padding: 'inherit' }}
          onClick={() => pushFn('items_attributes', undefined)}>
          <IntlMessages id="pages.manage.recipients.carousel.steps.recipients.add_recipient_button" />
        </MCardsButton>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  formContainer: {
    maxHeight: '400px',
    overflowY: 'auto',
  },
  rowContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    marginTop: '1rem',
  },
  visibleFieldContainer: {
    position: 'relative',
    marginRight: '9px',
  },
}));
export default RecipientsListForm;
