import React from 'react';
import SearchableList from 'components/SearchableList';
import MCardsTable from '../../MCardsTable';
import { CampaignImage, CampaignName, CampaignStatus, Scheduled, TotalValueDelivered } from './CampaignRow';
import IntlMessages from '@jumbo/utils/IntlMessages';
import DatePeriod from 'components/filters/DatePeriod';
import SearchText from 'components/filters/SearchText';
import service from 'services/distributor_api/campaigns.service';

const CampaignRows = ({ currentList }) => {
  const headers = ['name', 'cards_delivered', 'total_value_delivered', 'scheduled', 'status'];

  return (
    <>
      <MCardsTable
        headers={[''].concat(
          headers.map((header) => <IntlMessages id={`pages.manage.campaigns.list.table.col.${header}`} />),
        )}
        rows={currentList?.map((campaign) => {
          return [
            <CampaignImage imageUrl={campaign.image_url} />,
            <CampaignName name={campaign.name} uuid={campaign.uuid} />,
            campaign.cards_delivered_count,
            <TotalValueDelivered amount={campaign.cards_delivered_amount} />,
            <Scheduled deliveryOptions={campaign.delivery_options} />,
            campaign.status && <CampaignStatus campaign={campaign} />,
          ];
        })}
        widths={['10%', '30%', '15%', '17%', '17%', '11%']}
        cellPadding="1rem"
      />
    </>
  );
};

const CampaignList = () => (
  <>
    <SearchableList
      getAll={service.getAll}
      ListComponent={CampaignRows}
      filters={[<SearchText />, <DatePeriod />]}
      countHeadingTitle={<IntlMessages id="pages.manage.campaigns.menuTitle" />}
    />
  </>
);

export default CampaignList;
