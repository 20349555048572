import React from 'react';
import { useParams } from 'react-router';
import RemoteData from '../../../../components/RemoteData';
import CampaignsService from '../../../../services/distributor_api/campaigns.service';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';
import CampaignHeading from '../../../../components/mcards/Campaigns/CampaignHeading';
import { Box } from '@material-ui/core';
import ManageRecipientsCarousel from '../../../../components/mcards/Campaigns/ManageRecipientsCarousel';

const ManageRecipientsPage = () => {
  const { campaign_uuid } = useParams();

  return (
    <RemoteData get={() => CampaignsService.getByUuid(campaign_uuid)}>
      {(campaign) => (
        <PageContainer heading={<CampaignHeading currentCampaign={campaign} />}>
          <Box>
            <ManageRecipientsCarousel campaign={campaign} />
          </Box>
        </PageContainer>
      )}
    </RemoteData>
  );
};

export default ManageRecipientsPage;
