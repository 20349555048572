import actionTypes from './cardprograms.actionTypes';
import initialState from './cardprograms.initialState';

const cardprogramsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.CARDPROGRAMS_LOAD_START:
      return {
        ...state,
        isLoading: true,
        cardprograms: null,
        errorMessage: null,
      };
    case actionTypes.CARDPROGRAMS_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cardprograms: payload,
      };
    case actionTypes.CARDPROGRAMS_LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    case actionTypes.CARDPROGRAM_LOAD_START:
      return {
        ...state,
        isLoading: true,
        currentCardprogram: null,
        errorMessage: null,
      };
    case actionTypes.CARDPROGRAM_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentCardprogram: payload,
      };
    case actionTypes.CARDPROGRAM_LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    default:
      return state;
  }
};

export default cardprogramsReducer;
