import React from 'react';
import CmtImage from '@coremat/CmtImage';
import { Button } from '@material-ui/core';

const StepsIcon = ({ stepIcons, currentStep, index, onClick, ...props }) => {
  const stepStatus = currentStep < index ? 'default' : currentStep === index ? 'active' : 'complete';

  const isComplete = stepStatus === 'complete';

  return (
    <li
      key={index}
      className={props.className}
      style={{ width: '4rem', height: '4rem', position: 'relative', marginLeft: '1.25rem', cursor: 'initial' }}>
      {index > 0 && (
        <div
          style={{
            backgroundColor: '#F0F0F0',
            height: '2px',
            width: '1.75rem',
            position: 'absolute',
            top: '50%',
            right: '3.85rem',
            borderRadius: '50%',
          }}
        />
      )}

      <Button onClick={onClick} style={{ height: '64px', borderRadius: '50%' }} disabled={!isComplete}>
        <CmtImage src={`/images/mcards/${stepIcons[stepStatus]}`} />
      </Button>
    </li>
  );
};

export default StepsIcon;
