import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';
import HomePage from './Pages/HomePage';
import DashboardPage from './Pages/DashboardPage';
import { useAuth0 } from '@auth0/auth0-react';
import PageLoader from '@jumbo/components/PageComponents/PageLoader';
import MyCampaigns from './Pages/MyCampaigns';
import CreateCampaign from './Pages/Campaigns/CreateCampaign';
import CampaignDetailsPage from './Pages/MyCampaigns/CampaignDetailsPage';
import EditCampaign from './Pages/Campaigns/EditCampaign';
import CardholderListPage from './Pages/Cardholders/CardholderListPage';
import CardholderDetailsPage from './Pages/Cardholders/CardholderDetailsPage';
import LocationsListPage from './Pages/Locations/LocationsListPage';
import LocationDetailsPage from './Pages/Locations/LocationDetailsPage';
import LocationCreatePage from './Pages/Locations/LocationCreatePage';
import TerminalsListPage from './Pages/Terminals/TerminalsListPage';
import ManageRecipientsPage from './Pages/Campaigns/ManageRecipients';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { user, isLoading, isAuthenticated } = useAuth0();

  if (isLoading && !isAuthenticated) {
    return <PageLoader />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              search: props.location.search,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const location = useLocation();
  const { user, isLoading, isAuthenticated } = useAuth0();

  if (isLoading && !isAuthenticated) {
    return <PageLoader />;
  }

  if (user && ['', '/signin'].includes(location.pathname)) {
    return <Redirect to={{ pathname: '/', search: location.search }} />;
  } else if (location.pathname === '') {
    return <Redirect to={{ pathname: '/', search: location.search }} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <RestrictedRoute path="/" exact component={HomePage} />
        <RestrictedRoute path="/dashboard" component={DashboardPage} />
        <RestrictedRoute path="/campaigns/new" component={CreateCampaign} />
        <RestrictedRoute path="/campaigns/:campaign_uuid/edit" component={EditCampaign} />
        <RestrictedRoute path="/campaigns/:campaign_uuid/recipients" component={ManageRecipientsPage} />
        <RestrictedRoute path="/locations/new" component={LocationCreatePage} />
        <RestrictedRoute path="/reports/campaigns/:campaign_uuid" component={CampaignDetailsPage} />
        <RestrictedRoute path="/reports/campaigns" component={MyCampaigns} />
        <RestrictedRoute path="/reports/cardholders/:cardholder_uuid" component={CardholderDetailsPage} />
        <RestrictedRoute path="/reports/cardholders" component={CardholderListPage} />
        <RestrictedRoute path="/reports/locations/:location_uuid" component={LocationDetailsPage} />
        <RestrictedRoute path="/reports/locations" component={LocationsListPage} />
        <RestrictedRoute path="/reports/terminals" component={TerminalsListPage} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
