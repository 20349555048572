import React from 'react';
import PWModal from '../PWModal';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import MCardsButton from './MCardsForm/MCardsButton';
import MCardsModalTitleIcon from './MCardsModalTitleIcon';
import IntlMessages from '../../@jumbo/utils/IntlMessages';

const GenericErrorModal = ({
  errorMessage = <IntlMessages id="genericErrorModal.defaultMessage" />,
  isOpen = true,
  onClose,
}) => {
  const classes = useStyles();

  const [showError, setShowError] = React.useState(isOpen);
  if (!onClose) {
    onClose = () => setShowError(false);
  }

  return (
    <PWModal size="xs" modalOpen={showError}>
      <div className={classes.modalContainer}>
        <MCardsModalTitleIcon src="/images/icons/warning-outline.png" />

        <Typography align="center" variant="h1" className={classes.title}>
          Error!
        </Typography>

        <Typography variant="subtitle1" color="textSecondary" align="center">
          {errorMessage}
        </Typography>

        <MCardsButton type="button" onClick={onClose} className={classes.actionBtn}>
          Done
        </MCardsButton>
      </div>
    </PWModal>
  );
};

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    padding: '1rem',
  },
  title: {
    margin: '2rem 0',
    fontSize: '28px',
  },
  actionBtn: {
    marginTop: theme.spacing(6),
    paddingLeft: theme.spacing(13),
    paddingRight: theme.spacing(13),
  },
}));

export default GenericErrorModal;
