import React from 'react';
import { Box } from '@material-ui/core';
import service from 'services/distributor_api/locations.service';
import SearchText from '../../../filters/SearchText';
import DatePeriod from '../../../filters/DatePeriod';
import SearchableList from '../../../SearchableList';
import { useIntl } from 'react-intl';
import MCardsTable from '../../MCardsTable';
import LocationsRow from './LocationsRow';
import IntlMessages from '@jumbo/utils/IntlMessages';

const LocationsList = () => {
  const Intl = useIntl();
  const headers = ['location', 'address', 'phone', 'spend_to_date', 'terminals', 'campaigns'];

  return (
    <Box>
      <SearchableList
        getAll={service.getAll}
        ListComponent={({ currentList }) => (
          <MCardsTable
            headers={headers.map((colName) => (
              <IntlMessages id={`pages.manage.locations.list.table.col.${colName}`} />
            ))}
            rows={currentList?.map(LocationsRow)}
            cellPadding={'1rem 1.5rem'}
            widths={['20%', '25%', '15%', '15%', '15%', '10%']}
          />
        )}
        filters={[
          <SearchText
            placeholder={Intl.formatMessage({ id: 'pages.manage.locations.search.placeholder' })}
            searchBarXs={8}
          />,
          <DatePeriod />,
        ]}
      />
    </Box>
  );
};

export default LocationsList;
