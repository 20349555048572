import React from 'react';
import { Field } from 'react-final-form';
import MCardsTextField from 'components/mcards/MCardsForm/MCardsTextField';
import fetchFormError from 'helpers/validators/fetchFormError';

const MultilineField = ({ name, label, minRows = 1, placeholder, validator = null }) => {
  return (
    <Field name={name} validate={validator}>
      {({ input, meta }) => (
        <MCardsTextField
          {...input}
          label={label}
          placeholder={placeholder}
          error={!!fetchFormError(meta)}
          helperText={fetchFormError(meta)}
          minRows={minRows}
          multiline
          fullWidth
        />
      )}
    </Field>
  );
};

export default MultilineField;
