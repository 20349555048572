import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { Avatar, Grid } from '@material-ui/core';
import MCardsButton from 'components/mcards/MCardsForm/MCardsButton';
import makeStyles from '@material-ui/styles/makeStyles';
import { create, addTerminal } from 'redux/reducers/distributor_api/locations/locations.thunks';
import MCardsTextField from 'components/mcards/MCardsForm/MCardsTextField';
import GenericErrorModal from 'components/mcards/GenericErrorModal';
import ValidateSuccessModal from './TerminalsModal/ValidateSuccessModal';
import Box from '@material-ui/core/Box';
import CmtImage from '@coremat/CmtImage';
import PropTypes from 'prop-types';

const someEmpty = (...fields) => fields.some((field) => !field);

const AddTerminalForm = ({ suggestedMerchantTradeName, suggestedCardAcceptorId, place, existingLocation }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const redirectToDetails = () => {
    history.push(`/reports/locations/${newLocation?.uuid}`);
  };

  const closeSuccessModal = () => setSuccessModalOpened(false);

  const onValidateSuccess = existingLocation ? closeSuccessModal : redirectToDetails;

  const { isLoading, newLocation, createError, addedTerminal, addedTerminalError } = useSelector((state) => state.locations);

  const [successModalOpened, setSuccessModalOpened] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setSuccessModalOpened(!!(addedTerminal || newLocation));
    setError(!!(createError || addedTerminalError));
  }, [newLocation, createError, addedTerminal, addedTerminalError]);

  const initialFormState = {
    terminalId: suggestedCardAcceptorId ?? '',
    merchantTradeName: suggestedMerchantTradeName ?? '',
  };

  const anyFieldIsEmpty = (values) => someEmpty(values.terminalId, values.merchantTradeName);

  const terminalsAttributesFor = (values) => [
    { card_acceptor_id: values.terminalId, merchant_trade_name: values.merchantTradeName },
  ];

  const createLocationAndTerminal = (values) => {
    const addressAttributes = {
      long_address: place.formatted_address,
      google_place_id: place.place_id,
    };
    const terminalsAttributes = terminalsAttributesFor(values);

    create(dispatch, place.name, addressAttributes, terminalsAttributes);
  };

  const createTerminalForExistingLocation = (values) => {
    addTerminal(dispatch, existingLocation.uuid, terminalsAttributesFor(values));
  };

  const validateTerminal = (values, form) => {
    existingLocation ? createTerminalForExistingLocation(values) : createLocationAndTerminal(values);
    form.reset();
  };

  const onSubmit = (form) => console.log(form);

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={initialFormState}
        render={({ handleSubmit, values, form }) => (
          <form onSubmit={handleSubmit} noValidate className={classes.form}>
            <Box className={classes.gridRow}>
              <Box className={classes.addIconSpace}>
                <Avatar aria-label="icon" className={classes.avatar}>
                  <CmtImage src={'/images/icons/plus-icon.png'} />
                </Avatar>
              </Box>
              <Grid container spacing={2} alignItems="center" className={classes.gridRowContent}>
                <Grid item xs={3}>
                  <Field id="terminalId" name="terminalId">
                    {({ input }) => <MCardsTextField {...input} fullWidth />}
                  </Field>
                </Grid>

                <Grid item xs={4}>
                  <Field id="merchantTradeName" name="merchantTradeName">
                    {({ input }) => <MCardsTextField {...input} fullWidth />}
                  </Field>
                </Grid>

                <Grid item xs={2}>
                  {/* filling for missing status field */}
                </Grid>

                <Grid item xs={3}>
                  <MCardsButton
                    type="button"
                    size="small"
                    disabled={isLoading || anyFieldIsEmpty(values)}
                    onClick={() => validateTerminal(values, form)}>
                    Verify
                  </MCardsButton>
                </Grid>
              </Grid>
              <Box className={classes.deleteButtonSpace}>{/* filling for missing delete button */}</Box>
            </Box>
          </form>
        )}
      />

      <ValidateSuccessModal
        location={newLocation ?? existingLocation}
        isOpen={successModalOpened}
        terminal={addedTerminal}
        onClose={onValidateSuccess}
      />

      <GenericErrorModal isOpen={error} onClose={() => setError(false)} />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  form: {},
  addIconSpace: {
    width: '50px',
  },
  gridRow: {
    alignItems: 'center',
    display: 'flex',
  },
  gridRowContent: {
    padding: theme.spacing(0, 2),
  },
  avatar: {
    background: 'rgba(8, 38, 235, 0.04)',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
  },
  deleteButtonSpace: {
    marginLeft: '1rem',
    height: '30px',
    width: '30px',
  },
  btn: {
    width: '100%',
  },
}));

AddTerminalForm.propTypes = {
  suggestedMerchantTradeName: PropTypes.string,
  suggestedCardAcceptorId: PropTypes.string,
  place: PropTypes.object,
  existingLocation: PropTypes.object,
};

export default AddTerminalForm;
