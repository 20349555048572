import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import TerminalsModal, { DetailsText, DetailsTitle } from './index';
import CmtImage from '@coremat/CmtImage';
import TerminalsService from 'services/distributor_api/terminals.service';

const TerminalVerificationModal = ({ isOpen, onClose, location, terminal, onTerminalUpdated }) => {
  const intl = useIntl();
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const verifyTerminal = () => {
    let responseErrorMessage;
    setIsUpdating(true);
    setErrorMessage(null);

    TerminalsService.update(terminal.uuid, { terminal: { location_auto_associated: false } })
      .then(() => onClose({ success: true }))
      .catch(({ response }) => {
        if (response?.status === 403) {
          responseErrorMessage = intl.formatMessage({ id: 'messages.generic.forbidden' });
        } else if (response?.data?.errors[0].detail) {
          responseErrorMessage = response.data?.errors[0].detail;
        } else {
          responseErrorMessage = intl.formatMessage({ id: 'messages.generic.fallbackError' });
        }
      })
      .finally(() => {
        if (!!responseErrorMessage) {
          setIsUpdating(false);
          setErrorMessage(responseErrorMessage);
        } else {
          onTerminalUpdated();
        }
      });
  };

  const type = 'verification';
  const googleAddress = location?.address?.long_address;
  const googlePlaceId = location?.address?.google_place_id;
  const buttons = [
    { id: 'back', type: 'button', color: 'default', onClick: onClose, isDisabled: false },
    {
      id: isUpdating ? 'busy_verifying' : 'confirm',
      type: 'button',
      color: 'primary',
      onClick: verifyTerminal,
      isDisabled: isUpdating,
    },
  ];

  return (
    <TerminalsModal
      type={type}
      isOpen={isOpen}
      location={location}
      terminal={terminal}
      buttons={buttons}
      errorMessage={errorMessage}>
      <>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <DetailsTitle id={`${type}.field_terminal_id`} />
            <DetailsText>{terminal?.card_acceptor_id}</DetailsText>
          </Grid>

          <Grid item xs={6}>
            <DetailsTitle id={`${type}.field_merchant_trade_name`} />
            <DetailsText>{terminal?.merchant_trade_name}</DetailsText>
          </Grid>
        </Grid>

        <Box mt={4}>
          <DetailsTitle id={`${type}.field_location`} />

          <Grid container spacing={4}>
            <Grid item xs={2}>
              {googlePlaceId && (
                <Link href={`https://www.google.com/maps/place?q=place_id:${googlePlaceId}`} target="_blank">
                  <CmtImage src="/images/icons/google-maps-api-icon.png" />
                </Link>
              )}
            </Grid>
            <Grid item xs={10}>
              {googleAddress && (
                <>
                  <Typography component="h6" variant="h6" gutterBottom style={{ color: '#333' }}>
                    {location?.name}
                  </Typography>
                  <DetailsText>{googleAddress}</DetailsText>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </>
    </TerminalsModal>
  );
};

export default TerminalVerificationModal;
