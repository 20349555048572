import actionTypes from './stats.actionTypes';
import initialState from './stats.initialState';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_ALL_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case actionTypes.GET_ALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        results: payload,
      };
    case actionTypes.GET_ALL_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: payload,
      };
    default:
      return state;
  }
};
