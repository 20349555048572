import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const AmountChip = ({ item }) => {
  const getTransactionType = (item) => {
    let transactionType = 'transacted';
    if (item.formatted_total_amount.startsWith('+$')) {
      transactionType = 'pointsExchanged';
    } else if (item.formatted_total_amount.startsWith('$')) {
      transactionType = 'transacted';
    } else if (item.formatted_total_amount.endsWith('pts')) {
      transactionType = 'pointsEarned';
    }
    return transactionType;
  };

  const amountColorConfig = {
    pointsExchanged: {
      foreground: '#FFFFFF',
      background: '#19C283',
    },
    transacted: {
      foreground: '#0826EB',
      background: '#ECEEFF',
    },
    pointsEarned: {
      foreground: '#FFFFFF',
      background: '#0826EB',
    },
  };
  const amountColors = amountColorConfig[getTransactionType(item)];
  const classes = useChipStyles({ amountColors });

  return <Chip size="small" label={item.formatted_total_amount} component="span" classes={{ root: classes.chipRoot }} />;
};

const useChipStyles = makeStyles((theme) => ({
  chipRoot: {
    color: (props) => props.amountColors.foreground,
    background: (props) => props.amountColors.background,
    fontWeight: theme.typography.fontWeightBold,
    height: '26px',
  },
}));

export default AmountChip;
