import React, { useState } from 'react';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, makeStyles } from '@material-ui/core';
import CampaignReport from 'components/mcards/Campaigns/CampaignReport';
import { useHistory, useParams } from 'react-router';
import MCardsButton from '../../../../components/mcards/MCardsForm/MCardsButton';
import CampaignHeading from '../../../../components/mcards/Campaigns/CampaignHeading';
import CampaignsService from 'services/distributor_api/campaigns.service';
import RemoteData from '../../../../components/RemoteData';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';

const CampaignCTAButton = ({ currentCampaign, routeSuffix, intlSuffix }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <MCardsButton
      className={classes.ctaButton}
      type="button"
      onClick={() => history.push(`/campaigns/${currentCampaign.uuid}/${routeSuffix}`)}
      disabled={currentCampaign.status === 'deleted'}>
      <IntlMessages id={`pages.manage.campaigns.buttons.${intlSuffix}`} />
    </MCardsButton>
  );
};

const EditCampaignButton = ({ currentCampaign }) => (
  <CampaignCTAButton currentCampaign={currentCampaign} routeSuffix="edit" intlSuffix="edit" />
);

const ManageRecipientsButton = ({ currentCampaign }) => (
  <CampaignCTAButton currentCampaign={currentCampaign} routeSuffix="recipients" intlSuffix="manageRecipients" />
);

const CampaignDetailsPage = () => {
  const { campaign_uuid } = useParams();
  const [timeOfRender, setTimeOfRender] = useState(new Date().getTime());

  const onReload = () => setTimeOfRender(new Date().getTime());

  return (
    <RemoteData get={() => CampaignsService.getByUuid(campaign_uuid)} useEffectDeps={[timeOfRender]}>
      {(campaign) => (
        <PageContainer
          ctaButtons={[
            <ManageRecipientsButton currentCampaign={campaign} />,
            <EditCampaignButton currentCampaign={campaign} />,
          ]}
          heading={<CampaignHeading currentCampaign={campaign} />}>
          <Box>
            <CampaignReport campaign={campaign} onReload={onReload} />
          </Box>
        </PageContainer>
      )}
    </RemoteData>
  );
};

const useStyles = makeStyles(() => ({
  ctaButton: {
    minWidth: '12rem',
  },
}));

export default CampaignDetailsPage;
