import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import ErrorMessage from '../../../../ErrorMessage';
import PageLoader from '../../../../../@jumbo/components/PageComponents/PageLoader';
import CampaignsService from '../../../../../services/distributor_api/campaigns.service';
import { consolidateGenericErrorMessages } from '../../../../../helpers/response';
import IntlMessages from '../../../../../@jumbo/utils/IntlMessages';
import SingleDateForm from './SingleDateForm';
import moment from 'moment/moment';
import MultipleDatesForm from './MultipleDatesForm';
import InstantForm from './InstantForm';
import { notDeliveredNorCancelled } from '../../../../../helpers/campaign';
import ActionButtonsContainer from '../ActionButtons/ActionButtonsContainer';
import BackButton from '../ActionButtons/BackButton';
import ReviewRecipientsModal from './ReviewRecipientsModal';
import MCardsButton from '../../../MCardsForm/MCardsButton';
import { useFormState } from 'react-final-form';
import { useIntl } from 'react-intl';

const ScheduleStep = ({ currentCampaign, onBackClick, onCampaignUpdated }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedMode, setSelectedMode] = useState('scheduled_all_together');
  const [deliverableItems, setDeliverableItems] = useState([]);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [currentDeliveryOptions, setCurrentDeliveryOptions] = useState({});
  const [currentFormValues, setCurrentFormValues] = useState({});

  const NextButton = () => {
    const { values, valid } = useFormState();
    const intl = useIntl();

    const buildDeliveryOptionsMap = () => {
      let deliveryOptions;

      if (selectedMode === 'scheduled_separate') {
        deliveryOptions = values.delivery_dates.reduce((acc, item) => {
          acc[item.uuid] = mergeDateAndTime(item.date, item.time);
          return acc;
        }, {});
      } else if (selectedMode === 'scheduled_all_together') {
        deliveryOptions = {
          default: mergeDateAndTime(values.delivery_date, values.delivery_time),
        };
      } else if (selectedMode === 'instant') {
        deliveryOptions = {
          default: intl.formatMessage({
            id: 'pages.manage.recipients.carousel.steps.schedule.confirmation.instant_delivery',
          }),
        };
      }

      return deliveryOptions;
    };

    const openReviewModal = () => {
      if (valid) {
        setCurrentDeliveryOptions(buildDeliveryOptionsMap());
        setCurrentFormValues(values);
        setConfirmationModalOpen(true);
      }
    };

    return (
      <MCardsButton type="submit" disabled={isUpdating} onClick={openReviewModal}>
        <IntlMessages id="pages.manage.campaigns.carousel.buttons.next" />
      </MCardsButton>
    );
  };

  useEffect(() => {
    setDeliverableItems(currentCampaign.items.filter(notDeliveredNorCancelled));
  }, [currentCampaign]);

  useEffect(() => {
    let initialMode = 'scheduled_all_together';

    if (allDeliverableItemsAreInstantDeliveries()) {
      initialMode = 'instant';
    } else if (differentDeliveryDatesExist()) {
      initialMode = 'scheduled_separate';
    }

    setSelectedMode(initialMode);
  }, [deliverableItems]);

  const toDeliveryDate = ({ delivery_options: { delivery_date } }) => moment(delivery_date).format('YYYY-MM-DD HH');
  const allDeliverableItemsAreInstantDeliveries = () =>
    deliverableItems.every((item) => item.delivery_options.delivery_type === 'instant');
  const differentDeliveryDatesExist = () => {
    const uniqueDeliveryDates = [...new Set(deliverableItems.map(toDeliveryDate))];
    return uniqueDeliveryDates.length > 1;
  };

  const mergeDateAndTime = (date, time) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const formattedTime = moment(time).format('HH:mm');
    return moment(`${formattedDate} ${formattedTime}`);
  };

  const formatDatetime = (date, time, format) => {
    return mergeDateAndTime(date, time).format(format);
  };

  const itemsWithScheduledDeliveryOptions = () => {
    const deliveryDates =
      currentFormValues.delivery_dates ??
      Array(deliverableItems.length).fill({
        date: currentFormValues.delivery_date,
        time: currentFormValues.delivery_time,
      });

    return deliverableItems.map((item, index) => {
      const formattedDeliveryDate = formatDatetime(deliveryDates[index].date, deliveryDates[index].time);

      return {
        uuid: item.uuid,
        delivery_options_attributes: {
          delivery_date: formattedDeliveryDate,
          delivery_type: 'scheduled',
        },
      };
    });
  };

  const itemsWithInstantDeliveryOptions = () => {
    return deliverableItems.map((item) => {
      return {
        uuid: item.uuid,
        delivery_options_attributes: {
          delivery_date: null,
          delivery_type: 'instant',
        },
      };
    });
  };

  const onSubmit = () => {
    const campaign = {
      items_attributes: selectedMode === 'instant' ? itemsWithInstantDeliveryOptions() : itemsWithScheduledDeliveryOptions(),
    };
    setIsUpdating(true);
    setErrorMessage('');
    setConfirmationModalOpen(false);
    CampaignsService.update(currentCampaign['uuid'], campaign)
      .then(({ data }) => onCampaignUpdated(data))
      .catch((error) => setErrorMessage(consolidateGenericErrorMessages(error)))
      .finally(() => setIsUpdating(false));
  };

  const deliveryOptions = ['instant', 'scheduled_all_together', 'scheduled_separate'];
  const deliveryOptionToForm = {
    instant: InstantForm,
    scheduled_all_together: SingleDateForm,
    scheduled_separate: MultipleDatesForm,
  };
  const DateForm = deliveryOptionToForm[selectedMode];

  const i18nPath = 'pages.manage.recipients.carousel.steps.schedule';
  const classes = useStyles();

  return (
    <Box position="relative">
      {errorMessage && <ErrorMessage message={errorMessage} />}
      {isUpdating && <PageLoader />}

      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="details"
        value={selectedMode}
        className={classes.deliveryOptions}>
        {deliveryOptions.map((deliveryOption) => (
          <FormControlLabel
            value={deliveryOption}
            key={`delivery-option-${deliveryOption}`}
            control={<Radio color="primary" />}
            label={<IntlMessages id={`${i18nPath}.delivery_options.${deliveryOption}`} />}
            onChange={() => setSelectedMode(deliveryOption)}
          />
        ))}
      </RadioGroup>

      <DateForm onSubmit={onSubmit} deliverableItems={deliverableItems}>
        {confirmationModalOpen && (
          <ReviewRecipientsModal
            onClose={() => setConfirmationModalOpen(false)}
            titleI18N={`${i18nPath}.confirmation.title`}
            subtitleI18N={`${i18nPath}.confirmation.subtitle`}
            isOpen={confirmationModalOpen}
            campaign={currentCampaign}
            onConfirm={onSubmit}
            showBackButton={true}
            buttonI18N="pages.manage.campaigns.carousel.buttons.confirm"
            currentDeliveryOptions={currentDeliveryOptions}
          />
        )}

        <ActionButtonsContainer>
          <BackButton disabled={isUpdating} onClick={onBackClick} />
          <NextButton />
        </ActionButtonsContainer>
      </DateForm>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  deliveryOptions: {
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '2em 0',
  },
}));

export default ScheduleStep;
