import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Box, makeStyles, Tab } from '@material-ui/core';

/**
 * TabbedView - Basic shell to make a view tabbed, which is already styled to mCards custom tabs design.
 * @param {Array} tabs - an array of objects containing all tabs label and content.
 * @returns {JSX.Element}
 * @constructor
 *
 * @example
 *   <TabbedView
 *     tabs={[
 *       { value: '1', label: Intl.formatMessage({ id: 'page.tab.title' }), tabPanel: <ComponentOne /> },
 *       { value: '2', label: 'Tab Two', tabPanel: <ComponentTwo /> },
 *     ]}
 *   />
 */

const TabbedView = ({ tabs }) => {
  const classes = useStyles();
  const [chosenTab, setChosenTab] = useState(tabs[0]?.value || '1');

  return (
    <TabContext value={chosenTab}>
      <TabList onChange={(_, newValue) => setChosenTab(newValue)} className={classes.tabList}>
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} className={classes.tab} />
        ))}
      </TabList>

      {tabs.map((tab) => (
        <TabPanel key={tab.value} value={tab.value} className={classes.tabPanel}>
          <Box className={classes.tabPanelContent}>{tab.tabPanel}</Box>
        </TabPanel>
      ))}
    </TabContext>
  );
};

const useStyles = makeStyles((theme) => ({
  tabList: {
    minHeight: '2.5rem',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tab: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderBottom: 'none',
    borderTopRightRadius: '0.625rem',
    borderTopLeftRadius: '0.625rem',
    boxShadow: '0px 0px 4.85427px rgba(0, 0, 0, 0.15)',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: '0.875rem',
    minHeight: '2.5rem',
    opacity: 1,
    padding: '8px 12px',
    textTransform: 'inherit',
    '&.Mui-selected': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      cursor: 'default',
    },
  },
  tabPanel: {
    borderRadius: '0.625rem',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
    padding: '0',
  },
  tabPanelContent: {
    padding: '2rem',
  },
}));

TabbedView.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      tabPanel: PropTypes.any.isRequired,
    }),
  ).isRequired,
};

export default TabbedView;
