import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import MCardsDateTimePicker from '../../../MCardsDateTimePicker';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import moment from 'moment/moment';
import IntlMessages from '../../../../../@jumbo/utils/IntlMessages';
import validateDate from '../../../../../helpers/validators/validateDate';
import fetchFormError from '../../../../../helpers/validators/fetchFormError';

const SingleDateForm = ({ onSubmit, deliverableItems, children }) => {
  const initFormState = () => {
    if (deliverableItems[0]?.delivery_options?.delivery_date) {
      const defaultDeliveryDatetime = moment(deliverableItems[0].delivery_options.delivery_date);

      setInitialFormState({
        delivery_date: defaultDeliveryDatetime,
        delivery_time: defaultDeliveryDatetime,
      });
    }
  };

  useEffect(initFormState, [deliverableItems]);

  const [initialFormState, setInitialFormState] = useState({
    delivery_date: '',
    delivery_time: '',
  });

  const classes = useStyles();
  const i18nPath = 'pages.manage.recipients.carousel.steps.schedule';
  const intl = useIntl();

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialFormState}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box className={classes.datePickerContainer}>
            <Box className={classes.labelContainer} mr={10}>
              <Typography align="center" variant="subtitle1" color="textSecondary">
                <IntlMessages id={`${i18nPath}.delivery_options.scheduled_all_together.label`} />
              </Typography>
            </Box>
            <Box mr={3}>
              <Field name="delivery_date" key="single-schedule-delivery-date" validate={validateDate}>
                {({ input, meta }) => (
                  <MCardsDateTimePicker
                    type="date"
                    placeholder={intl.formatMessage({ id: `${i18nPath}.datepicker.date` })}
                    formatOutput
                    name={input.name}
                    value={input.value}
                    onChange={input.onChange}
                    disablePast={true}
                    error={!!fetchFormError(meta)}
                    helperText={fetchFormError(meta)}
                  />
                )}
              </Field>
            </Box>

            <Field name="delivery_time" key="single-schedule-delivery-time" validate={validateDate} mr={10}>
              {({ input, meta }) => (
                <MCardsDateTimePicker
                  type="time"
                  placeholder={intl.formatMessage({ id: `${i18nPath}.datepicker.time` })}
                  name={input.name}
                  value={input.value}
                  onChange={input.onChange}
                  views={['hours']}
                  error={!!fetchFormError(meta)}
                  helperText={fetchFormError(meta)}
                />
              )}
            </Field>
          </Box>
          {children}
        </form>
      )}></Form>
  );
};

const useStyles = makeStyles((theme) => ({
  datePickerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '> *': {
      margin: '0 1em',
    },
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxHeight: '3em',
  },
}));
export default SingleDateForm;
