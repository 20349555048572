import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const mapMaskTypes = {
  tel: createNumberMask({
    prefix: '+',
    suffix: '',
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: '',
  }),
  currency: createNumberMask({
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    allowNegative: false,
    allowLeadingZeroes: false,
  }),
};

const MCardsMaskedInput = (props) => {
  const { inputRef, fieldType, ...rest } = props;

  return <MaskedInput {...rest} inputMode="numeric" mask={mapMaskTypes[fieldType]} />;
};

export default MCardsMaskedInput;
