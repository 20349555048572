import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import SearchableList from 'components/SearchableList';
import MCardsTable from 'components/mcards/MCardsTable';
import { Avatar, Link } from '@material-ui/core';
import { formatDateRelative } from 'helpers/dates';
import CmtImage from '@coremat/CmtImage';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import AmountChip from 'components/AmountChip';
import DatePeriod from '../../filters/DatePeriod';
import SearchText from '../../filters/SearchText';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { CardTransactionStatus } from './CardActivityRow';
import service from 'services/distributor_api/cardactivities.service';

/**
 * @param {Object|String} heading - heading title of the card activities
 * @param {String} locationUuid - uuid of the location to filter by
 * @returns {JSX.Element}
 */
const CardActivities = ({ heading, locationUuid }) => {
  const intl = useIntl();

  return (
    <SearchableList
      countHeadingTitle={heading}
      getAll={(filters, page) => service.getAll(filters, page, { location_uuid: locationUuid })}
      ListComponent={CardholderRows}
      filters={[
        <SearchText placeholder={intl.formatMessage({ id: 'pages.manage.cardholders.search.placeholder' })} />,
        <DatePeriod />,
      ]}
    />
  );
};

export const CardholderRows = ({ currentList }) => {
  return CardActivitiesRows({ currentList, includeCardholderName: true });
};

export const CardActivitiesRows = ({ currentList, includeCardholderName = false }) => {
  const history = useHistory();
  const columns = ['program', 'cardholder', 'mobile_number', 'email', 'type', 'summary', 'time', 'status', 'amount'];
  let headers = columns.map((name) => <IntlMessages id={`pages.manage.cardholders.cardactivity.table.col.${name}`} />);

  if (!includeCardholderName) {
    headers.splice(1, 1);
  }

  return useMemo(() => {
    return (
      <>
        <MCardsTable
          headers={headers}
          rows={currentList?.map((item) => {
            let cardholder = includeCardholderName ? (
              <Link
                onClick={() => history.push(`/reports/cardholders/${item.cardholder_uuid}`)}
                style={{ cursor: 'pointer' }}>
                {item.cardholder_name}
              </Link>
            ) : null;

            let row = [
              <ProgramImage image_url={item.card_design_image_url} />,
              cardholder,
              item.cardholder_phone,
              item.cardholder_email,
              <IconForActivityType activityType={item.icon} />,
              item.title, // summary. Another candidate: item.description.
              item.created_at ? formatDateRelative(item.created_at) : null,
              <CardTransactionStatus status={item.transaction_state} />,
              <AmountChip item={item} />,
            ];

            if (!includeCardholderName) {
              row.splice(1, 1);
            }
            return row;
          })}
          widths={['50px', '100px', '120px', '120px', '50px', '140px', '100px', '50px']}
          cellPadding={'1rem 1rem'}
        />
      </>
    );
  }, [currentList]);
};

const IconForActivityType = ({ activityType }) => {
  const icons = {
    info: CreditCardIcon,
    block: CreditCardIcon,
    done: CreditCardIcon,
    card_giftcard: StarBorderIcon,
    account_balance: SyncAltIcon,
  };

  const Icon = icons[activityType] ?? StarBorderIcon;

  return (
    <Avatar
      aria-label="icon"
      component="span"
      style={{
        background: '#EEF0F8',
        color: '#0826EB',
      }}>
      <Icon fontSize="small" />
    </Avatar>
  );
};

const ProgramImage = ({ image_url }) => {
  return image_url ? (
    <CmtImage
      src={image_url}
      alt="program image"
      style={{
        borderRadius: '0.25rem',
        maxWidth: '30px',
        maxHeight: '30px',
      }}
    />
  ) : null;
};

CardActivities.defaultProps = {
  heading: null,
  locationUuid: null,
};

CardActivities.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  locationUuid: PropTypes.string,
};

export default CardActivities;
