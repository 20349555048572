import { useLocation } from 'react-router-dom';

export const useSearchParams = () => {
  const windowLocation = useLocation();
  let searchParams = windowLocation['search'];
  if (searchParams.startsWith('?')) {
    searchParams = searchParams?.substring(1).split('&');
    searchParams = searchParams?.reduce((acc, curr) => {
      const [key, value] = curr.split('=');
      acc[key] = decodeURI(value);
      return acc;
    }, {});
  } else {
    searchParams = {};
  }
  return searchParams;
};

export default useSearchParams;
