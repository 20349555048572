const validateEmail = (value) => {
  const emailRegex = new RegExp(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/);

  if (value) {
    return emailRegex.test(value) ? undefined : 'Invalid email format';
  } else {
    return 'Required';
  }
};

export default validateEmail;
