import React from 'react';
import { Box, Link } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const StatusLabel = ({ state, text, onClick, showDot }) => {
  const classes = useStyles();
  const style = classes[state];

  return (
    <Box component={onClick ? Link : 'span'} onClick={onClick ? onClick : null} className={clsx(classes.root, style)}>
      {showDot && <span className={classes.dot}>⬤</span>}
      <span className={onClick ? classes.withLink : ''}>{text}</span>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'inline-flex',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  dot: {
    fontSize: '0.4rem',
    marginRight: '7px',
  },
  danger: {
    color: theme.status.danger,
  },
  error: {
    color: theme.status.error,
  },
  pending: {
    color: theme.status.pending,
  },
  success: {
    color: theme.status.success,
  },
  warning: {
    color: theme.status.warning,
  },
  withLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

StatusLabel.defaultProps = {
  onClick: null,
  showDot: true,
};

StatusLabel.propTypes = {
  state: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]).isRequired,
  onClick: PropTypes.func,
  showDot: PropTypes.bool,
};

export default StatusLabel;
