import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const HeaderTitleAndSubtitle = ({ title, subtitle }) => {
  const classes = headerStyle();

  return (
    <>
      <Typography component="h1" variant="h1" className={classes.mainTitle}>
        {title}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        {subtitle}
      </Typography>
    </>
  );
};

const headerStyle = makeStyles((theme) => ({
  mainTitle: {
    fontSize: '2rem',
  },
}));

HeaderTitleAndSubtitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default HeaderTitleAndSubtitle;
