import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import ReviewSummary from '../../ReviewSummary';
import ReviewSummaryRow from '../../ReviewSummary/ReviewDetailRow';
import { notDeliveredNorCancelled } from '../../../../../helpers/campaign';
import MCardsButton from '../../../MCardsForm/MCardsButton';
import { makeStyles } from '@material-ui/styles';
import IntlMessages from '../../../../../@jumbo/utils/IntlMessages';
import ReviewRecipientsModal from '../ScheduleStep/ReviewRecipientsModal';
import ActionButtonsContainer from '../ActionButtons/ActionButtonsContainer';
import BackButton from '../ActionButtons/BackButton';
import { useHistory } from 'react-router';

const i18nPath = 'pages.manage.recipients.carousel.steps.confirmation';

const ReviewStep = ({ currentCampaign, onBackClick }) => {
  const history = useHistory();
  const [recipientsCount, setRecipientsCount] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [recipientsModalOpen, setRecipientsModalOpen] = useState(false);

  const toggleRecipientsModal = () => setRecipientsModalOpen((prevState) => !prevState);

  useEffect(() => {
    if (currentCampaign?.items) {
      const deliverableItems = currentCampaign.items.filter(notDeliveredNorCancelled);
      setRecipientsCount(deliverableItems.length);
      setTotalValue(deliverableItems.reduce((acc, item) => acc + parseFloat(item.amount), 0.0).toFixed(2));
    }
  }, [currentCampaign]);

  const classes = useStyles();

  return (
    <Box>
      <ReviewSummary
        title={<IntlMessages id={`${i18nPath}.summary.title`} />}
        rows={[
          <ReviewSummaryRow
            key="recipients"
            description={<IntlMessages id={`${i18nPath}.summary.rows.count`} />}
            value={recipientsCount}
          />,
          <ReviewSummaryRow
            key="total"
            description={<IntlMessages id={`${i18nPath}.summary.rows.total`} />}
            value={`$${totalValue}`}
          />,
        ]}
        summaryActions={
          <>
            {recipientsModalOpen && (
              <ReviewRecipientsModal
                onClose={toggleRecipientsModal}
                titleI18N={`${i18nPath}.review.title`}
                buttonI18N={`${i18nPath}.review.button`}
                isOpen={recipientsModalOpen}
                campaign={currentCampaign}
                onConfirm={toggleRecipientsModal}
              />
            )}

            <MCardsButton className={classes.recipientsButton} variant="text" type="button" onClick={toggleRecipientsModal}>
              {<IntlMessages id={`${i18nPath}.summary.actions.button`} />}
            </MCardsButton>
          </>
        }
      />

      <ActionButtonsContainer>
        <BackButton onClick={onBackClick} />
        <MCardsButton type="button" onClick={() => history.push(`/reports/campaigns/${currentCampaign.uuid}/`)}>
          <IntlMessages id="pages.manage.campaigns.carousel.buttons.done" />
        </MCardsButton>
      </ActionButtonsContainer>
    </Box>
  );
};
const useStyles = makeStyles(() => ({
  recipientsButton: {
    paddingBottom: 0,
    paddingLeft: 0,
  },
}));

export default ReviewStep;
